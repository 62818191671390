import { styled } from '@attentive/picnic';

import { SortableHeaderCell } from './SortableHeaderCell';
import { SORT_ICON_WIDTH } from './utils';

export const StyledSortableHeaderCell = styled(SortableHeaderCell, {
  alignItems: 'start',
  variants: {
    alignment: {
      left: {
        textAlign: 'left',
        pr: 0,
      },
      right: {
        textAlign: 'right',
        pr: SORT_ICON_WIDTH,
      },
    },
    sticky: {
      topLeft: {
        position: 'sticky',
        top: '0',
        left: '0',
        background: 'white',
        zIndex: 3,
        borderRight: '1px $grayscale200 solid',
      },
      top: {
        position: 'sticky',
        top: '0',
        background: 'white',
        zIndex: 2,
      },
    },
  },
});
