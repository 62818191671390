import React from 'react';

import { BarDatum } from '@attentive/charts';
import { Text, Stack, Heading } from '@attentive/picnic';

import { BarChartLegendItem } from '../BarChartLegendItem/BarChartLegendItem';

import { formatTooltipValue } from './utils';

interface MultiStackVerticalBarChartTooltipProps {
  useRange: boolean;
  data: BarDatum;
  labels: string[];
  colors: string[];
  indexedBy: string;
}
export const MultiStackVerticalBarChartTooltip: React.FunctionComponent<
  MultiStackVerticalBarChartTooltipProps
> = ({ children, useRange, data, labels, colors, indexedBy }) => {
  let heading = data[indexedBy];
  if (data?.tooltipHeading) {
    heading = data?.tooltipHeading;
  } else if (useRange) {
    const rangeHeading = `${data.rangeStart}${data.rangeEnd ? ` - ${data.rangeEnd}` : ''}`;
    heading = rangeHeading;
  }
  const { dataType, currencyCode, locale } = data;

  //Make sure the top most bar label is displayed first
  const reversedLabels = [...labels].reverse();
  const reveredColors = [...colors].reverse();

  return (
    <Stack
      direction="vertical"
      css={{
        padding: '$space2',
        background: '$bgDefault',
        justifyContent: 'center',
        borderRadius: '$radius2',
        boxShadow: '$shadow1',
      }}
    >
      <Heading
        variant="subheading"
        css={{
          width: '100%',
          borderBottom: '1px solid $borderDefault',
          paddingBottom: '$space1',
          marginBottom: '$space1',
        }}
      >
        {heading}
      </Heading>
      {reversedLabels.map(
        (label, index) =>
          data[label] && (
            <Stack
              direction="horizontal"
              key={label}
              css={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
                mt: '$space1',
              }}
            >
              <BarChartLegendItem
                verticalCircleGradient
                color={reveredColors[index]}
                label={label}
                size="small"
              />
              <Text variant="micro" css={{ fontWeight: '$bold', textAlign: 'right' }}>
                {formatTooltipValue(
                  data[label],
                  dataType.toString(),
                  currencyCode.toString(),
                  locale.toString()
                )}
              </Text>
            </Stack>
          )
      )}
      {children}
    </Stack>
  );
};
