import _useHasAIJourneysPerformanceReportAccess_ReportingPlatform_query from "./__generated__/useHasAIJourneysPerformanceReportAccess_ReportingPlatform_query.graphql";
import { useFragment } from 'react-relay';
import { useCompanyFeatureFlag, getRoles } from '@attentive/acore-utils';
import { Role, graphql } from '@attentive/data';
import { useHasAIJourneysAccess } from '@attentive/ml-common';
import useHasAIJourneysPerformanceReportAccess_ReportingPlatform_query from './__generated__/useHasAIJourneysPerformanceReportAccess_ReportingPlatform_query.graphql';
_useHasAIJourneysPerformanceReportAccess_ReportingPlatform_query;
export const useHasAIJourneysPerformanceReportAccess = queryRef => {
  const queryData = useFragment(useHasAIJourneysPerformanceReportAccess_ReportingPlatform_query, queryRef);
  const [hasAIJourneysAccess] = useHasAIJourneysAccess(queryData); // backend flag

  const isAIJourneysPerformanceReportConfigEnabled = useCompanyFeatureFlag('ENABLE_AI_JOURNEYS_PERFORMANCE_REPORT');
  const isAIJourneysPageEnabled = useCompanyFeatureFlag('ENABLE_AI_JOURNEYS_PERFORMANCE_UI');
  const isClientFacingAIJourneysReportEnabled = useCompanyFeatureFlag('ENABLE_CLIENT_FACING_AI_JOURNEYS_REPORT');
  const isSuperUser = getRoles().has(Role.RoleSuperUser); // AI Journeys Performance report is gated by a three-step process. For this report to show:
  // 1. the AI Journeys Report flag ENABLE_AI_JOURNEYS_PERFORMANCE_REPORT is on
  // 2. client has used AI Journeys before, same logic showing AIJourneysPerformancePage
  // 3. if ENABLE_CLIENT_FACING_AI_JOURNEYS_PERFORMANCE_REPORT flag is on, show the report for all users, otherwise superuser-only

  const hasAIJourneysPerformanceReportAccess = isAIJourneysPerformanceReportConfigEnabled && isAIJourneysPageEnabled && hasAIJourneysAccess && (isClientFacingAIJourneysReportEnabled || isSuperUser);
  return {
    hasAIJourneysPerformanceReportAccess
  };
};
export const AI_JOURNEY_PERFORMANCE_REPORT_ID = 'ai_journeys_performance';