/**
 * @generated SignedSource<<53fbaa0dbfce595757c0b14e2d986e16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReportExportButton_ReportingPlatform_metrics$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"useGenerateRawMetricsRows_ReportingPlatform_metrics" | "useGenerateReportMetricsColumnHeaders_ReportingPlatform_metrics">;
  readonly " $fragmentType": "ReportExportButton_ReportingPlatform_metrics";
}>;
export type ReportExportButton_ReportingPlatform_metrics$key = ReadonlyArray<{
  readonly " $data"?: ReportExportButton_ReportingPlatform_metrics$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReportExportButton_ReportingPlatform_metrics">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ReportExportButton_ReportingPlatform_metrics",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useGenerateReportMetricsColumnHeaders_ReportingPlatform_metrics"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useGenerateRawMetricsRows_ReportingPlatform_metrics"
    }
  ],
  "type": "Metric",
  "abstractKey": null
};

(node as any).hash = "3e37d7a8ef2eba33a6d634cdd7603fed";

export default node;
