import {
  format,
  endOfMonth,
  endOfYear,
  endOfQuarter,
  startOfWeek,
  startOfMonth,
  startOfYear,
  startOfQuarter,
  sub,
  subQuarters,
  getQuarter,
  getYear,
  isSameDay,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { DateFilterPeriod } from '@attentive/data/types';
import { DATE_FNS_ISO_8601_DATE_FORMAT } from '@attentive/picnic';

export type DateRangeTitle =
  | 'Today'
  | 'Yesterday'
  | 'Last 7 days'
  | 'Last 30 days'
  | 'Last 90 days'
  | 'Last month'
  | 'Last quarter'
  | 'Last year'
  | 'Week to date'
  | 'Month to date'
  | 'Quarter to date'
  | 'Year to date'
  | `${string} Quarter (${string})`
  | 'All time'
  | 'Last 8 weeks'
  | 'Last 14 days'
  | 'Custom';

export interface DateRangeOption {
  title: DateRangeTitle;
  dateFilterPeriod: DateFilterPeriod | null;
  getStartDate: () => string;
  getEndDate: () => string;
}

export const getOrdinalNum = (num: number): string => {
  if (num > 3 && num < 21) return `${num}th`;
  switch (num % 10) {
    case 1:
      return `${num}st`;
    case 2:
      return `${num}nd`;
    case 3:
      return `${num}rd`;
    default:
      return `${num}th`;
  }
};

const getTodaysDate = (companyTimezone?: string): Date => {
  if (companyTimezone) {
    return utcToZonedTime(Date.now(), companyTimezone);
  }
  return new Date();
};

export const getDateRangeOptions = (
  {
    companyTimezone,
    includeLast4Quarters,
    includeCustom,
    includeLast8Weeks,
    includeLast14Days,
  }: {
    companyTimezone?: string;
    includeLast4Quarters?: boolean;
    includeCustom?: boolean;
    includeLast8Weeks?: boolean;
    includeLast14Days?: boolean;
  } = {
    companyTimezone: '',
    includeLast4Quarters: true,
    includeCustom: false,
    includeLast8Weeks: false,
    includeLast14Days: false,
  }
): DateRangeOption[] => {
  const today = getTodaysDate(companyTimezone);
  const dynamicRanges: DateRangeOption[] = [
    {
      title: 'Today',
      dateFilterPeriod: DateFilterPeriod.DateFilterPeriodToday,
      getStartDate: () => format(today, DATE_FNS_ISO_8601_DATE_FORMAT),
      getEndDate: () => format(today, DATE_FNS_ISO_8601_DATE_FORMAT),
    },
    {
      title: 'Yesterday',
      dateFilterPeriod: DateFilterPeriod.DateFilterPeriodYesterday,
      getStartDate: () => format(sub(today, { days: 1 }), DATE_FNS_ISO_8601_DATE_FORMAT),
      getEndDate: () => format(sub(today, { days: 1 }), DATE_FNS_ISO_8601_DATE_FORMAT),
    },
    {
      title: 'Last 7 days',
      dateFilterPeriod: DateFilterPeriod.DateFilterPeriodLast_7Days,
      // Subtract an extra day to end the date range on the last _full_ day
      getStartDate: () => format(sub(today, { days: 7 }), DATE_FNS_ISO_8601_DATE_FORMAT),
      getEndDate: () => format(sub(today, { days: 1 }), DATE_FNS_ISO_8601_DATE_FORMAT),
    },
    {
      title: 'Last 30 days',
      dateFilterPeriod: DateFilterPeriod.DateFilterPeriodLast_30Days,
      // Subtract an extra day to end the date range on the last _full_ day
      getStartDate: () => format(sub(today, { days: 30 }), DATE_FNS_ISO_8601_DATE_FORMAT),
      getEndDate: () => format(sub(today, { days: 1 }), DATE_FNS_ISO_8601_DATE_FORMAT),
    },
    {
      title: 'Last 90 days',
      dateFilterPeriod: DateFilterPeriod.DateFilterPeriodLast_90Days,
      // Subtract an extra day to end the date range on the last _full_ day
      getStartDate: () => format(sub(today, { days: 90 }), DATE_FNS_ISO_8601_DATE_FORMAT),
      getEndDate: () => format(sub(today, { days: 1 }), DATE_FNS_ISO_8601_DATE_FORMAT),
    },
    {
      title: 'Last month',
      dateFilterPeriod: DateFilterPeriod.DateFilterPeriodLastMonth,
      getStartDate: () =>
        format(startOfMonth(sub(today, { months: 1 })), DATE_FNS_ISO_8601_DATE_FORMAT),
      getEndDate: () =>
        format(endOfMonth(sub(today, { months: 1 })), DATE_FNS_ISO_8601_DATE_FORMAT),
    },
    {
      title: 'Last quarter',
      dateFilterPeriod: DateFilterPeriod.DateFilterPeriodLastQuarter,
      getStartDate: () =>
        format(startOfQuarter(subQuarters(today, 1)), DATE_FNS_ISO_8601_DATE_FORMAT),
      getEndDate: () => format(endOfQuarter(subQuarters(today, 1)), DATE_FNS_ISO_8601_DATE_FORMAT),
    },
    {
      title: 'Last year',
      dateFilterPeriod: DateFilterPeriod.DateFilterPeriodLastYear,
      getStartDate: () =>
        format(startOfYear(sub(today, { years: 1 })), DATE_FNS_ISO_8601_DATE_FORMAT),
      getEndDate: () => format(endOfYear(sub(today, { years: 1 })), DATE_FNS_ISO_8601_DATE_FORMAT),
    },
    {
      title: 'Week to date',
      dateFilterPeriod: DateFilterPeriod.DateFilterPeriodWeekToDate,
      getStartDate: () => format(startOfWeek(today), DATE_FNS_ISO_8601_DATE_FORMAT),
      getEndDate: () => format(today, DATE_FNS_ISO_8601_DATE_FORMAT),
    },
    {
      title: 'Month to date',
      dateFilterPeriod: DateFilterPeriod.DateFilterPeriodMonthToDate,
      getStartDate: () => format(startOfMonth(today), DATE_FNS_ISO_8601_DATE_FORMAT),
      getEndDate: () => format(today, DATE_FNS_ISO_8601_DATE_FORMAT),
    },
    {
      title: 'Quarter to date',
      dateFilterPeriod: DateFilterPeriod.DateFilterPeriodQuarterToDate,
      getStartDate: () => format(startOfQuarter(today), DATE_FNS_ISO_8601_DATE_FORMAT),
      getEndDate: () => format(today, DATE_FNS_ISO_8601_DATE_FORMAT),
    },
    {
      title: 'Year to date',
      dateFilterPeriod: DateFilterPeriod.DateFilterPeriodYearToDate,
      getStartDate: () => format(startOfYear(today), DATE_FNS_ISO_8601_DATE_FORMAT),
      getEndDate: () => format(today, DATE_FNS_ISO_8601_DATE_FORMAT),
    },
  ];
  const last4Quarters = includeLast4Quarters
    ? [
        ...[...Array(4)].map((_, i) => {
          const dateInQuarter = subQuarters(today, i + 1);
          const quarter = getQuarter(dateInQuarter);
          const year = getYear(dateInQuarter);
          return {
            title: `${getOrdinalNum(quarter)} Quarter (${year})` as DateRangeTitle,
            dateFilterPeriod: null,
            getStartDate: () =>
              format(startOfQuarter(dateInQuarter), DATE_FNS_ISO_8601_DATE_FORMAT),
            getEndDate: () => format(endOfQuarter(dateInQuarter), DATE_FNS_ISO_8601_DATE_FORMAT),
          };
        }),
      ]
    : [];
  const custom = includeCustom
    ? [
        {
          title: 'Custom' as DateRangeTitle,
          dateFilterPeriod: null,
          getStartDate: () => '',
          getEndDate: () => '',
        },
      ]
    : [];
  const last8Weeks = includeLast8Weeks
    ? [
        {
          title: 'Last 8 weeks' as DateRangeTitle,
          dateFilterPeriod: null,
          getStartDate: () => format(sub(today, { weeks: 8 }), DATE_FNS_ISO_8601_DATE_FORMAT),
          getEndDate: () => format(sub(today, { days: 1 }), DATE_FNS_ISO_8601_DATE_FORMAT),
        },
      ]
    : [];
  const last14Days = includeLast14Days
    ? [
        {
          title: 'Last 14 days' as DateRangeTitle,
          dateFilterPeriod: null,
          getStartDate: () => format(sub(today, { days: 14 }), DATE_FNS_ISO_8601_DATE_FORMAT),
          getEndDate: () => format(sub(today, { days: 1 }), DATE_FNS_ISO_8601_DATE_FORMAT),
        },
      ]
    : [];
  return [...custom, ...dynamicRanges, ...last8Weeks, ...last14Days, ...last4Quarters];
};

export const getMatchingDateRangeTitle = (
  startDate: string | null,
  endDate: string | null,
  companyTimezone?: string
): DateRangeTitle | null => {
  const dateRangeOptions = getDateRangeOptions({ companyTimezone, includeLast4Quarters: true });
  const dateRange = dateRangeOptions.find((option) => {
    return (
      startDate &&
      endDate &&
      isSameDay(new Date(startDate), new Date(option.getStartDate())) &&
      isSameDay(new Date(endDate), new Date(option.getEndDate()))
    );
  });
  return dateRange?.title || null;
};

export const getDateRangeOption = (
  title: DateRangeTitle,
  companyTimezone?: string
): DateRangeOption => {
  return getDateRangeOptions({ companyTimezone, includeLast4Quarters: true }).find(
    (option) => option.title === title
  ) as DateRangeOption;
};

export const getDateRangeTitle = (
  dateFilterPeriod: DateFilterPeriod | null,
  dateRangeOptions: DateRangeOption[]
) =>
  dateRangeOptions.find((option) => option.dateFilterPeriod === dateFilterPeriod)?.title ??
  'Custom';
