import { ReportFilter } from '../services';

export type ReportPageUrlFilterParam = {
  dimensionId: string;
  operator: 'FILTER_OPERATOR_IN' | 'FILTER_OPERATOR_NOT_IN';
  dimensionSets: Array<{ k: string; v: string }>;
};

export type ReportPageUrlDateParams = {
  startDate: string;
  endDate: string;
};

const dateRegex = /^\d{4}-\d{2}-\d{2}$/; // ISO 8601 yyyy-MM-dd date format

export const generateReportUrl = ({
  reportName,
  reportParams,
  externalCompanyId,
  dateRange,
}: {
  reportName: string;
  reportParams?: ReportPageUrlFilterParam[];
  externalCompanyId?: number;
  dateRange?: ReportPageUrlDateParams;
}) => {
  const urlParams = new URLSearchParams();

  reportParams?.forEach(({ dimensionId, operator, dimensionSets }) => {
    const filterParam = {
      dimensionId,
      operator,
      dimensionSets,
    };
    urlParams.append('filter', JSON.stringify(filterParam));
  });

  if (dateRange) {
    if (!dateRegex.test(dateRange.startDate) || !dateRegex.test(dateRange.endDate)) {
      throw new Error('Invalid date format. Dates must be in "yyyy-MM-dd" format.');
    }

    urlParams.append('startDate', dateRange.startDate);
    urlParams.append('endDate', dateRange.endDate);
  }

  if (externalCompanyId) {
    urlParams.append('cId', externalCompanyId.toString());
  }

  return `${
    window.location.origin
  }/analytics/reports/library/${reportName}?${urlParams.toString()}`;
};

export const extractReportFilters = (
  urlSearchParams: URLSearchParams
): { reportFilters: ReportFilter[]; startDate: string | null; endDate: string | null } => {
  const filters = urlSearchParams.getAll('filter');
  const startDate = urlSearchParams.get('startDate');
  const endDate = urlSearchParams.get('endDate');

  const reportFilters = filters.map((filter) => {
    const filterParam: ReportPageUrlFilterParam = JSON.parse(filter);
    return {
      __typename: 'ReportStringFilter',
      dimensionId: filterParam.dimensionId,
      filterOperator: filterParam.operator,
      dimensionSets: filterParam.dimensionSets.map((dimensionSet) => ({
        key: dimensionSet.k,
        value: dimensionSet.v,
      })),
      values: filterParam.dimensionSets.map((dimensionSet) => dimensionSet.v),
    };
  }) as ReportFilter[];

  return {
    reportFilters,
    startDate,
    endDate,
  };
};

export const mergeReportFilters = (reportFilters: ReportFilter[], urlFilters: ReportFilter[]) => {
  if (urlFilters.length) {
    const urlFilterIds = urlFilters.map((filter) => filter.dimensionId);
    const reportFiltersNotInUrl = reportFilters.filter(
      (filter) => !urlFilterIds.includes(filter.dimensionId)
    );
    return [...reportFiltersNotInUrl, ...urlFilters];
  }
  return reportFilters;
};
