import { Report } from '@attentive/data/types';

export { FilterOperator } from '@attentive/data/types';
export type {
  DateFilterPeriod,
  Report,
  ReportFilter,
  ReportFilterWrapper,
  ReportGrouping,
  ReportGroupingWrapper,
  ReportStringFilter,
  ReportTimeFilter,
  ReportGroupingInput,
  ReportFilterInput,
  ReportStringGrouping,
  ReportFilterDimensionSet,
} from '@attentive/data/types';

import { TimeDimensionGranularity } from '../../services';

export interface ReportDomain {
  title: string;
  domainId: string;
  description: string;
  reports: Report[];
  displayOrder: number;
}

// this type allows the user to select a time "Group by <>" -- depending on their selection, it will do two different things:
// 1. The user can select a TimeDimensionGranularity like day, week, month which results in passing that granularity along to Corpus as part of the date dimension.
// 2. We also want the users to be able to select "Entire range" which removes the date groupings
export const ENTIRE_RANGE_DATE_GRANULARITY = 'Entire range';
export type ReportDateGranularityType =
  | TimeDimensionGranularity
  | typeof ENTIRE_RANGE_DATE_GRANULARITY;
