import { atom } from 'jotai';

export enum TableSortDirection {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}
export const DEFAULT_TABLE_SORT_DIRECTION = TableSortDirection.ASCENDING;
export const tableSortDirectionAtom = atom<TableSortDirection>(DEFAULT_TABLE_SORT_DIRECTION);

const DEFAULT_SORTED_COLUMN = 0;
export const tableSortedColumnAtom = atom<number>(DEFAULT_SORTED_COLUMN);
