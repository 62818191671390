import { isAfter, isSameDay } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';

import { DateFilterPeriod } from '@attentive/data/types';
import { Box, formatDateRange, FormField, PicnicCss, Select, SelectProps } from '@attentive/picnic';

import { getCalendarDay } from '../../utils/dates';

import { DateRangeSelectCalendar } from './DateRangeSelectCalendar';
import { DateRangeOption, DateRangeTitle, getDateRangeOptions } from './utils';

export type DateRangeSelectChange = (dateRange: {
  startDate: string | null;
  endDate: string | null;
  dateFilterPeriod: DateFilterPeriod | null;
  dateRangeTitle: DateRangeTitle | null;
}) => void;

interface DateRangeSelectProps {
  startDate: string | null;
  endDate: string | null;
  dateFilterPeriod?: DateFilterPeriod | null;
  shouldReset?: boolean;
  includeHeading?: boolean;
  shouldUseInternalCalendar?: boolean;
  onChange?: DateRangeSelectChange;
  onReset?: () => void;
  companyTimezone?: string;
  dateRangeOptions?: DateRangeOption[];
  numberOfMonthsInCalendar?: React.ComponentProps<typeof DateRangeSelectCalendar>['numberOfMonths'];
  size?: SelectProps['size'];
  css?: PicnicCss;
}

export const DateRangeSelect: React.FC<DateRangeSelectProps> = ({
  startDate,
  endDate,
  dateFilterPeriod,
  shouldReset = false,
  includeHeading = true,
  shouldUseInternalCalendar = true,
  onChange,
  onReset,
  companyTimezone,
  dateRangeOptions: dateRangeOptionsProp,
  numberOfMonthsInCalendar,
  size = 'small',
  css,
}) => {
  const dateRangeOptions = dateRangeOptionsProp
    ? dateRangeOptionsProp
    : getDateRangeOptions({ companyTimezone, includeLast4Quarters: true });

  const customIndex = dateRangeOptions.findIndex((option) => option.title === 'Custom');
  const hasCustomOption = customIndex !== -1;

  const shouldUseExternalDateSelection = !shouldUseInternalCalendar && hasCustomOption;

  const shouldUseGivenOption = !!dateFilterPeriod;
  const shouldUseCustomDateOption = dateFilterPeriod === null && shouldUseExternalDateSelection;
  const shouldManuallyGetOption = dateFilterPeriod === undefined;

  let initialIndex = -1;
  if (shouldUseGivenOption) {
    initialIndex = dateRangeOptions.findIndex(
      (option) => option.dateFilterPeriod === dateFilterPeriod
    );
  } else if (shouldUseCustomDateOption) {
    initialIndex = customIndex;
  } else if (shouldManuallyGetOption) {
    initialIndex = dateRangeOptions.findIndex((option) => {
      // coerces dates to string so we can compare empty strings in cases of clearing out dates
      return (
        new Date(startDate || '').toString() === new Date(option.getStartDate()).toString() &&
        new Date(endDate || '').toString() === new Date(option.getEndDate()).toString()
      );
    });
  }

  const initialDateRangeOptionIndex = useRef(initialIndex);
  const [dateRangeOptionIndex, setDateRangeOptionIndex] = useState(initialIndex);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const handleChange: React.ComponentProps<typeof Select>['onChange'] = (index: number) => {
    const dateRangeOption = dateRangeOptions[index];
    if (dateRangeOption.title === 'Custom' && !shouldUseExternalDateSelection) {
      setIsCalendarOpen(true);
    } else {
      setDateRangeOptionIndex(index);
      onChange &&
        onChange({
          startDate: dateRangeOption.getStartDate(),
          endDate: dateRangeOption.getEndDate(),
          dateFilterPeriod: dateRangeOption.dateFilterPeriod,
          dateRangeTitle: dateRangeOption.title,
        });
    }
  };

  const handleCalendarSelection: React.ComponentProps<
    typeof DateRangeSelectCalendar
  >['onChange'] = (newDates) => {
    onChange &&
      onChange({
        startDate: newDates.startDate,
        endDate: newDates.endDate,
        dateFilterPeriod: null,
        dateRangeTitle: 'Custom',
      });

    setDateRangeOptionIndex(-1);
  };

  const handleCalendarSetOpen: React.ComponentProps<typeof DateRangeSelectCalendar>['setIsOpen'] = (
    newIsOpen
  ) => {
    setIsCalendarOpen(newIsOpen);
  };

  // if the start date or end date changes, this means the user is choosing a "Custom" date range
  useEffect(() => {
    const currentActiveOption =
      dateRangeOptionIndex > customIndex && dateRangeOptions[dateRangeOptionIndex];

    if (
      currentActiveOption &&
      startDate &&
      endDate &&
      (!isSameDay(new Date(startDate), new Date(currentActiveOption.getStartDate())) ||
        !isSameDay(new Date(endDate), new Date(currentActiveOption.getEndDate())))
    ) {
      setDateRangeOptionIndex(customIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    if (shouldReset && onReset && shouldUseExternalDateSelection) {
      setDateRangeOptionIndex(initialDateRangeOptionIndex.current);
      onReset();
    }
  }, [shouldReset, onReset, shouldUseExternalDateSelection]);

  const blockFutureDates = (date: string) => isAfter(new Date(date), new Date());
  const todayInCompanyTz = getCalendarDay(new Date(), companyTimezone || 'UTC');

  return (
    <Box
      css={{
        ...css,
      }}
    >
      {includeHeading && <FormField.Label css={{ pb: '$space2' }}>Date range</FormField.Label>}
      <Select
        value={isCalendarOpen ? undefined : dateRangeOptionIndex}
        placeholder={isCalendarOpen ? 'Custom' : formatDateRange(startDate, endDate)}
        onChange={handleChange}
        size={size}
        aria-label="Open date range select dropdown"
      >
        {dateRangeOptions.map(({ title }, i) => (
          <Select.Item value={i} key={title}>
            {title}
          </Select.Item>
        ))}
      </Select>

      <DateRangeSelectCalendar
        startDate={todayInCompanyTz}
        endDate={todayInCompanyTz}
        onChange={handleCalendarSelection}
        isOpen={isCalendarOpen}
        setIsOpen={handleCalendarSetOpen}
        isDayBlocked={blockFutureDates}
        numberOfMonths={numberOfMonthsInCalendar}
      />
    </Box>
  );
};
