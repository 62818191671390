import React from 'react';

import { Box, Table, Text } from '@attentive/picnic';

import { MetricRawRow } from '../../hooks';

import { StyledBodyCell } from './StyledBodyCell';

interface TotalsRowProps {
  row: MetricRawRow;
  periodDateLabel: string;
  periodLabel: string;
}
export const TotalsRow = ({ row, periodDateLabel, periodLabel }: TotalsRowProps) => {
  return (
    <Table.BodyRow key="currentPeriodTotalsRow">
      {row.map((cell, columnIndex) => {
        const isLeftAligned = cell.isGroupingDimensionValue;
        const isFirstGroupingColumn = cell.isGroupingDimensionValue && columnIndex === 0;
        const value = isFirstGroupingColumn ? (
          <Box css={{ display: 'flex', flexDirection: 'column' }}>
            <Text>Total</Text>
            <Text variant="caption" color="subdued" css={{ fontWeight: '$regular' }}>
              {periodDateLabel}
            </Text>
            {periodLabel && (
              <Text variant="caption" color="subdued" css={{ fontWeight: '$regular' }}>
                {periodLabel}
              </Text>
            )}
          </Box>
        ) : (
          cell.value
        );

        const sticky = columnIndex === 0;
        return (
          <StyledBodyCell
            // eslint-disable-next-line react/no-array-index-key
            key={`cell: currentPeriodTotalsRow, ${columnIndex} ${cell.value}`}
            align={isLeftAligned ? 'left' : 'right'}
            alignment={isLeftAligned ? 'left' : 'right'}
            type="emphasized"
            stickyLeft={sticky}
          >
            {value}
          </StyledBodyCell>
        );
      })}
    </Table.BodyRow>
  );
};
