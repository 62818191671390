import {
  differenceInCalendarDays,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  parseISO,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  subDays,
  subMonths,
  subQuarters,
  subYears,
} from 'date-fns';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import {
  Tracker,
  TrackerEvents,
  useCompanyFeatureFlag,
  useCurrentUser,
  useLocaleConfig,
} from '@attentive/acore-utils';
import { DateFilterPeriod } from '@attentive/data/types';
import { DateFormat, formatDateWithLocalTime, Locale } from '@attentive/locale-utils';

import { DateRangeTitle, getDateRangeOption, getMatchingDateRangeTitle } from '../DateRangeSelect';

interface MetricsDateRangeSyncContext {
  startDate: string;
  endDate: string;
  dateFilterPeriod: DateFilterPeriod | null;
  setDateRange: (start: string, end: string) => void;
  groupByMonth: boolean;
  comparisonStartDate: string;
  comparisonEndDate: string;
  shouldCompareToPreviousDates: boolean;
  setShouldCompareToPreviousDates: (value: boolean) => void;
  comparisonPeriod: MetricsComparisonPeriod;
  setComparisonPeriod: (period: MetricsComparisonPeriod) => void;
  dateRangeSelection: DateRangeTitle;
  setDateRangeSelection: (dateRangeSelection: DateRangeTitle) => void;
  setDateFilterPeriod: (dateFilter: DateFilterPeriod | null) => void;
}

/**
 * Defines dynamic time periods against which metrics can be compared
 *
 * If a new enum value is added here, a corresponding function to derive
 * the comparison period from a given start and end date interval
 * must be added to the {@link metricsComparisonPeriodFunctions} mapping
 */
enum MetricsComparisonPeriod {
  PREVIOUS_PERIOD = 'Previous period',
  PREVIOUS_YEAR = 'Previous year',
}

const DEFAULT_METRICS_COMPARISON_PERIOD = MetricsComparisonPeriod.PREVIOUS_PERIOD;

type MetricsComparisonPeriodFunction = ({
  start,
  end,
  selectedDateRange,
  usePeriodLengthForPreviousPeriod,
}: {
  start: Date;
  end: Date;
  selectedDateRange: DateRangeTitle | null;
  usePeriodLengthForPreviousPeriod?: boolean;
}) => Interval;

const metricsComparisonPeriodFunctions: {
  [key in MetricsComparisonPeriod]: MetricsComparisonPeriodFunction;
} = {
  [MetricsComparisonPeriod.PREVIOUS_PERIOD]: ({
    start,
    end,
    selectedDateRange,
    usePeriodLengthForPreviousPeriod = false,
  }: {
    start: Date;
    end: Date;
    selectedDateRange: DateRangeTitle | null;
    usePeriodLengthForPreviousPeriod?: boolean;
  }): Interval => {
    if (usePeriodLengthForPreviousPeriod) {
      const periodLength = differenceInCalendarDays(end, start) + 1;
      return { start: subDays(start, periodLength), end: subDays(start, 1) };
    }

    if (selectedDateRange === 'Last month') {
      return { start: startOfMonth(subMonths(start, 1)), end: endOfMonth(subMonths(end, 1)) };
    }

    if (selectedDateRange === 'Last year') {
      //Explicitly compute last year to handle leap years
      return { start: startOfYear(subYears(start, 1)), end: endOfYear(subYears(end, 1)) };
    }

    if (selectedDateRange && selectedDateRange.includes('Quarter')) {
      return {
        start: startOfQuarter(subQuarters(start, 1)),
        end: endOfQuarter(subQuarters(end, 1)),
      };
    }

    const periodLength = differenceInCalendarDays(end, start) + 1;
    return { start: subDays(start, periodLength), end: subDays(start, 1) };
  },
  [MetricsComparisonPeriod.PREVIOUS_YEAR]: ({
    start,
    end,
  }: {
    start: Date;
    end: Date;
  }): Interval => {
    return { start: subYears(start, 1), end: subYears(end, 1) };
  },
};

const computeComparisonDateRange = (
  start: string,
  end: string,
  period: MetricsComparisonPeriod,
  locale: Locale,
  usePeriodLengthForPreviousPeriod?: boolean,
  companyTimezone?: string
): { start: string; end: string } => {
  const matchingDateRangeTitle = getMatchingDateRangeTitle(start, end, companyTimezone);
  const periodFunction = metricsComparisonPeriodFunctions[period];
  const computedRange = periodFunction({
    start: parseISO(start),
    end: parseISO(end),
    selectedDateRange: matchingDateRangeTitle,
    usePeriodLengthForPreviousPeriod,
  });

  return {
    start: formatDateWithLocalTime(computedRange.start, DateFormat.ISO_8601, locale),
    end: formatDateWithLocalTime(computedRange.end, DateFormat.ISO_8601, locale),
  };
};

const MetricsDateRangeSyncContext = React.createContext<MetricsDateRangeSyncContext | undefined>(
  undefined
);

const useMetricsDateRangeSyncContext = (): MetricsDateRangeSyncContext => {
  const context = React.useContext(MetricsDateRangeSyncContext);
  if (context === undefined) {
    throw new Error(
      'useMetricsDateRangeSyncContext must be used within a MetricsDateRangeSyncProvider'
    );
  }
  return context as MetricsDateRangeSyncContext;
};

const MetricsDateRangeSyncProvider: React.FC = ({ children }) => {
  const { timezone } = useCurrentUser().company;
  const usePeriodLengthForPreviousPeriod = useCompanyFeatureFlag(
    'ENABLE_ANALYTICS_DASHBOARD_RANGE_COMPARISON_SELECTOR'
  );

  const { locale } = useLocaleConfig();

  const lastThirtyDaysDateRange = getDateRangeOption('Last 30 days');

  const [startDate, setStartDate] = useState<string>(lastThirtyDaysDateRange.getStartDate());
  const [endDate, setEndDate] = useState<string>(lastThirtyDaysDateRange.getEndDate());
  const [shouldCompareToPreviousDates, setShouldCompareToPreviousDates] = useState<boolean>(true);
  const [comparisonPeriod, setComparisonPeriod] = useState<MetricsComparisonPeriod>(
    DEFAULT_METRICS_COMPARISON_PERIOD
  );
  const [comparisonStartDate, setComparisonStartDate] = useState<string>(
    computeComparisonDateRange(
      startDate,
      endDate,
      comparisonPeriod,
      locale,
      usePeriodLengthForPreviousPeriod,
      timezone
    ).start
  );
  const [comparisonEndDate, setComparisonEndDate] = useState<string>(
    computeComparisonDateRange(
      startDate,
      endDate,
      comparisonPeriod,
      locale,
      usePeriodLengthForPreviousPeriod,
      timezone
    ).end
  );
  const [dateRangeSelection, setDateRangeSelection] = useState<DateRangeTitle>('Last 30 days');
  const [dateFilterPeriod, setDateFilterPeriod] = useState<DateFilterPeriod | null>(
    DateFilterPeriod.DateFilterPeriodLast_30Days
  );

  const setDateRange = (start: string, end: string) => {
    Tracker.track({
      eventName: TrackerEvents.ANALYTICS_DATE_RANGE_CHANGED,
      properties: {
        startDate: start,
        endDate: end,
      },
    });

    setStartDate(start);
    setEndDate(end);
    if (comparisonPeriod) {
      const computedComparisonDateRange = computeComparisonDateRange(
        start,
        end,
        comparisonPeriod,
        locale,
        usePeriodLengthForPreviousPeriod,
        timezone
      );
      setComparisonStartDate(computedComparisonDateRange.start);
      setComparisonEndDate(computedComparisonDateRange.end);
    }
  };

  useEffect(() => {
    if (comparisonPeriod) {
      const computedComparisonDateRange = computeComparisonDateRange(
        startDate,
        endDate,
        comparisonPeriod,
        locale,
        usePeriodLengthForPreviousPeriod,
        timezone
      );
      setComparisonStartDate(computedComparisonDateRange.start);
      setComparisonEndDate(computedComparisonDateRange.end);
    }
  }, [comparisonPeriod, startDate, endDate, locale, usePeriodLengthForPreviousPeriod, timezone]);

  const groupByMonth = moment(endDate).diff(moment(startDate), 'months') >= 3;

  return (
    <MetricsDateRangeSyncContext.Provider
      value={{
        startDate,
        endDate,
        dateFilterPeriod,
        setDateRange,
        groupByMonth,
        comparisonStartDate,
        comparisonEndDate,
        shouldCompareToPreviousDates,
        setShouldCompareToPreviousDates,
        comparisonPeriod,
        setComparisonPeriod,
        dateRangeSelection,
        setDateRangeSelection,
        setDateFilterPeriod,
      }}
    >
      {children}
    </MetricsDateRangeSyncContext.Provider>
  );
};

export {
  MetricsDateRangeSyncProvider,
  useMetricsDateRangeSyncContext,
  MetricsComparisonPeriod,
  computeComparisonDateRange,
};
