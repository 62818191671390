/**
 * @generated SignedSource<<85af92629c1cf2d85f8d3ef3a5a6cf90>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReportTable_ReportingPlatformLib_metrics$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"useGenerateRawMetricsRows_ReportingPlatform_metrics" | "useGenerateReportMetricsColumnHeaders_ReportingPlatform_metrics" | "useVisibleMetrics_ReportingPlatform_metrics">;
  readonly " $fragmentType": "ReportTable_ReportingPlatformLib_metrics";
}>;
export type ReportTable_ReportingPlatformLib_metrics$key = ReadonlyArray<{
  readonly " $data"?: ReportTable_ReportingPlatformLib_metrics$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReportTable_ReportingPlatformLib_metrics">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ReportTable_ReportingPlatformLib_metrics",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useVisibleMetrics_ReportingPlatform_metrics"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useGenerateReportMetricsColumnHeaders_ReportingPlatform_metrics"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useGenerateRawMetricsRows_ReportingPlatform_metrics"
    }
  ],
  "type": "Metric",
  "abstractKey": null
};

(node as any).hash = "469a904e81dd2ce11874d7a1e56f9c81";

export default node;
