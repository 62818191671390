import React, { useState } from 'react';

import { Heading, PicnicCss, Tooltip } from '@attentive/picnic';

import { LegacyMetric } from '../../services';

type TooltipProps = React.ComponentProps<typeof Tooltip>;
export interface MetricHeadingProps {
  metric: LegacyMetric;
  size?: 'small' | 'medium';
  onTooltipOpenChange?: TooltipProps['onOpenChange'];
}

export const MetricHeading: React.VFC<MetricHeadingProps> = ({
  metric,
  size = 'medium',
  onTooltipOpenChange,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const { name, description } = metric;

  const handleTooltipOpenChange = (open: boolean) => {
    if (onTooltipOpenChange) {
      onTooltipOpenChange(open);
    }

    setIsTooltipOpen(open);
  };

  const headingVariant = size === 'small' ? 'subheading' : 'md';

  const sharedHeadingStyles: PicnicCss = {
    display: 'inline-block',
    borderBottomWidth: '1px',
  };

  // do not show tooltip if description is undefined
  if (!description) {
    return (
      <Heading css={sharedHeadingStyles} variant={headingVariant}>
        {name}
      </Heading>
    );
  }

  return (
    <Tooltip onOpenChange={handleTooltipOpenChange} open={isTooltipOpen}>
      <Tooltip.Trigger>
        <Heading
          variant={headingVariant}
          css={{
            ...sharedHeadingStyles,
            borderBottomStyle: 'dotted',
            '&:hover': {
              borderBottomStyle: 'solid',
            },
          }}
        >
          {name}
        </Heading>
      </Tooltip.Trigger>
      <Tooltip.Content>{description}</Tooltip.Content>
    </Tooltip>
  );
};
