import { parse, format } from 'date-fns';
import { Formik, Form, FormikProps } from 'formik';
import React, { ChangeEvent, useEffect, useRef, VFC } from 'react';
import * as Yup from 'yup';

import {
  Box,
  Button,
  DateRangeChangeType,
  DATE_FNS_ISO_8601_DATE_FORMAT,
  FormField,
  PicnicCss,
  TextInput,
  PicnicSpaceToken,
  TextInputProps,
} from '@attentive/picnic';

import {
  isValidDate,
  isDateAvailable,
  isValidDateRange,
  removeInvalidDateCharacters,
} from './utils';

export type TypableDateSchema = Yup.SchemaOf<DateRangeFormFields>;

interface TypableDateRangeProps {
  startDate: string | null;
  endDate: string | null;
  onChange?: DateRangeChangeType;
  dateSchema?: TypableDateSchema;
  includeEndDateInput?: boolean;
  size?: TextInputProps['size'];
  spacing?: PicnicSpaceToken;
  disabled?: boolean;
  startDateFormLabel?: string;
  endDateFormLabel?: string;
  startDateCss?: PicnicCss;
  endDateCss?: PicnicCss;
  css?: PicnicCss;
}

type DateRangeFormFields = {
  startDate: string | null;
  endDate: string | null;
};

const DateRangeSchema: Yup.SchemaOf<DateRangeFormFields> = Yup.object({
  startDate: Yup.string()
    .required('Required')
    .test('isValidDate', 'YYYY-MM-DD required', isValidDate)
    .test('isDateInValidRange', 'Date unavailable', isDateAvailable)
    .test('isValidRange', 'Invalid range', (value, context) =>
      isValidDateRange({
        newEnd: context.parent.endDate,
        newStart: value,
      })
    ),
  endDate: Yup.string()
    .required('Required')
    .test('isValidDate', 'YYYY-MM-DD required', isValidDate)
    .test('isDateInValidRange', 'Date unavailable', isDateAvailable)
    .test('isValidRange', 'Invalid range', (value, context) =>
      isValidDateRange({
        newEnd: value,
        newStart: context.parent.startDate,
      })
    ),
}).defined();

export const TypableDateRange: VFC<TypableDateRangeProps> = ({
  startDate,
  endDate,
  onChange,
  dateSchema,
  includeEndDateInput = true,
  size = 'small',
  spacing = '$space11',
  disabled = false,
  startDateFormLabel = 'Start date',
  endDateFormLabel = 'End date',
  startDateCss,
  endDateCss,
  css,
}) => {
  const formikRef: React.Ref<FormikProps<DateRangeFormFields>> =
    useRef<FormikProps<DateRangeFormFields>>(null);

  const handleSubmit = ({ startDate: newStartDate, endDate: newEndDate }: DateRangeFormFields) => {
    const parsedStartDate = parse(newStartDate || '', DATE_FNS_ISO_8601_DATE_FORMAT, new Date());
    const parsedEndDate = parse(newEndDate || '', DATE_FNS_ISO_8601_DATE_FORMAT, new Date());
    if (onChange) {
      onChange({
        startDate: newStartDate
          ? format(new Date(parsedStartDate), DATE_FNS_ISO_8601_DATE_FORMAT)
          : null,
        endDate: newEndDate ? format(new Date(parsedEndDate), DATE_FNS_ISO_8601_DATE_FORMAT) : null,
      });
    }
  };

  useEffect(() => {
    // update the form fields when the date is changed from the outside,
    // i.e. user selects date range from dropdown or calendar
    // but first, reset the form to clear all errors and touched states
    formikRef.current?.resetForm();
    formikRef.current?.setFieldValue('startDate', startDate);
    formikRef.current?.setFieldValue('endDate', endDate);
  }, [startDate, endDate]);

  return (
    <Box
      css={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        ...css,
      }}
      data-dashboard-id="DATE RANGE INPUT"
    >
      <Formik
        initialValues={{
          startDate,
          endDate,
        }}
        validationSchema={dateSchema ? dateSchema : DateRangeSchema}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {({ values, errors, touched, setTouched, handleChange, submitForm }) => (
          <Box
            as={Form}
            css={{
              display: 'flex',
              flexDirection: 'row',
              flexGrow: 1,
            }}
          >
            <Box
              as={FormField}
              css={{
                width: '100%',
                mr: spacing,
                ...startDateCss,
              }}
            >
              <FormField.Label>{startDateFormLabel}</FormField.Label>
              <TextInput
                aria-label="Type the start date in YYYY-MM-DD format"
                id="startDate"
                name="startDate"
                size={size}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  e.target.value = removeInvalidDateCharacters(e.target.value);
                  handleChange(e);
                }}
                state={touched.startDate && errors.startDate ? 'error' : 'normal'}
                onBlur={() => {
                  setTouched({ startDate: true }, true);
                  submitForm();
                }}
                value={values.startDate || ''}
                placeholder="YYYY-MM-DD"
                disabled={disabled}
              />
              <FormField.ErrorText>
                {touched.startDate && errors.startDate ? errors.startDate : null}
              </FormField.ErrorText>
            </Box>
            {includeEndDateInput && (
              <Box
                as={FormField}
                css={{
                  width: '100%',
                  ...endDateCss,
                }}
              >
                <FormField.Label>{endDateFormLabel}</FormField.Label>
                <TextInput
                  aria-label="Type the end date in YYYY-MM-DD format"
                  id="endDate"
                  name="endDate"
                  size={size}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    e.target.value = removeInvalidDateCharacters(e.target.value);
                    handleChange(e);
                  }}
                  state={touched.endDate && errors.endDate ? 'error' : 'normal'}
                  onBlur={() => {
                    setTouched({ endDate: true }, true);
                    submitForm();
                  }}
                  value={values.endDate || ''}
                  placeholder="YYYY-MM-DD"
                  disabled={disabled}
                />
                <FormField.ErrorText>
                  {touched.endDate && errors.endDate ? errors.endDate : null}
                </FormField.ErrorText>
              </Box>
            )}

            <Button type="submit" css={{ display: 'none' }} />
          </Box>
        )}
      </Formik>
    </Box>
  );
};
