import { deserializeGQLDateTime } from '@attentive/data';

import { TimeDimensionGranularity, DimensionFilter, DimensionGrouping } from '../../services';
import { ComparisonPeriod } from '../../types';
import {
  getStartOfDayUTCFromCalendarDay,
  getEndOfDayUTCFromCalendarDay,
  getCalendarDay,
} from '../../utils';
import {
  ReportFilter,
  ReportGrouping,
  ReportStringGrouping,
  ReportDateGranularityType,
  FilterOperator,
  ReportStringFilter,
  ReportTimeFilter,
} from '../reports/types';

// TODO: Remove fallback date dimensionId check when we migrate to fragment types
export const isReportTimeFilter = (filter: ReportFilter): filter is ReportTimeFilter =>
  filter.__typename === 'ReportTimeFilter' || filter.dimensionId === 'date';

// TODO: Remove fallback date dimensionId check when we migrate to fragment types
export const isReportStringFilter = (filter: ReportFilter): filter is ReportStringFilter =>
  filter.__typename === 'ReportStringFilter' || filter.dimensionId !== 'date';

export const convertReportFiltersToDimensionFilters = ({
  startDate,
  endDate,
  reportFilters,
}: {
  startDate: string;
  endDate: string;
  reportFilters: ReportFilter[] | undefined;
}): DimensionFilter[] => {
  if (!reportFilters) {
    return [];
  }
  // These are DimensionFilters. MetricsAPI expects the time
  // to have a Z at the end, and it will apply company timezone.
  // i.e startDate should always look like "2023-06-05T00:00:00.000Z"
  // regardless of what company timezone is.
  return reportFilters.flatMap((filter) => {
    if (isReportTimeFilter(filter)) {
      return {
        dimensionId: filter.dimensionId,
        startDate: getStartOfDayUTCFromCalendarDay(startDate),
        endDate: getEndOfDayUTCFromCalendarDay(endDate),
        value: null,
        operator: null,
        list: null,
        nodeId: null,
        nodeIds: null,
      };
    }

    return {
      value: null,
      operator:
        filter.filterOperator === FilterOperator.FilterOperatorEquals
          ? FilterOperator.FilterOperatorIn
          : filter.filterOperator,
      dimensionId: filter.dimensionId === 'trigger_name_v2' ? 'trigger_name' : filter.dimensionId,
      startDate: null,
      endDate: null,
      list: { values: filter.values },
      nodeId: null,
      nodeIds: null,
    };
  });
};

export const convertComparisonReportFiltersToDimensionFilters = ({
  comparisonPeriod,
  reportFilters,
  timezone,
}: {
  comparisonPeriod: ComparisonPeriod;
  reportFilters: ReportFilter[] | undefined;
  timezone: string;
}) => {
  if (!(comparisonPeriod?.startDate && comparisonPeriod.endDate)) {
    return [];
  }

  return convertReportFiltersToDimensionFilters({
    startDate: getCalendarDay(deserializeGQLDateTime(comparisonPeriod.startDate), timezone),
    endDate: getCalendarDay(deserializeGQLDateTime(comparisonPeriod.endDate), timezone),
    reportFilters,
  });
};

export const convertReportGroupingsToMetricGroupings = ({
  reportGroupings,
  dateGranularity,
}: {
  reportGroupings: ReportGrouping[] | undefined;
  dateGranularity?: ReportDateGranularityType | null;
}): DimensionGrouping[] => {
  return (reportGroupings || []).map((grouping) => {
    // if this is a ReportTimeGrouping, set granularity
    if (grouping.__typename === 'ReportTimeGrouping') {
      return {
        dimensionId: grouping.dimensionId,
        // do not use "Entire range" as a granularity
        // if a dateDimension is passed in, use that
        // otherwise default to what the report was originally
        granularity:
          dateGranularity !== 'Entire range'
            ? (dateGranularity as TimeDimensionGranularity)
            : grouping.granularity,
      };
    }
    // this is a ReportStringGrouping, null out ReportTimeGrouping input types
    return {
      dimensionId: (grouping as ReportStringGrouping).dimensionId,
      granularity: null,
    };
  });
};

export const getSingleRegionFilterValue = (reportFilters: ReportFilter[]) => {
  const regionFilter = reportFilters.find(
    (filter): filter is ReportStringFilter =>
      isReportStringFilter(filter) && filter.dimensionId === 'region'
  );
  const hasSingleRegion =
    regionFilter?.values.length === 1 &&
    regionFilter.filterOperator === FilterOperator.FilterOperatorIn;
  if (!regionFilter || !hasSingleRegion) return undefined;
  return regionFilter.values[0];
};
