import React, { Fragment } from 'react';

import { Grid, Text } from '@attentive/picnic';

import { BarChartCircle } from '../BarChartLegendItem';

export const VerticalLegend = ({
  values,
  labels,
  colors,
  percentChange,
}: {
  values: string[];
  labels: string[];
  colors: string[];
  percentChange?: number;
}) => {
  const hasPercentChange = percentChange !== undefined;
  const numCols = hasPercentChange ? 3 : 2;

  return (
    <Grid
      columns={numCols}
      css={{
        gridTemplateColumns: `repeat(${numCols}, auto)`,
        columnGap: '$space5',
        rowGap: '$space2',
        placeContent: 'start',
        justifyContent: 'center',
        textAlign: 'right',
      }}
    >
      {values.map((value, index) => {
        const color = colors[index];
        const label = labels[index];
        const magic = color === 'magic';
        const showPercentChange = hasPercentChange && index === values.length - 1;

        return (
          <Fragment key={label}>
            <Grid.Cell css={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <BarChartCircle magic={magic} css={{ backgroundColor: color }} />
              <Text variant="caption">{label}</Text>
            </Grid.Cell>
            <Grid.Cell>
              <Text variant="caption" css={{ fontWeight: '$bold' }}>
                {value}
              </Text>
            </Grid.Cell>
            {hasPercentChange && (
              <Grid.Cell>
                {showPercentChange && (
                  <Text variant="caption" color="success" css={{ fontWeight: '$bold' }}>
                    +{percentChange}%
                  </Text>
                )}
              </Grid.Cell>
            )}
          </Fragment>
        );
      })}
    </Grid>
  );
};
