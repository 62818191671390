import { add } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

import { castStringAsSerializedDateTime, deserializeGQLDateTime } from '@attentive/data';
import { DateFormat, formatDateWithTimezone, Locale } from '@attentive/locale-utils';

export function convertDateToGQLDateTime(date: Date) {
  const isoDate = date.toISOString();
  return castStringAsSerializedDateTime(isoDate);
}

export function getStartOfDayForTimezoneFromCalendarDay(
  calendarDay: string,
  timezone: string
): SerializedDateTime {
  const zoneInitializedDate = zonedTimeToUtc(calendarDay, timezone);
  return convertDateToGQLDateTime(zoneInitializedDate);
}

export function getEndOfDayForTimezoneFromCalendarDay(
  calendarDay: string,
  timezone: string
): SerializedDateTime {
  const zoneInitializedDate = zonedTimeToUtc(calendarDay, timezone);
  const endOfZoneInitializedDay = add(zoneInitializedDate, { hours: 23, minutes: 59, seconds: 59 });
  return convertDateToGQLDateTime(endOfZoneInitializedDay);
}

export function getStartOfDayUTCFromCalendarDay(calendarDay: string): SerializedDateTime {
  const UTCInitializedDate = zonedTimeToUtc(calendarDay, 'UTC');
  return convertDateToGQLDateTime(UTCInitializedDate);
}

export function getEndOfDayUTCFromCalendarDay(calendarDay: string): SerializedDateTime {
  const UTCInitializedDate = zonedTimeToUtc(calendarDay, 'UTC');
  const endOfUTCInitializedDay = add(UTCInitializedDate, { hours: 23, minutes: 59, seconds: 59 });
  return convertDateToGQLDateTime(endOfUTCInitializedDay);
}

export function getCalendarDay(date: Date, timezone: string) {
  if (timezone === 'UTC') {
    // you have to get the date substring this way instead of using
    // date-fns's `format` function because the `format` fuction assumes
    // the timezone is local time
    const clippedDate = date.toISOString().substring(0, 10);
    return clippedDate;
  }
  // locale has no affect on DateFormat.ISO_8601, so just pass Locale.enUS instead of
  // worrying about passing it around elsewhere
  return formatDateWithTimezone(date, DateFormat.ISO_8601, { locale: Locale.enUS, timezone });
}

/** --------------------------------------------------------------------------------
 * 'UTC' timestamps have a Z at the end, to indicate UTC-0 timezone.
 *  Some timestamps in this MFE are currently in a 'Faux' UTC format,
 *  in which the z is misleading and the timestamp should be considered "timezoneless"
 *  For example, 2023-01-01T00:00:000Z is the "Faux" UTC timestamp for the beginning of the day,
 *  Whereas the "Real" UTC timestamp is 2023-01-01T05:00:000Z, depending on the company timezone.
 *  Data Platform have plans to stop using the Faux UTC timestamp when we move to metricsTable()
 *  but for now, use these utils to convert between the two.
 * --------------------------------------------------------------------------------
 */

export const getStartOfDayUTCFromTimezoneDate = (date: SerializedDateTime, timezone: string) =>
  getStartOfDayUTCFromCalendarDay(getCalendarDay(deserializeGQLDateTime(date), timezone));

export const getEndOfDayUTCFromTimezoneDate = (date: SerializedDateTime, timezone: string) =>
  getEndOfDayUTCFromCalendarDay(getCalendarDay(deserializeGQLDateTime(date), timezone));

export const getStartOfDayForTimezoneFromUTCDate = (date: SerializedDateTime, timezone: string) =>
  getStartOfDayForTimezoneFromCalendarDay(
    getCalendarDay(deserializeGQLDateTime(date), 'UTC'),
    timezone
  );

export const getEndOfDayForTimezoneFromUTCDate = (date: SerializedDateTime, timezone: string) =>
  getEndOfDayForTimezoneFromCalendarDay(
    getCalendarDay(deserializeGQLDateTime(date), 'UTC'),
    timezone
  );
