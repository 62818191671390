/**
 * @generated SignedSource<<19a1461d54402630b5e3d02048fe934a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useCMGReportUrlDeepLinkFragment_campaignMessageGroup$data = {
  readonly messageMembers: ReadonlyArray<{
    readonly composeMessage: {
      readonly name: string;
    } | null;
  }>;
  readonly name: string;
  readonly sendTimes: ReadonlyArray<{
    readonly startTime: SerializedDateTime;
  }>;
  readonly " $fragmentType": "useCMGReportUrlDeepLinkFragment_campaignMessageGroup";
};
export type useCMGReportUrlDeepLinkFragment_campaignMessageGroup$key = {
  readonly " $data"?: useCMGReportUrlDeepLinkFragment_campaignMessageGroup$data;
  readonly " $fragmentSpreads": FragmentRefs<"useCMGReportUrlDeepLinkFragment_campaignMessageGroup">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useCMGReportUrlDeepLinkFragment_campaignMessageGroup",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SendTime",
      "kind": "LinkedField",
      "name": "sendTimes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startTime",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CampaignMessageMember",
      "kind": "LinkedField",
      "name": "messageMembers",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ComposeMessage",
          "kind": "LinkedField",
          "name": "composeMessage",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CampaignMessageGroup",
  "abstractKey": null
};
})();

(node as any).hash = "3be0fd9d6408766c7520667138a4aa08";

export default node;
