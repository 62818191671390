import { CurrencyCode, Locale } from '@attentive/locale-utils';

const BILLABLE_REPORTS: { [key: string]: string } = {
  'Daily Message Cost Class': 'Daily Message Cost Class',
  'Campaign Spend Details Class': 'Campaign Spend Details Class',
};

export const isBillableReport = (reportClassId: string | undefined): boolean =>
  !!reportClassId && !!BILLABLE_REPORTS[reportClassId];

export const DEFAULT_LOCALE_CONFIG = {
  locale: Locale.enUS,
  currencyConfig: { currencyCode: CurrencyCode.US },
};
