import React from 'react';

import { Box, Heading, Icon, PicnicCss } from '@attentive/picnic';

const PU_MIN_HEIGHT = 240;

interface DataUnavailableNotificationProps {
  title?: string;
  css?: PicnicCss;
}

export const DataUnavailableNotification: React.FC<DataUnavailableNotificationProps> = ({
  title = 'No data to display',
  css,
  children,
}) => {
  return (
    <Box
      css={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: PU_MIN_HEIGHT,
        textAlign: 'center',
        ...css,
      }}
    >
      <Icon name="NoData" />
      <Heading variant="lg" css={{ mt: '$space4', mb: '$space4' }}>
        {title}
      </Heading>
      {children}
    </Box>
  );
};
