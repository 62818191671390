import _useAiProRevenueData_ReportingPlatform_company from "./__generated__/useAiProRevenueData_ReportingPlatform_company.graphql";
import { useFragment } from 'react-relay';
import { graphql } from '@attentive/data';
import { calculatePercent, extractSingleMetricAggregateValue } from '../utils';
import useAiProRevenueData_ReportingPlatform_company from './__generated__/useAiProRevenueData_ReportingPlatform_company.graphql';
_useAiProRevenueData_ReportingPlatform_company;
export const useAiProRevenueData = companyRef => {
  const company = useFragment(useAiProRevenueData_ReportingPlatform_company, companyRef);
  const {
    audiencesAiIncrementalRevenueMetrics,
    identityAiIncrementalRevenueMetrics,
    identityAiBaseRevenueMetrics,
    audiencesAiExpansionBaseRevenueMetrics,
    aiProReducedCostMetrics
  } = company;
  const audiencesAiExpansionBaseRevenue = extractSingleMetricAggregateValue(audiencesAiExpansionBaseRevenueMetrics);
  const audiencesAiIncrementalRevenue = extractSingleMetricAggregateValue(audiencesAiIncrementalRevenueMetrics);
  const identityAiBaseRevenue = extractSingleMetricAggregateValue(identityAiBaseRevenueMetrics);
  const identityAiIncrementalRevenue = extractSingleMetricAggregateValue(identityAiIncrementalRevenueMetrics);
  const reducedCostFromAiPro = extractSingleMetricAggregateValue(aiProReducedCostMetrics);
  const aiProIncrementalRevenue = audiencesAiIncrementalRevenue + identityAiIncrementalRevenue;
  const aiProBaseRevenue = audiencesAiExpansionBaseRevenue + identityAiBaseRevenue;
  const audiencesAiRevenueLiftPercent = calculatePercent({
    numerator: audiencesAiIncrementalRevenue,
    denominator: audiencesAiExpansionBaseRevenue
  });
  const identityAIRevenueLiftPercent = calculatePercent({
    numerator: identityAiIncrementalRevenue,
    denominator: identityAiBaseRevenue
  });
  const aiProRevenueLiftPercent = calculatePercent({
    numerator: aiProIncrementalRevenue,
    denominator: aiProBaseRevenue
  });
  return {
    aiProBaseRevenue: Math.round(aiProBaseRevenue),
    aiProIncrementalRevenue: Math.round(aiProIncrementalRevenue),
    aiProRevenueLiftPercent: parseFloat(aiProRevenueLiftPercent.toFixed(1)),
    audiencesAiExpansionBaseRevenue: Math.round(audiencesAiExpansionBaseRevenue),
    audiencesAiIncrementalRevenue: Math.round(audiencesAiIncrementalRevenue),
    audiencesAiRevenueLiftPercent: parseFloat(audiencesAiRevenueLiftPercent.toFixed(1)),
    identityAiBaseRevenue: Math.round(identityAiBaseRevenue),
    identityAiIncrementalRevenue: Math.round(identityAiIncrementalRevenue),
    identityAIRevenueLiftPercent: parseFloat(identityAIRevenueLiftPercent.toFixed(1)),
    reducedCostFromAiPro: Math.round(reducedCostFromAiPro)
  };
};