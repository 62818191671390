import React, { useState } from 'react';

import { Heading, Icon, Tooltip } from '@attentive/picnic';

interface MetricDashProps {
  metricErrorMessage?: string;
  size: 'small' | 'large';
  description?: string;
}

export const MetricDash: React.FC<MetricDashProps> = ({
  metricErrorMessage,
  size,
  description = 'Unable to calculate metric value',
}) => {
  const [isPercentChangeTooltipOpen, setIsPercentChangeTooltipOpen] = useState(false);
  return metricErrorMessage ? (
    <Tooltip onOpenChange={setIsPercentChangeTooltipOpen} open={isPercentChangeTooltipOpen}>
      <Tooltip.Trigger>
        <Heading
          variant={size === 'small' ? 'sm' : 'lg'}
          css={{
            borderBottomWidth: '1px',
            borderBottomStyle: 'dotted',
            '&:hover': { borderBottomStyle: 'solid' },
          }}
        >
          <Icon
            css={{ pointerEvents: 'none' }}
            name="MinusSign"
            size={size === 'small' ? 'extraSmall' : 'medium'}
            mode="presentational"
            description={description}
          />
        </Heading>
      </Tooltip.Trigger>
      <Tooltip.Content>{metricErrorMessage}</Tooltip.Content>
    </Tooltip>
  ) : (
    <Icon
      css={{ pointerEvents: 'none' }}
      name="MinusSign"
      size={size === 'small' ? 'extraSmall' : 'medium'}
      mode="presentational"
      description={description}
    />
  );
};
