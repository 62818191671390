// eslint-disable-next-line import/no-extraneous-dependencies
import { faker } from '@faker-js/faker';

import { MockReportFactory } from './MockReportFactory';
import { ReportDomain } from './types';

interface CreateArgs {
  numReports?: number;
  overrides?: Partial<ReportDomain>;
}
export const ReportDomainFactory = {
  create: ({ numReports, overrides }: CreateArgs = {}): ReportDomain => {
    const domainTitle = `Domain ${faker.random.word()}`;
    return {
      title: domainTitle,
      description: faker.lorem.paragraph(),
      displayOrder: faker.datatype.number(),
      domainId: faker.random.numeric(),
      reports: [
        ...Array(
          numReports !== undefined ? numReports : faker.datatype.number({ min: 2, max: 10 })
        ),
      ].map(() => MockReportFactory.create()),
      ...overrides,
    };
  },
  createMany: (
    {
      numDomains,
    }: {
      numDomains?: number;
    } = { numDomains: 5 }
  ): ReportDomain[] => {
    return [...Array(numDomains)].map(() => ReportDomainFactory.create());
  },
};
