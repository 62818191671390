/**
 * @generated SignedSource<<5c00218278ef9ff3565cd2930d0290b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReportDomainsContextReportingPlatform_query$data = {
  readonly company: {
    readonly id: string;
    readonly reports?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string;
          readonly reportClass: {
            readonly name: string;
          } | null;
          readonly reportDomain: {
            readonly description: string;
            readonly displayOrder: number;
            readonly domainId: string;
            readonly name: string;
          };
          readonly reportId: string;
        };
      }>;
    } | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"useHasAIJourneysPerformanceReportAccess_ReportingPlatform_query">;
  readonly " $fragmentType": "ReportDomainsContextReportingPlatform_query";
};
export type ReportDomainsContextReportingPlatform_query$key = {
  readonly " $data"?: ReportDomainsContextReportingPlatform_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReportDomainsContextReportingPlatform_query">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "companyId"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReportDomainsContextReportingPlatform_query",
  "selections": [
    {
      "alias": "company",
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "companyId"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "first",
                  "variableName": "first"
                }
              ],
              "concreteType": "ReportConnection",
              "kind": "LinkedField",
              "name": "reports",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ReportEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Report",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "reportId",
                          "storageKey": null
                        },
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ReportClass",
                          "kind": "LinkedField",
                          "name": "reportClass",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ReportDomain",
                          "kind": "LinkedField",
                          "name": "reportDomain",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "description",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "displayOrder",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "domainId",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Company",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "companyId",
          "variableName": "companyId"
        }
      ],
      "kind": "FragmentSpread",
      "name": "useHasAIJourneysPerformanceReportAccess_ReportingPlatform_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "cec5e110dd671e011ff2990a90ed0797";

export default node;
