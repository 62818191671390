import _useCMGReportUrlDeepLinkFragment_campaignMessageGroup from "./__generated__/useCMGReportUrlDeepLinkFragment_campaignMessageGroup.graphql";
import _useCMGReportUrlDeepLinkFragment_composeCampaign from "./__generated__/useCMGReportUrlDeepLinkFragment_composeCampaign.graphql";
import { useFragment } from 'react-relay';
import { deserializeGQLDateTime, graphql } from '@attentive/data';
import { generateReportUrl, getCalendarDay } from '../utils';
import useCMGReportUrlDeepLinkFragment_campaignMessageGroup from './__generated__/useCMGReportUrlDeepLinkFragment_campaignMessageGroup.graphql';
import useCMGReportUrlDeepLinkFragment_composeCampaign from './__generated__/useCMGReportUrlDeepLinkFragment_composeCampaign.graphql';
_useCMGReportUrlDeepLinkFragment_composeCampaign;
_useCMGReportUrlDeepLinkFragment_campaignMessageGroup;
export const useCMGReportUrlDeepLink = (campaignRef, campaignMessageGroupRef) => {
  const campaign = useFragment(useCMGReportUrlDeepLinkFragment_composeCampaign, campaignRef);
  const campaignMessageGroup = useFragment(useCMGReportUrlDeepLinkFragment_campaignMessageGroup, campaignMessageGroupRef);
  const {
    name: campaignName
  } = campaign;
  const {
    name: campaignMessageGroupName
  } = campaignMessageGroup;
  const {
    externalId: externalCompanyId,
    timezone: companyTimezone
  } = campaign.company || {};
  const cmgSendTimes = campaignMessageGroup.sendTimes.map(sendTime => deserializeGQLDateTime(sendTime.startTime).getTime());
  const earliestSendTime = new Date(Math.min.apply(null, cmgSendTimes));
  const oneDayBeforeEarliestSendTime = new Date(earliestSendTime.getTime() - 24 * 60 * 60 * 1000);
  const todaysDate = new Date();
  const reportParams = [{
    dimensionId: 'campaign_name',
    operator: 'FILTER_OPERATOR_IN',
    dimensionSets: [{
      k: campaignName,
      v: campaignName
    }]
  }, {
    dimensionId: 'campaign_message_group_name',
    operator: 'FILTER_OPERATOR_IN',
    dimensionSets: [{
      k: campaignMessageGroupName,
      v: campaignMessageGroupName
    }]
  }];

  if (!companyTimezone) {
    throw new Error('Company timezone is required to generate report URL');
  }

  if (!externalCompanyId) {
    throw new Error('External company ID is required to generate report URL');
  }

  return {
    generateUrlForReport: reportName => generateReportUrl({
      reportName,
      reportParams,
      externalCompanyId,
      dateRange: {
        startDate: getCalendarDay(oneDayBeforeEarliestSendTime, companyTimezone),
        endDate: getCalendarDay(todaysDate, companyTimezone)
      }
    })
  };
};