import React from 'react';

import {
  Box,
  DisplayNamed,
  Paginator as PicnicPaginator,
  PicnicCss,
  Select,
  Text,
} from '@attentive/picnic';

enum DynamicPaginatorMaxItemsPerPage {
  TWENTY_FIVE = 25,
  FIFTY = 50,
  ONE_HUNDRED = 100,
}

type DynamicPaginatorVariant = 'stacked' | 'condensed-single-row' | 'single-row';
const DEFAULT_VARIANT: DynamicPaginatorVariant = 'single-row';

interface DynamicPaginatorProps {
  totalItems: number;
  maxItemsPerPage: number;
  onMaxItemsPerPageChange: (nextMaxItemsPerPage: number) => void;
  onOffsetChange: (offset: number) => void;
  offset: number;
  variant?: DynamicPaginatorVariant;
  disabled?: boolean;
  css?: PicnicCss;
}

type DynamicPaginatorDropdownProps = Pick<
  DynamicPaginatorProps,
  'maxItemsPerPage' | 'onMaxItemsPerPageChange' | 'variant' | 'disabled' | 'css'
>;
const PU_SELECT_MIN_WIDTH = 100;
const DynamicPaginatorDropdown: React.VFC<DynamicPaginatorDropdownProps> = ({
  maxItemsPerPage,
  onMaxItemsPerPageChange,
  variant,
  disabled,
  css,
}) => {
  return (
    <Box css={{ display: 'flex', flexDirection: 'row', alignItems: 'center', ...css }}>
      <Text
        css={{
          display: variant === 'single-row' ? 'block' : 'none',
        }}
      >
        Rows per page
      </Text>
      <Select
        value={maxItemsPerPage}
        onChange={onMaxItemsPerPageChange}
        size="small"
        disabled={disabled}
        css={{ width: 'auto', minWidth: PU_SELECT_MIN_WIDTH, ml: '$space2', mr: '$space4' }}
      >
        {Object.values(DynamicPaginatorMaxItemsPerPage)
          // remove the enum keys -- assumes the enum values are numbers
          .filter((value) => !isNaN(parseInt(value as string)))
          .map((value) => {
            return (
              <Select.Item key={value} value={value}>
                {value}
              </Select.Item>
            );
          })}
      </Select>
    </Box>
  );
};

const DynamicPaginatorButtonGroup = PicnicPaginator.ButtonGroup;
const DynamicPaginatorOffsetLabel = PicnicPaginator.Label;

const DynamicPaginatorComponent = ({
  maxItemsPerPage,
  onMaxItemsPerPageChange,
  totalItems,
  offset,
  onOffsetChange,
  variant = DEFAULT_VARIANT,
  disabled = false,
  css,
}: DynamicPaginatorProps) => {
  const handleMaxItemsPerPageChange: DynamicPaginatorProps['onMaxItemsPerPageChange'] = (value) => {
    onMaxItemsPerPageChange(value);
    // reset offset
    onOffsetChange(0);
  };

  return (
    <Box
      css={{
        display: 'flex',
        alignContent: 'flex-end',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: variant === 'stacked' ? 'end' : 'center',
        flexWrap: 'wrap',
        gap: '$space4',
        width: variant === 'stacked' ? '100%' : 'auto',
        ...css,
      }}
    >
      <DynamicPaginatorDropdown
        maxItemsPerPage={maxItemsPerPage}
        onMaxItemsPerPageChange={handleMaxItemsPerPageChange}
        variant={variant}
        disabled={disabled}
        css={{ order: 0 }}
      />

      <PicnicPaginator
        hasStartEndButtons={true}
        maxItemsPerPage={maxItemsPerPage}
        totalItems={totalItems}
        offset={offset}
        disabled={disabled}
        onOffsetChange={onOffsetChange}
        css={{
          textAlign: 'right',
          alignItems: variant === 'stacked' ? 'end' : 'center',
          gap: '$space4',
          flexDirection: variant === 'stacked' ? 'column' : 'row',
          flexWrap: 'wrap',
        }}
      />
    </Box>
  );
};

type ComponentType = typeof DynamicPaginatorComponent & DisplayNamed;
interface CompositeComponent extends ComponentType {
  Label: typeof DynamicPaginatorOffsetLabel & DisplayNamed;
  ButtonGroup: typeof DynamicPaginatorButtonGroup & DisplayNamed;
}

const DynamicPaginator = DynamicPaginatorComponent as CompositeComponent;
DynamicPaginator.Label = DynamicPaginatorOffsetLabel;
DynamicPaginator.ButtonGroup = DynamicPaginatorButtonGroup;

DynamicPaginator.displayName = 'DynamicPaginator';
DynamicPaginator.Label.displayName = 'DynamicPaginator.Label';
DynamicPaginator.ButtonGroup.displayName = 'DynamicPaginator.ButtonGroup';

export type { DynamicPaginatorProps };
export { DynamicPaginator, DynamicPaginatorMaxItemsPerPage };
