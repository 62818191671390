import _transformMetricsTable_ReportingPlatform_metricsTable from "./__generated__/transformMetricsTable_ReportingPlatform_metricsTable.graphql";
import { graphql, readInlineData } from '@attentive/data';
import MetricsTableFragment from './__generated__/transformMetricsTable_ReportingPlatform_metricsTable.graphql';
_transformMetricsTable_ReportingPlatform_metricsTable;
export const transformMetricsTable = metricsTableRef => {
  if (!metricsTableRef) return null;
  const metricsTableData = readInlineData(MetricsTableFragment, metricsTableRef);
  const metricsData = []; // Index is based on definitions since they are always returned

  metricsTableData.definitions.forEach((metricDefinition, index) => {
    var _metricsTableData$agg, _metricsTableData$agg2, _metricsTableData$edg, _metricsTableData$edg2, _metricsTableData$edg3;

    const definition = metricDefinition;
    const aggregateValue = ((_metricsTableData$agg = metricsTableData.aggregateValues) === null || _metricsTableData$agg === void 0 ? void 0 : (_metricsTableData$agg2 = _metricsTableData$agg[index]) === null || _metricsTableData$agg2 === void 0 ? void 0 : _metricsTableData$agg2.value) !== undefined ? metricsTableData.aggregateValues[index].value : 0;
    const errors = ((_metricsTableData$edg = metricsTableData.edges) === null || _metricsTableData$edg === void 0 ? void 0 : (_metricsTableData$edg2 = _metricsTableData$edg[0]) === null || _metricsTableData$edg2 === void 0 ? void 0 : (_metricsTableData$edg3 = _metricsTableData$edg2.node.values[index]) === null || _metricsTableData$edg3 === void 0 ? void 0 : _metricsTableData$edg3.errors) || [];
    const groupedValues = metricsTableData.edges.map(edge => {
      var _edge$node$values$ind;

      return {
        groupingDimensionValues: [],
        // Ignored deprecated value
        groupingDimensions: edge.node.groupingDimensions || [],
        value: (_edge$node$values$ind = edge.node.values[index]) === null || _edge$node$values$ind === void 0 ? void 0 : _edge$node$values$ind.value,
        errors
      };
    }).filter(_ref => {
      let {
        value
      } = _ref;
      return value !== null;
    });
    const metricData = {
      aggregateValue,
      definition,
      groupedValues,
      errors
    };
    metricsData.push(metricData);
  });
  return metricsData;
};