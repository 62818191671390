// eslint-disable-next-line import/no-extraneous-dependencies
import { faker } from '@faker-js/faker';
import {
  differenceInDays,
  differenceInMonths,
  differenceInWeeks,
  differenceInYears,
  format,
  parseISO,
  sub,
} from 'date-fns';
import merge from 'lodash/merge';
import snakeCase from 'lodash/snakeCase';
import startCase from 'lodash/startCase';
import uniqBy from 'lodash/uniqBy';

import { castStringAsSerializedDateTime } from '@attentive/data';
import {
  DimensionWrapper,
  GroupedMetrics,
  GroupedMetricsEdge,
  MetricsTableConnection,
} from '@attentive/data/types';
import { camelCase } from '@attentive/nodash';

import { getDateRangeOption } from '../../components';
import { Report } from '../reports';

import {
  Dimension,
  DimensionFilter,
  DimensionGrouping,
  GroupedMetricValue,
  Metric,
  MetricAggregationType,
  MetricConnotation,
  MetricDataType,
  MetricDefinition,
  StringDimension,
  TimeDimensionGranularity,
} from './types';

export type MockStringDimensionId = 'awesome_level' | 'journey_type' | 'channel';
export type MockDimensionId = 'date' | MockStringDimensionId;

const MockMetricDimensionNames: { [key: string]: string } = {
  ['date']: 'Date',
  ['awesome_level']: 'Awesomeness',
  ['journey_type']: 'Journey Type',
  ['channel']: 'Channel',
  ['message_id']: 'Message Id',
  ['region']: 'Region',
  ['trigger_name']: 'Trigger Name',
};

export const MockMetricStringDimensionPossibleValues: { [key: string]: string[] } = {
  ['awesome_level']: ['Not awesome', 'Kinda awesome', 'Ridonkulously awesome'],
  ['trigger_name']: [
    'Added to cart',
    'Joined a Segment',
    'Made a purchase',
    'Signed up for texts',
    'Viewed a product',
  ],
  ['journey_type']: ['Journey A', 'Journey B', 'Journey C', 'Journey D', 'Journey E'],
  ['channel']: ['Email', 'Text'],
  ['message_id']: ['1337', '631', '1111'],
  ['region']: ['US', 'CA', 'GB', 'CH', 'UNKNOWN'],
  ['has_audience_ai']: ['true', 'false'],
  ['audience_ai_segment_group']: ['Base', 'Expansion', 'Low Propensity'],
  ['audience_ai_scenario']: [
    'Expansion only',
    'Low propensity removed only',
    'Expansion + low propensity removed',
    'Expansion + low propensity tracked',
    'Low propensity tracked only',
  ],
};

const cartesianProduct = <T>(...sets: T[][]) =>
  sets.reduce<T[][]>(
    (accSets, set) => accSets.flatMap((accSet) => set.map((value) => [...accSet, value])),
    [[]]
  );

interface CreateMetricArgs {
  dimensionGroupings?: DimensionGrouping[];
  metricId?: string;
  filters?: DimensionFilter[];
  dataType?: MetricDataType;
  overrides?: Partial<Metric>;
}

interface CreateMetricListArgs {
  numMetrics: number;
  metricIds?: string[];
  dimensionGroupings?: DimensionGrouping[];
  filters?: DimensionFilter[];
  overridesList?: Array<Partial<Metric>>;
  dataType?: MetricDataType;
}

interface CreateMetricListForReportArgs extends Pick<Report, 'groupingDimensions'> {
  numMetrics: number;
  filters?: DimensionFilter[];
}

const DATE_FORMAT = 'yyyy-MM-dd hh:mm:ss';

const getDateDimensionValues = ({
  dateGrouping,
  filters,
}: {
  dateGrouping: DimensionGrouping;
  filters?: DimensionFilter[];
}): string[] => {
  const last30Days = getDateRangeOption('Last 30 days');
  const dateFilter = filters?.find((filter) => filter.dimensionId === 'date');

  const endDate = (dateFilter?.endDate || last30Days.getEndDate()) as unknown as string;
  const startDate = (dateFilter?.startDate || last30Days.getStartDate()) as unknown as string;

  if (dateGrouping.dimensionId !== 'date') {
    return [];
  }

  switch (dateGrouping.granularity) {
    case TimeDimensionGranularity.TimeDimensionGranularityDaily: {
      const numDatePoints = differenceInDays(parseISO(endDate), parseISO(startDate)) + 1 || 1;
      return [...Array(numDatePoints)].map((_, i) =>
        format(sub(parseISO(endDate), { days: i }), DATE_FORMAT)
      );
    }
    case TimeDimensionGranularity.TimeDimensionGranularityMonthly: {
      const numDatePoints = differenceInMonths(parseISO(endDate), parseISO(startDate)) || 1;
      return [...Array(numDatePoints)].map((_, i) =>
        format(sub(parseISO(endDate), { months: i }), DATE_FORMAT)
      );
    }
    case TimeDimensionGranularity.TimeDimensionGranularityWeekly: {
      const numDatePoints = differenceInWeeks(parseISO(endDate), parseISO(startDate)) || 1;
      return [...Array(numDatePoints)].map((_, i) =>
        format(sub(parseISO(endDate), { weeks: i }), DATE_FORMAT)
      );
    }
    case TimeDimensionGranularity.TimeDimensionGranularityYearly: {
      const numDatePoints = differenceInYears(parseISO(endDate), parseISO(startDate)) || 1;
      return [...Array(numDatePoints)].map((_, i) =>
        format(sub(parseISO(endDate), { years: i }), DATE_FORMAT)
      );
    }
    default: {
      return [];
    }
  }
};

const getDimensionValueArrays = ({
  groupings,
  filters,
  definition,
}: {
  groupings: DimensionGrouping[] | undefined;
  filters?: DimensionFilter[];
  definition: MetricDefinition;
}): string[][] => {
  const result =
    groupings?.map((requestedDimensionGrouping) => {
      // handle a dimension grouping if it is a valid grouping (based on definition)
      const dimensionDefinition = definition.dimensions.find(
        (d) => d.dimensionId === requestedDimensionGrouping.dimensionId
      );
      if (dimensionDefinition !== undefined) {
        // is this a date dimension?
        if (requestedDimensionGrouping.dimensionId === 'date') {
          return getDateDimensionValues({ dateGrouping: requestedDimensionGrouping, filters });
        }
        // assume this is a string dimension
        return (dimensionDefinition as StringDimension).possibleValues;
      }
      return [];
    }) || [];

  return result.filter((arr) => arr && arr.length > 0);
};

const consolidateCommonGroupedMetricValues = (
  consolidatedMetricValues: Map<string, GroupedMetricValue>,
  inputGroupedValue: GroupedMetricValue
): Map<string, GroupedMetricValue> => {
  const joinedKeys = inputGroupedValue.groupingDimensions.map(({ key }) => key).join();
  const prevValue = consolidatedMetricValues.get(joinedKeys)?.value;
  if (inputGroupedValue.value) {
    inputGroupedValue.value += prevValue ?? 0;
  }

  return consolidatedMetricValues.set(joinedKeys, inputGroupedValue);
};

const reduceGroupedMetricValuesToGroupedMetrics = (
  accumulatedGroupedMetrics: Map<string, GroupedMetrics>,
  inputGroupedMetricValues: Map<string, GroupedMetricValue>
): Map<string, GroupedMetrics> => {
  inputGroupedMetricValues.forEach(({ value, groupingDimensions }) => {
    const joinedKeys = groupingDimensions.map(({ key }) => key).join();

    const groupedMetric = accumulatedGroupedMetrics.get(joinedKeys) || {
      values: [],
      dimensionValues: groupingDimensions.map((dimension) => dimension.value),
      groupingDimensions,
    };
    groupedMetric.values.push({ value, errors: [] });
    accumulatedGroupedMetrics.set(joinedKeys, groupedMetric);
  });

  return accumulatedGroupedMetrics;
};

const mapGroupedMetricsToEdges = (node: GroupedMetrics, idx: number): GroupedMetricsEdge => ({
  node,
  cursor: `${idx}`,
});

export const MetricFactory = {
  createMetric: ({
    dimensionGroupings: requestedDimensionGroupings,
    metricId,
    filters,
    overrides,
    dataType = MetricDataType.MetricDataTypeNumber,
  }: CreateMetricArgs = {}): Metric => {
    const definition =
      overrides?.definition ||
      MetricFactory.createMetricDefinition({
        dimensionIds: requestedDimensionGroupings?.map((g) => g.dimensionId) || undefined,
        metricId,
        dataType,
      });

    const dimensionsToGenerateValuesFor = getDimensionValueArrays({
      groupings: uniqBy(requestedDimensionGroupings, (obj) => obj.dimensionId),
      definition,
      filters,
    });

    const groupingCombinations: string[][] = dimensionsToGenerateValuesFor
      ? cartesianProduct<string>(...dimensionsToGenerateValuesFor)
      : [];

    const groupedValues = groupingCombinations.map((groupingDimensionTuple) =>
      MetricFactory.createGroupedMetricValue({
        dataType: definition.dataType,
        groupingDimensionValues: groupingDimensionTuple,
      })
    );

    // apply filters
    const filteredGroupedValues = groupedValues.filter(({ groupingDimensions }) => {
      // if no filters passed in, return all values
      if (!filters) {
        return groupedValues;
      }

      // check the current grouped value against all filters
      const filtersResult = filters.reduce<boolean>((curValue, filter) => {
        // don't do extra filtering for date because
        // we use start and end date to generated values which acts as a filter
        if (filter.dimensionId === 'date') {
          return curValue;
        }

        // find the index of the dimension for this filter
        const dimensionIndex = definition.dimensionWrappers.findIndex(
          ({ dimension: dimensionDefinition }) =>
            dimensionDefinition.dimensionId === filter.dimensionId
        );

        // check the value of the filter against the groupedDimensions value
        const groupingDimensionValue = groupingDimensions[dimensionIndex]?.value;
        const filterResult =
          dimensionIndex >= 0 &&
          (groupingDimensionValue === filter.value ||
            groupingDimensionValue === filter.list?.values?.[0]);

        // if a single filter fails to match, do not return this groupedValue
        return curValue && filterResult;
      }, true);

      return filtersResult;
    });

    const aggregateValue = groupedValues.reduce((prevValue, currentItem) => {
      return (currentItem.value || 0) + prevValue;
    }, 0);

    return {
      __typename: 'Metric',
      aggregateValue,
      definition,
      groupedValues: filteredGroupedValues,
      errors: [],
    };
  },

  createMetricList: ({
    numMetrics,
    metricIds,
    overridesList,
    dimensionGroupings,
    filters,
    dataType,
  }: CreateMetricListArgs): Metric[] => {
    const dataTypeList = Object.values(MetricDataType);
    return (metricIds ? metricIds : [...Array(numMetrics)]).map((metricId, index) =>
      MetricFactory.createMetric({
        overrides: overridesList ? overridesList[index] : undefined,
        dimensionGroupings,
        metricId,
        filters,
        dataType: dataType ?? dataTypeList[index],
      })
    );
  },

  createMetricsTable: (metricList: Metric[]): MetricsTableConnection => {
    const pageLength = 10;
    const groupedMetricsMap = metricList
      .map(({ groupedValues }) => groupedValues)
      .map((groupedMetricValue) =>
        groupedMetricValue.reduce(consolidateCommonGroupedMetricValues, new Map())
      )
      .reduce(reduceGroupedMetricValuesToGroupedMetrics, new Map());

    return {
      aggregateValues: metricList.map(({ aggregateValue: value }) => ({ value, errors: [] })),
      definitions: metricList.map(({ definition }) => definition),
      edges: Array.from(groupedMetricsMap.values()).map(mapGroupedMetricsToEdges),
      pageInfo: {
        startCursor: '0',
        endCursor: `${pageLength}`,
        hasNextPage: groupedMetricsMap.size >= pageLength,
        hasPreviousPage: false,
      },
      totalCount: groupedMetricsMap.size,
    };
  },

  createGroupedMetricValue: ({
    groupingDimensionValues = [],
    dataType = MetricDataType.MetricDataTypeNumber,
  }: {
    groupingDimensionValues?: string[];
    dataType?: MetricDataType;
  }): GroupedMetricValue => {
    const value =
      dataType === MetricDataType.MetricDataTypePercent ? Math.random() : faker.datatype.number();

    return {
      __typename: 'GroupedMetricValue',
      groupingDimensionValues,
      groupingDimensions: groupingDimensionValues.map((dimensionValue) => ({
        key: dimensionValue,
        value: dimensionValue,
      })),
      value,
      errors: [],
    } as unknown as GroupedMetricValue;
  },

  createMetricDefinition: ({
    overrides,
    dimensionIds,
    metricId,
    dataType = MetricDataType.MetricDataTypeNumber,
  }: {
    overrides?: Partial<MetricDefinition>;
    dimensionIds?: string[];
    metricId?: string;
    dataType?: MetricDataType;
  } = {}): MetricDefinition => {
    const name =
      (metricId && startCase(camelCase(metricId))) ||
      `Metric ${faker.lorem.word()} ${faker.datatype.number()}`;
    const mockMetricId = metricId || snakeCase(name);
    const defaultMetricDefinition: MetricDefinition = {
      __typename: 'MetricDefinition',
      aggregationType: MetricAggregationType.MetricAggregationTypeCount,
      connotation: MetricConnotation.MetricConnotationPositive,
      dataType,
      description: faker.lorem.paragraph(),
      dimensionWrappers: (dimensionIds
        ? dimensionIds
        : Object.keys(MockMetricDimensionNames)
      ).map<DimensionWrapper>((d) => {
        return {
          dimension: MetricFactory.createMetricDimension({ dimensionId: d as MockDimensionId }),
        };
      }),
      dimensions: (dimensionIds ? dimensionIds : Object.keys(MockMetricDimensionNames)).map((d) => {
        return MetricFactory.createMetricDimension({ dimensionId: d as MockDimensionId });
      }),
      domain: faker.lorem.word(),
      metricId: mockMetricId,
      name,
    };
    return merge(defaultMetricDefinition, overrides);
  },

  createMetricDimension: ({
    dimensionId,
  }: { dimensionId?: MockDimensionId | string } = {}): Dimension => {
    if (dimensionId === 'date') {
      const allValidTimeGranularities = Object.values(TimeDimensionGranularity).filter(
        (g) => g !== TimeDimensionGranularity.TimeDimensionGranularityUnknown
      );
      return {
        __typename: 'TimeDimension',
        description: 'Date description',
        dimensionId: 'date',
        name: MockMetricDimensionNames.date,
        granularities: allValidTimeGranularities,
        earliestAvailableDate: castStringAsSerializedDateTime(''),
      };
    }

    const name =
      (dimensionId && MockMetricDimensionNames[dimensionId]) ||
      (dimensionId && startCase(camelCase(dimensionId))) ||
      faker.lorem.words();
    const defaultDimensionId = snakeCase(name);
    const defaultPossibleValues = [
      `${dimensionId} 0`,
      `${dimensionId} 1`,
      `${dimensionId} 2`,
      `${dimensionId} 3`,
    ];
    const stringDimension: StringDimension = {
      __typename: 'StringDimension',
      description: `${name} description`,
      dimensionId: dimensionId || defaultDimensionId,
      name,
      possibleValues:
        (dimensionId && MockMetricStringDimensionPossibleValues[dimensionId]) ||
        defaultPossibleValues,
    };
    return stringDimension;
  },
  createMetricListForReport: ({
    numMetrics,
    groupingDimensions,
    filters,
  }: CreateMetricListForReportArgs): Metric[] => {
    return MetricFactory.createMetricList({
      numMetrics,
      dimensionGroupings: groupingDimensions.map((groupingDimensionWrapper) => ({
        granularity: null,
        ...groupingDimensionWrapper.grouping,
      })),
      filters,
    });
  },
};
