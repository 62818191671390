import React from 'react';

import { Table, Text } from '@attentive/picnic';

import { ColumnHeader, MetricRawRow } from '../../hooks';

import { StyledBodyCell } from './StyledBodyCell';
import { getPercentChangeColor } from './utils';

interface ChangeRowProps {
  row: MetricRawRow;
  headers: ColumnHeader[];
}
export const ChangeRow = ({ row, headers }: ChangeRowProps) => {
  return (
    <Table.BodyRow>
      {row.map((cell, columnIndex) => {
        const isLeftAligned = cell.isGroupingDimensionValue;
        const color =
          typeof cell.rawValue === 'number'
            ? getPercentChangeColor(headers[columnIndex].connotation, cell.rawValue as number)
            : 'default';
        const sticky = columnIndex === 0;
        return (
          <StyledBodyCell
            // eslint-disable-next-line react/no-array-index-key
            key={`cell: changeRow, ${columnIndex} ${cell.value}`}
            align={isLeftAligned ? 'left' : 'right'}
            type="emphasized"
            alignment={isLeftAligned ? 'left' : 'right'}
            stickyLeft={sticky}
          >
            <Text color={color}>{cell.value}</Text>
          </StyledBodyCell>
        );
      })}
    </Table.BodyRow>
  );
};
