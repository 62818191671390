/**
 * @generated SignedSource<<000d3a81dc0f245db61d899a9c9f32e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReportExportButton_ReportingPlatform_metricsTable$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useGenerateRawMetricsRows_ReportingPlatform_metricsTable" | "useGenerateReportMetricsColumnHeaders_ReportingPlatform_metricsTable">;
  readonly " $fragmentType": "ReportExportButton_ReportingPlatform_metricsTable";
};
export type ReportExportButton_ReportingPlatform_metricsTable$key = {
  readonly " $data"?: ReportExportButton_ReportingPlatform_metricsTable$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReportExportButton_ReportingPlatform_metricsTable">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReportExportButton_ReportingPlatform_metricsTable",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useGenerateReportMetricsColumnHeaders_ReportingPlatform_metricsTable"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useGenerateRawMetricsRows_ReportingPlatform_metricsTable"
    }
  ],
  "type": "MetricsTableConnection",
  "abstractKey": null
};

(node as any).hash = "514f5524ca4472006b81ee14ff8ca228";

export default node;
