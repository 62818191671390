import { faker } from '@faker-js/faker';
import merge from 'lodash/merge';

import { AuthUser } from '@attentive/data';

import { CompanyFactory } from './CompanyFactory';

export const UserFactory = {
  create: (userArg?: Partial<AuthUser>): AuthUser => {
    return merge(
      {
        id: `account-${faker.datatype.number()}`,
        internalId: faker.datatype.number(),
        company: userArg?.company || CompanyFactory.create(),
        email: faker.internet.email(),
        firstName: faker.name.fullName(),
        lastName: faker.name.lastName(),
        phone: faker.phone.number(),
      },
      userArg
    );
  },
};
