import React from 'react';

import { Box } from '@attentive/picnic';

import NoDataChartSvg from '../../../assets/Chart-No-Data.svg';

import { DataUnavailableNotification } from './DataUnavailableNotification';

type ChartDataUnavailableNotificationProps = {
  css?: React.CSSProperties;
  children?: React.ReactNode;
};

export const ChartDataUnavailableNotification: React.FC<ChartDataUnavailableNotificationProps> = ({
  css,
  children,
}) => {
  return (
    <>
      <Box
        as="img"
        src={NoDataChartSvg}
        alt="report queued success"
        css={{ width: '100%', height: '100%' }}
      />
      <DataUnavailableNotification
        css={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          ...css,
        }}
      >
        {children}
      </DataUnavailableNotification>
    </>
  );
};
