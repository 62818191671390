import React from 'react';

import { Box, Text } from '@attentive/picnic';

import { BarChartLegendItem } from '../BarChartLegendItem/BarChartLegendItem';

interface BarChartTooltipProps {
  value: string;
  color: string;
  label: string;
}

export const BarChartTooltip = ({ value, color, label }: BarChartTooltipProps) => {
  return (
    <Box
      css={{
        display: 'flex',
        p: '$space2',
        borderRadius: '$radius2',
        border: '$borderWidths$borderWidth1 solid $borderDefault',
        backgroundColor: '$bgDefault',
        boxShadow: '$shadow1',
        alignItems: 'center',
      }}
    >
      <BarChartLegendItem color={color} label={label} size="small" />
      <Text variant="micro" css={{ fontWeight: '$bold', ml: '$space6' }}>
        {value}
      </Text>
    </Box>
  );
};
