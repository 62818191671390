import _useReportMetricsData_AnalyticsUI_metricsTable from "./__generated__/useReportMetricsData_AnalyticsUI_metricsTable.graphql";
import _useReportMetricsData_AnalyticsUI_metrics from "./__generated__/useReportMetricsData_AnalyticsUI_metrics.graphql";
import { useMemo } from 'react';
import { useFragment } from 'react-relay';
import { useCompanyFeatureFlag } from '@attentive/acore-utils';
import { graphql } from '@attentive/data';
import { transformMetricsTable } from '../utils';
import MetricsFragment from './__generated__/useReportMetricsData_AnalyticsUI_metrics.graphql';
import MetricsTableFragment from './__generated__/useReportMetricsData_AnalyticsUI_metricsTable.graphql';
_useReportMetricsData_AnalyticsUI_metrics;
_useReportMetricsData_AnalyticsUI_metricsTable;
export const useReportMetricsData = _ref => {
  let {
    metricsRef,
    metricsTableRef
  } = _ref;
  const isReportingMetricsTableMigrationEnabled = useCompanyFeatureFlag('ENABLE_REPORTING_METRICS_TABLE_MIGRATION');
  const metricsData = useFragment(MetricsFragment, metricsRef);
  const metricsTableData = useFragment(MetricsTableFragment, metricsTableRef);
  const defaultMetricsData = metricsData;
  const transformedMetricsTableData = useMemo(() => transformMetricsTable(metricsTableData), [metricsTableData]);
  return isReportingMetricsTableMigrationEnabled ? transformedMetricsTableData : defaultMetricsData;
};