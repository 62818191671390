import round from 'lodash/round';

import { Locale } from '@attentive/locale-utils';

import { ColumnHeader, MetricRawRow } from '../../hooks';
import { Metric, MetricConnotation, ReportGrouping } from '../../services';
export const SORT_ICON_WIDTH = 56;

export const formatPercentChange = (locale: Locale, percentChange?: number) => {
  if (percentChange !== undefined) {
    return `${Math.abs(round(percentChange, 1)).toLocaleString(locale)}%`;
  }
  return '';
};

export const getPercentChangeSymbol = (percentChange?: number) => {
  if (!percentChange) {
    return '';
  }

  return percentChange > 0 ? '+' : '-';
};

export const getPercentChangeColor = (connotation: MetricConnotation, percentChange: number) => {
  if (percentChange === 0 || connotation === MetricConnotation.MetricConnotationNeutral) {
    return 'subdued';
  }

  const modifier = connotation === MetricConnotation.MetricConnotationNegative ? -1 : 1;
  return modifier * percentChange > 0 ? 'success' : 'warning';
};

// TODO: Replace with useGenerateReportMetricsColumnHeaders
export const generateReportMetricsColumnHeaders = ({
  metrics,
  groupingDimensions,
}: {
  groupingDimensions: ReportGrouping[];
  metrics: Metric[];
}): string[] => {
  const columnHeaders: string[] = [];

  // the first columns will be grouping dimensions
  // date should always show first, if it exists
  const sortedReportDimensionIds =
    [...(groupingDimensions || [])].sort(({ dimensionId }) => (dimensionId === 'date' ? -1 : 1)) ||
    [];

  // the rest of the columns are metrics
  // reports only have the dimension ID
  // grab the dimension name from the metrics by searching through the metrics
  // this isn't ideal: in the future, we will be able to grab the name of the report from the Report node
  const capitalizedDimensions = sortedReportDimensionIds?.map(
    ({ dimensionId: reportDimensionId }) => {
      let dimensionName = '';
      metrics.forEach(({ definition: { dimensionWrappers } }) => {
        dimensionWrappers.forEach(({ dimension }) => {
          if (dimension.dimensionId === reportDimensionId) {
            dimensionName = dimension.name;
          }
        });
      });
      return dimensionName;
    }
  );
  columnHeaders.push(...capitalizedDimensions);
  columnHeaders.push(...metrics.map((m) => m.definition.name));

  return columnHeaders.filter((header) => header);
};

/**
 * We decided on these values while pairing with Product/design
 * to find cell sizes/cutoff character lengths that look good
 * in the Report Table
 */
const SMALL_METRIC_CELL_WIDTH = 192;
const LARGE_METRIC_CELL_WIDTH = 248;
const SMALL_GROUPING_CELL_WIDTH = 152;
const MEDIUM_GROUPING_CELL_WIDTH = 200;
const LARGE_GROUPING_CELL_WIDTH = 304;
const XLARGE_GROUPING_CELL_WIDTH = 456;
const SMALL_CHAR_LENGTH = 8;
const MEDIUM_CHAR_LENGTH = 16;
const LARGE_CHAR_LENGTH = 24;
const XLARGE_CHAR_LENGTH = 36;

export function generateColumnSizes(
  headers: ColumnHeader[],
  sampleRows: MetricRawRow[],
  firstGroupingColumnBuffer?: number
) {
  const firstColumn = headers[0];
  const hasGroupingColumns = firstColumn?.isGroupingDimensionValue;
  const sizes = headers.map((column, columnIndex) => {
    const maxCellValueLength = sampleRows.reduce((longestColumnValue, currentRow) => {
      // verify that the columnIndex being accessed actually exists in the metrics returned from Corpus
      // this happens when a user adds a grouping locally and the backend has not returned the data yet
      if (columnIndex >= currentRow.length) {
        return longestColumnValue;
      }
      const currentCellValueLength = `${currentRow[columnIndex].value}`?.length;
      const isFirstGroupingColumn = columnIndex === 0 && column.isGroupingDimensionValue;

      return Math.max(
        longestColumnValue,
        currentCellValueLength,
        isFirstGroupingColumn && firstGroupingColumnBuffer ? firstGroupingColumnBuffer : 0
      );
    }, 0);

    // if there are no grouping columns (will hapen when totals row is not enabled),
    // make the metrics proportional
    if (!hasGroupingColumns) {
      const minWidth =
        maxCellValueLength > SMALL_CHAR_LENGTH ? LARGE_METRIC_CELL_WIDTH : SMALL_METRIC_CELL_WIDTH;
      return `minmax(${minWidth}px, auto)`;
    }
    // if the column is a grouping, give it enough space to start off with room to grow
    if (column.isGroupingDimensionValue) {
      if (maxCellValueLength > XLARGE_CHAR_LENGTH) {
        return `minmax(${XLARGE_GROUPING_CELL_WIDTH}px, auto)`;
      } else if (maxCellValueLength > LARGE_CHAR_LENGTH) {
        return `minmax(${LARGE_GROUPING_CELL_WIDTH}px, auto)`;
      } else if (maxCellValueLength > MEDIUM_CHAR_LENGTH) {
        return `minmax(${MEDIUM_GROUPING_CELL_WIDTH}px, auto)`;
      }
      return `minmax(${SMALL_GROUPING_CELL_WIDTH}px, auto)`;
    }

    // otherwise the column is a metric, so give it a fixed size to maintain density
    return maxCellValueLength > SMALL_CHAR_LENGTH
      ? `${LARGE_METRIC_CELL_WIDTH}px`
      : `${SMALL_METRIC_CELL_WIDTH}px`;
  });
  return sizes;
}
