/**
 * @generated SignedSource<<6429275855e776158200022b53841b69>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useHasAIJourneysPerformanceReportAccess_ReportingPlatform_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useHasAIJourneysAccessFragment_query">;
  readonly " $fragmentType": "useHasAIJourneysPerformanceReportAccess_ReportingPlatform_query";
};
export type useHasAIJourneysPerformanceReportAccess_ReportingPlatform_query$key = {
  readonly " $data"?: useHasAIJourneysPerformanceReportAccess_ReportingPlatform_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"useHasAIJourneysPerformanceReportAccess_ReportingPlatform_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "companyId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useHasAIJourneysPerformanceReportAccess_ReportingPlatform_query",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "companyId",
          "variableName": "companyId"
        }
      ],
      "kind": "FragmentSpread",
      "name": "useHasAIJourneysAccessFragment_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "966b1f3759c4bff68578e6630af80d3f";

export default node;
