import React, { ComponentProps } from 'react';

import { Box, Text, styled } from '@attentive/picnic';

export const BarChartCircle = styled(Box, {
  borderRadius: '$radius2',
  flexShrink: 0,
  mr: '$space2',
  variants: {
    size: {
      small: {
        height: '$size3',
        width: '$size3',
      },
      medium: {
        height: '$size4',
        width: '$size4',
      },
    },
    magic: {
      true: {
        backgroundImage: '$bgGradientMagic',
        backgroundColor: '$bgGradientMagicFallback',
      },
    },
    verticalCircleGradient: {
      true: {
        transform: 'rotate(-90deg)',
      },
    },
  },
  defaultVariants: {
    size: 'medium',
  },
});

interface BarChartLegendItemProps extends ComponentProps<typeof BarChartCircle> {
  color: string;
  label: string;
}

export const BarChartLegendItem = ({ color, label, size, ...props }: BarChartLegendItemProps) => {
  const magic = color === 'magic';
  const variant = size === 'small' ? 'micro' : 'caption';

  return (
    <Box css={{ display: 'flex', alignItems: 'center' }}>
      <BarChartCircle size={size} magic={magic} css={{ backgroundColor: color }} {...props} />
      <Text variant={variant}>{label}</Text>
    </Box>
  );
};
