import _useAiProSendsData_ReportingPlatform_company from "./__generated__/useAiProSendsData_ReportingPlatform_company.graphql";
import { useFragment } from 'react-relay';
import { graphql } from '@attentive/data';
import { extractSingleMetricAggregateValue } from '../utils';
import useAiProSendsData_ReportingPlatform_company from './__generated__/useAiProSendsData_ReportingPlatform_company.graphql';
_useAiProSendsData_ReportingPlatform_company;
export const useAiProSendsData = companyRef => {
  const company = useFragment(useAiProSendsData_ReportingPlatform_company, companyRef);
  const {
    audiencesAiIncrementalSendsTextMetrics,
    audiencesAiIncrementalSendsEmailMetrics,
    audiencesAiExpansionBaseSendsTextMetrics,
    audiencesAiExpansionBaseSendsEmailMetrics,
    identityAiBaseSendsTextMetrics,
    identityAiBaseSendsEmailMetrics,
    identityAiIncrementalSendsTextMetrics,
    identityAiIncrementalSendsEmailMetrics,
    audiencesAiSuppressedSendsTextMetrics,
    audiencesAiSuppressedSendsEmailMetrics,
    audiencesAiExclusionBaseSendsTextMetrics,
    audiencesAiExclusionBaseSendsEmailMetrics
  } = company;
  const audiencesAiIncrementalSendsText = extractSingleMetricAggregateValue(audiencesAiIncrementalSendsTextMetrics);
  const audiencesAiIncrementalSendsEmail = extractSingleMetricAggregateValue(audiencesAiIncrementalSendsEmailMetrics);
  const audiencesAiExpansionBaseSendsText = extractSingleMetricAggregateValue(audiencesAiExpansionBaseSendsTextMetrics);
  const audiencesAiExpansionBaseSendsEmail = extractSingleMetricAggregateValue(audiencesAiExpansionBaseSendsEmailMetrics);
  const identityAiBaseSendsText = extractSingleMetricAggregateValue(identityAiBaseSendsTextMetrics);
  const identityAiBaseSendsEmail = extractSingleMetricAggregateValue(identityAiBaseSendsEmailMetrics);
  const identityAiIncrementalSendsText = extractSingleMetricAggregateValue(identityAiIncrementalSendsTextMetrics);
  const identityAiIncrementalSendsEmail = extractSingleMetricAggregateValue(identityAiIncrementalSendsEmailMetrics);
  const audiencesAiSuppressedSendsText = extractSingleMetricAggregateValue(audiencesAiSuppressedSendsTextMetrics);
  const audiencesAiSuppressedSendsEmail = extractSingleMetricAggregateValue(audiencesAiSuppressedSendsEmailMetrics);
  const audiencesAiExclusionBaseSendsText = extractSingleMetricAggregateValue(audiencesAiExclusionBaseSendsTextMetrics);
  const audiencesAiExclusionBaseSendsEmail = extractSingleMetricAggregateValue(audiencesAiExclusionBaseSendsEmailMetrics);
  return {
    audiencesAiIncrementalSendsText,
    audiencesAiIncrementalSendsEmail,
    audiencesAiExpansionBaseSendsText,
    audiencesAiExpansionBaseSendsEmail,
    identityAiBaseSendsText,
    identityAiBaseSendsEmail,
    identityAiIncrementalSendsText,
    identityAiIncrementalSendsEmail,
    audiencesAiSuppressedSendsText,
    audiencesAiSuppressedSendsEmail,
    audiencesAiExclusionBaseSendsText,
    audiencesAiExclusionBaseSendsEmail
  };
};