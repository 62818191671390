import { startOfMonth, isAfter, addMonths, addDays, isSameDay, parseISO } from 'date-fns';
import { format } from 'date-fns-tz';
import round from 'lodash/round';

import { CurrencyConfig, Locale } from '@attentive/locale-utils';
import { DATE_FNS_ISO_8601_DATE_FORMAT } from '@attentive/picnic';

import {
  Metric,
  MetricConnotation,
  MetricDataType,
  LegacyMetric,
  LegacyMetricConnotation,
  LegacyMetricId,
  LegacyMetricType,
  LegacyDateDimension,
  LegacySourceDimension,
  TimeDimensionGranularity,
} from '../services';

import { formatCurrency, formatPercent, MINIMUM_ROUNDED_VALUE } from './formatUtils';

const convertMetricDataTypeToLegacyMetricType = (
  metricDataType: MetricDataType
): LegacyMetricType => {
  switch (metricDataType) {
    case MetricDataType.MetricDataTypeCurrency: {
      return 'currency';
    }
    case MetricDataType.MetricDataTypeNumber: {
      return 'number';
    }
    case MetricDataType.MetricDataTypePercent: {
      return 'percent';
    }
    case MetricDataType.MetricDataTypeUnknown: {
      return 'number';
    }
    default: {
      return 'number';
    }
  }
};

const convertMetricConnotationToLegacyMetricConnotation = (
  metricConnotation: MetricConnotation
): LegacyMetricConnotation => {
  switch (metricConnotation) {
    case MetricConnotation.MetricConnotationNegative: {
      return 'negative';
    }
    case MetricConnotation.MetricConnotationNeutral: {
      return 'neutral';
    }
    case MetricConnotation.MetricConnotationPositive: {
      return 'positive';
    }
    case MetricConnotation.MetricConnotationUnknown: {
      return 'positive';
    }
    default: {
      return 'positive';
    }
  }
};

type ValuesGroupedByDate = { [key: string]: { date: string; value: number } };
type GroupedSourceValues = {
  [key: string]: {
    source: string;
    total: number;
    groupByDate: LegacyDateDimension[];
  };
};

const SOURCE_VALUE_SORT_ORDER: { [key: string]: number } = {
  text: 0,
  mms: 10,
  sms: 20,
  email: 30,
  video: 40,
  'carrier fees': 50,
  other: 60,
};

const fillDatesWithZero = ({
  metricValues,
  timeGranularity,
  endDate,
  startDate,
}: {
  timeGranularity?: TimeDimensionGranularity;
  metricValues: LegacyMetric['groupByDate'];
  startDate: string;
  endDate: string;
}) => {
  const result = [...metricValues];

  const isMonthGranularity =
    timeGranularity === TimeDimensionGranularity.TimeDimensionGranularityMonthly;
  const startDateToGenerateFrom = isMonthGranularity
    ? startOfMonth(parseISO(startDate))
    : parseISO(startDate);
  const endDateToGenerateTo = isMonthGranularity
    ? startOfMonth(parseISO(endDate))
    : parseISO(endDate);

  let currentDate = startDateToGenerateFrom;
  while (!isAfter(currentDate, endDateToGenerateTo)) {
    const metricValueForDay = result.find((metricValue) => {
      return isSameDay(parseISO(metricValue.date), currentDate);
    });

    // fill in with zero if date doesn't exist
    if (metricValueForDay === undefined) {
      result.push({
        date: format(currentDate, DATE_FNS_ISO_8601_DATE_FORMAT), // double check this
        value: 0,
      });
    }

    // iterate to next date
    if (isMonthGranularity) {
      currentDate = addMonths(currentDate, 1);
    } else {
      currentDate = addDays(currentDate, 1);
    }
  }
  return result;
};

export const convertMetricToLegacySourceDimensions = ({
  dateDimensionIndex,
  metric,
  sourceDimensionIndex,
  endDate,
  startDate,
  timeGranularity,
  shouldFillEmptyDatesWithZero,
}: {
  metric: Metric;
  dateDimensionIndex: number;
  sourceDimensionIndex?: number;
  timeGranularity?: TimeDimensionGranularity;
  startDate?: string;
  endDate?: string;
  shouldFillEmptyDatesWithZero?: boolean;
}): LegacySourceDimension[] | undefined => {
  if (!sourceDimensionIndex) {
    return undefined;
  }

  const valuesGroupedBySourceDimension = metric.groupedValues.reduce<GroupedSourceValues>(
    (currentMap, groupedValue) => {
      if (groupedValue.groupingDimensions.length > sourceDimensionIndex) {
        const source = groupedValue.groupingDimensions[sourceDimensionIndex].value;
        if (!currentMap[source]) {
          currentMap[source] = {
            source,
            total: 0,
            groupByDate: [],
          };
        }
        const valueToAdd = groupedValue.value || 0;
        currentMap[source].total += valueToAdd;
        currentMap[source].groupByDate.push({
          date: groupedValue.groupingDimensions[dateDimensionIndex].value,
          value: groupedValue.value || 0,
        });
      }
      return currentMap;
    },
    {} as GroupedSourceValues
  );
  const groupBySource = Object.values(valuesGroupedBySourceDimension);

  const sources: LegacySourceDimension[] = groupBySource.map(({ source, total, groupByDate }) => {
    return {
      name: source,
      // round to 1 decimal place. Number is mulitplied by 100 -- ready for % sign.
      percent: Math.round((total / (metric.aggregateValue || 1)) * 1000) / 10,
      total,
      groupByDate,
    };
  });

  if (shouldFillEmptyDatesWithZero && startDate && endDate) {
    sources.forEach((source) => {
      source.groupByDate = fillDatesWithZero({
        metricValues: source.groupByDate || [],
        startDate,
        endDate,
        timeGranularity,
      });
    });
  }

  sources.sort((a, b) => {
    const aValue = SOURCE_VALUE_SORT_ORDER[a.name.trim().toLowerCase()] || 0;
    const bValue = SOURCE_VALUE_SORT_ORDER[b.name.trim().toLowerCase()] || 0;
    return aValue - bValue;
  });

  return sources;
};

interface ConvertMetricToLegacyMetricArgs {
  metric: Metric;
  dateDimensionIndex: number;
  sourceDimensionIndex?: number;
}

interface FillDatesWithZeroArgs extends ConvertMetricToLegacyMetricArgs {
  shouldFillEmptyDatesWithZero?: true;
  startDate: string;
  endDate: string;
  timeGranularity: TimeDimensionGranularity;
}

interface DoNotFillDatesWithZeroArgs extends ConvertMetricToLegacyMetricArgs {
  shouldFillEmptyDatesWithZero?: false;
  startDate?: never;
  endDate?: never;
  timeGranularity?: never;
}

export const convertMetricToLegacyMetric = ({
  metric,
  dateDimensionIndex,
  sourceDimensionIndex,
  startDate,
  endDate,
  shouldFillEmptyDatesWithZero = false,
  timeGranularity,
}: FillDatesWithZeroArgs | DoNotFillDatesWithZeroArgs): LegacyMetric => {
  let metricValuesGroupByDate: LegacyMetric['groupByDate'] = [];
  if (dateDimensionIndex >= 0) {
    const valuesGroupedByDate = metric.groupedValues.reduce<ValuesGroupedByDate>(
      (currentMap, groupedValue) => {
        if (groupedValue.groupingDimensions.length <= dateDimensionIndex) {
          return currentMap;
        }

        const date = groupedValue.groupingDimensions[dateDimensionIndex].value;
        if (!currentMap[date]) {
          currentMap[date] = {
            date,
            value: 0,
          };
        }
        const valueToAdd = groupedValue.value || 0;
        currentMap[date].value += valueToAdd;
        return currentMap;
      },
      {} as ValuesGroupedByDate
    );
    metricValuesGroupByDate = Object.values(valuesGroupedByDate);
  }

  if (shouldFillEmptyDatesWithZero && startDate && endDate) {
    metricValuesGroupByDate = fillDatesWithZero({
      metricValues: metricValuesGroupByDate,
      endDate,
      startDate,
      timeGranularity,
    });
  }

  const sources = convertMetricToLegacySourceDimensions({
    metric,
    dateDimensionIndex,
    sourceDimensionIndex,
    endDate,
    startDate,
    timeGranularity,
    shouldFillEmptyDatesWithZero,
  });

  return {
    id: metric.definition.metricId as LegacyMetricId,
    name: metric.definition.name,
    description: metric.definition.description,
    type: convertMetricDataTypeToLegacyMetricType(metric.definition.dataType),
    dataType: 'decimal',
    connotation: convertMetricConnotationToLegacyMetricConnotation(metric.definition.connotation),
    total: metric.aggregateValue || 0,
    groupByDate: metricValuesGroupByDate,
    sources,
  };
};

export const isBillableSpendLegacyMetric = (id: string): boolean => {
  return (
    id === 'total_billable_spend' ||
    id === 'campaigns_billable_spend' ||
    id === 'journeys_billable_spend' ||
    id === 'total_message_billable_spend' ||
    id === 'campaign_message_billable_spend' ||
    id === 'journey_message_billable_spend' ||
    id.includes('billable_spend')
  );
};

export const formatLegacyMetricValue = ({
  metric,
  locale,
  currencyConfig: { currencyCode },
  hasDecimals,
}: {
  metric: Pick<LegacyMetric, 'id' | 'type' | 'total' | 'dataType'>;
  locale: Locale;
  currencyConfig: CurrencyConfig;
  hasDecimals?: boolean;
}): string => {
  const { type, total, dataType } = metric;

  if (type === 'currency') {
    // always display billable spend in USD since that's how we bill customers regardless of company's currency
    const isBillableSpend = isBillableSpendLegacyMetric(metric.id);
    return formatCurrency({
      locale: isBillableSpend ? Locale.enUS : locale,
      value: total,
      currencyCode: isBillableSpend ? 'USD' : currencyCode,
      hasDecimals: hasDecimals !== undefined ? hasDecimals : dataType === 'decimal',
    });
  }

  if (type === 'percent') {
    return formatPercent(total);
  }

  const roundedValue = round(parseFloat(`${total}`), 2);
  if (roundedValue === 0 && total !== 0) {
    return `< ${MINIMUM_ROUNDED_VALUE}`;
  }
  return roundedValue.toLocaleString();
};

export const computeLegacyMetricPercentChange = ({
  metric,
  comparisonMetric,
}: {
  metric: LegacyMetric;
  comparisonMetric?: LegacyMetric;
}): number | undefined => {
  if (
    !comparisonMetric ||
    !comparisonMetric.total ||
    comparisonMetric.total === 0 ||
    comparisonMetric.errors?.length
  ) {
    return undefined;
  }
  return ((metric.total - comparisonMetric.total) / comparisonMetric.total) * 100;
};
