/**
 * @generated SignedSource<<ccb41410fef9c751aea7cb4b255ca3b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useReportMetricsData_AnalyticsUI_metricsTable$data = {
  readonly " $fragmentSpreads": FragmentRefs<"transformMetricsTable_ReportingPlatform_metricsTable">;
  readonly " $fragmentType": "useReportMetricsData_AnalyticsUI_metricsTable";
};
export type useReportMetricsData_AnalyticsUI_metricsTable$key = {
  readonly " $data"?: useReportMetricsData_AnalyticsUI_metricsTable$data;
  readonly " $fragmentSpreads": FragmentRefs<"useReportMetricsData_AnalyticsUI_metricsTable">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dimensionId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useReportMetricsData_AnalyticsUI_metricsTable",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "transformMetricsTable_ReportingPlatform_metricsTable",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MetricValue",
          "kind": "LinkedField",
          "name": "aggregateValues",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MetricDefinition",
          "kind": "LinkedField",
          "name": "definitions",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "metricId",
              "storageKey": null
            },
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dataType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "connotation",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "DimensionWrapper",
              "kind": "LinkedField",
              "name": "dimensionWrappers",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "dimension",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v3/*: any*/),
                        (v1/*: any*/),
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "granularities",
                          "storageKey": null
                        }
                      ],
                      "type": "TimeDimension",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v3/*: any*/),
                        (v1/*: any*/),
                        (v2/*: any*/)
                      ],
                      "type": "StringDimension",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GroupedMetricsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "GroupedMetrics",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DimensionValue",
                  "kind": "LinkedField",
                  "name": "groupingDimensions",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "key",
                      "storageKey": null
                    },
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "MetricValue",
                  "kind": "LinkedField",
                  "name": "values",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "MetricDataError",
                      "kind": "LinkedField",
                      "name": "errors",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "message",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "MetricsTableConnection",
  "abstractKey": null
};
})();

(node as any).hash = "f5a91909c1b3b1cf0ef833eb1ec6ba41";

export default node;
