import { CountryCode, LocaleConfig, getLocaleConfig } from '@attentive/locale-utils';

import { MetricValue } from '../hooks';

import { DEFAULT_LOCALE_CONFIG, isBillableReport } from './billableReportUtils';
const isCountryCode = (value: MetricValue): value is CountryCode => {
  if (typeof value === 'string') {
    return Object.values(CountryCode).includes(value as CountryCode);
  }
  return false;
};

export const getReportLocaleConfig = ({
  reportClassName,
  regionValue,
  isReportingRegionCurrencyOverrideEnabled,
  companyLocaleConfig,
}: {
  reportClassName: string;
  regionValue?: MetricValue;
  isReportingRegionCurrencyOverrideEnabled: boolean;
  companyLocaleConfig: LocaleConfig;
}) => {
  if (isBillableReport(reportClassName)) {
    return {
      locale: DEFAULT_LOCALE_CONFIG.locale,
      currencyCode: DEFAULT_LOCALE_CONFIG.currencyConfig.currencyCode,
    };
  }
  if (!isReportingRegionCurrencyOverrideEnabled && isCountryCode(regionValue)) {
    const {
      locale: regionLocale,
      currencyConfig: { currencyCode: regionCurrencyCode },
    } = getLocaleConfig(regionValue);
    return { locale: regionLocale, currencyCode: regionCurrencyCode };
  }
  const {
    locale: companyLocale,
    currencyConfig: { currencyCode: companyCurrencyCode },
  } = companyLocaleConfig;
  return { locale: companyLocale, currencyCode: companyCurrencyCode };
};
