import round from 'lodash/round';

import { BarDatum } from '@attentive/charts';
import { CurrencyCode, Locale } from '@attentive/locale-utils';

import { BarDatumType, SingleStackedHorizontalBarDatum } from './types';

export function transformSeries(series: SingleStackedHorizontalBarDatum[]) {
  const serie: BarDatum = {};
  const values: number[] = [];
  const colors: string[] = [];
  const labels: string[] = [];
  let dataType: BarDatumType = 'number';
  let currencyCode: CurrencyCode = CurrencyCode.US;
  let locale: Locale = Locale.enUS;

  series.forEach((s) => {
    serie[s.label] = s.value;
    values.push(s.value);
    colors.push(s.color);
    labels.push(s.label);
    dataType = s.dataType;
    currencyCode = s.currencyCode;
    locale = s.locale;
  });

  return { data: [serie], values, colors, labels, dataType, currencyCode, locale };
}

export function calculateTotal(series: BarDatum[]) {
  let total = 0;

  series.forEach((serie) => {
    Object.values(serie).forEach((value) => {
      if (typeof value === 'number') {
        total += value;
      }
    });
  });

  return total;
}

export function calculateMaxValue(value: number) {
  if (value < 5) return Math.ceil(value / 1) * 1;
  if (value < 50) return Math.ceil(value / 5) * 5;
  if (value < 500) return Math.ceil(value / 50) * 50;
  if (value < 5000) return Math.ceil(value / 500) * 500;
  if (value < 50_000) return Math.ceil(value / 5000) * 5000;
  if (value < 500_000) return Math.ceil(value / 50_000) * 50_000;
  if (value < 5_000_000) return Math.ceil(value / 500_000) * 500_000;
  if (value < 50_000_000) return Math.ceil(value / 5_000_000) * 5_000_000;
  if (value < 500_000_000) return Math.ceil(value / 50_000_000) * 50_000_000;
  if (value < 1_000_000_000) return Math.ceil(value / 500_000_000) * 500_000_000;
  return Math.ceil(value / 1_000_000_000) * 1_000_000_000;
}

export function calculateTickValues(value: number) {
  const tickInterval = value / 4;
  return Array.from({ length: 5 }, (_, i) => i * tickInterval);
}

export function buildValueFormatter(
  dataType: BarDatumType,
  currencyCode: CurrencyCode,
  locale: Locale
) {
  if (dataType === 'number') return (value: number) => value.toLocaleString();

  return (value: number) => {
    return value.toLocaleString(locale, {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };
}

export function buildTickFormatter(
  dataType: BarDatumType,
  currencyCode: CurrencyCode,
  locale: Locale
) {
  return (value: number) => {
    const currencyFormatter = buildValueFormatter(dataType, currencyCode, locale);
    const currencySymbol = currencyFormatter(0).replace(/\d/g, '').trim();
    const prefix = dataType === 'currency' ? currencySymbol : '';
    const shortenValue = (val: number, divisor: number, suffix: string) =>
      `${prefix}${round(val / divisor, 2)}${suffix}`;

    if (value >= 1_000_000_000) return shortenValue(value, 1_000_000_000, 'b');
    if (value >= 1_000_000) return shortenValue(value, 1_000_000, 'm');
    if (value >= 1000) return shortenValue(value, 1000, 'k');

    return `${prefix}${value}`;
  };
}
