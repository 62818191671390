import React, { VFC } from 'react';

import { Icon } from '@attentive/picnic';

import { MetricDash } from '../MetricDash/MetricDash';

interface PercentChangeIconProps {
  percentChange?: number;
  errorMessage?: string;
}

export const PercentChangeIcon: VFC<PercentChangeIconProps> = ({ percentChange, errorMessage }) => {
  if (percentChange === 0) {
    return null;
  }
  return errorMessage !== undefined || percentChange === undefined ? (
    <MetricDash
      size="large"
      description="Unable to calculate percent change"
      metricErrorMessage={errorMessage}
    />
  ) : (
    <Icon
      size="extraSmall"
      css={{ mt: 1 }}
      name={percentChange > 0 ? 'PlusSign' : 'MinusSign'}
      mode="presentational"
      description={
        percentChange > 0
          ? 'Plus sign indicating a positive percent change'
          : 'Minus sign indicating a negative percent change'
      }
    />
  );
};
