// TODO: Replace with metricTableFragmentUtils

import { LibError } from '@attentive/acore-utils';
import { CurrencyCode, Locale } from '@attentive/locale-utils';

import { Dimension, Metric, MetricDataType } from '../services';

import { logError } from './logger';
import {
  formatMetricDimensionValue,
  formatMetricValue,
  METRIC_MISSING_DATA_SYMBOL,
} from './metricUtils';

export type MetricValue = string | number | undefined | null;
export type MetricCellDataType = MetricDataType | Dimension['__typename'];
export type MetricRawDataCell = {
  value: MetricValue;
  dataType: MetricCellDataType;
  isGroupingDimensionValue: boolean;
};
export type MetricRawRow = MetricRawDataCell[];
export type DataGroupedByDimension = {
  [key: string]: {
    groupedDimensionValues: MetricRawDataCell[];
    metricValuesMap: {
      [key: string]: MetricRawDataCell;
    };
  };
};

const groupDataByDimensionValues = ({
  metrics,
  groupingDimensionDataTypes,
}: {
  metrics: Metric[];
  groupingDimensionDataTypes: Array<Dimension['__typename']>;
}): DataGroupedByDimension => {
  // initialize all metric values to "-"
  const initialMetricValuesMap = metrics.reduce<{ [key: string]: MetricRawDataCell }>(
    (result, metric) => {
      result[metric.definition.metricId] = {
        value: METRIC_MISSING_DATA_SYMBOL,
        dataType: metric.definition.dataType,
        isGroupingDimensionValue: false,
      };
      return result;
    },
    {}
  );

  return metrics.reduce<{
    [key: string]: {
      groupedDimensionValues: MetricRawDataCell[];
      metricValuesMap: {
        [key: string]: MetricRawDataCell;
      };
    };
  }>((result, currentMetric) => {
    currentMetric.groupedValues.forEach((groupedValue) => {
      const stringifiedDimensionsArray = JSON.stringify(
        groupedValue.groupingDimensions.map((d) => d.key)
      );

      // initialize if the grouping doesn't exist yet
      if (!Object.keys(result).includes(stringifiedDimensionsArray)) {
        // create group dimensions key and values
        const groupedDimensionValues = groupedValue.groupingDimensions.map((dimension, index) => {
          const dataType = groupingDimensionDataTypes[index] || 'StringDimension';
          return {
            value: dimension.value,
            dataType,
            isGroupingDimensionValue: true,
          };
        });

        // set up key and inital values
        result[stringifiedDimensionsArray] = {
          groupedDimensionValues,
          metricValuesMap: { ...initialMetricValuesMap },
        };
      }

      // add metric to the group
      if (groupedValue.value !== null) {
        result[stringifiedDimensionsArray].metricValuesMap[currentMetric.definition.metricId] = {
          // only need to update the value, everything else is set
          ...result[stringifiedDimensionsArray].metricValuesMap[currentMetric.definition.metricId],
          value: groupedValue.value,
        };
      }
    });
    return result;
  }, {});
};

export const generateEmptyRawMetricsRow = ({
  metrics,
  groupingDimensionDataTypes,
}: {
  metrics: Metric[];
  groupingDimensionDataTypes: Array<Dimension['__typename']>;
}): MetricRawRow => {
  // if there are no rows that means there is no data OR there are no groupings
  // in either case, display METRIC_MISSING_DATA_SYMBOL for grouping values and the aggregate value for metrics
  // first, add dashes for each grouping dimension values
  const row: MetricRawDataCell[] = [];
  groupingDimensionDataTypes.forEach((groupingDataType) => {
    const cell: MetricRawDataCell = {
      value: METRIC_MISSING_DATA_SYMBOL,
      dataType: groupingDataType || 'StringDimension',
      isGroupingDimensionValue: true,
    };
    row.push(cell);
  });
  // add aggregate metric values
  metrics.forEach((metric) => {
    const cell: MetricRawDataCell = {
      value:
        typeof metric.aggregateValue === 'number'
          ? metric.aggregateValue
          : METRIC_MISSING_DATA_SYMBOL,
      dataType: metric.definition.dataType,
      isGroupingDimensionValue: false,
    };
    row.push(cell);
  });

  return row;
};

export const generateRawMetricsRows = ({
  metrics,
  groupingDimensionDataTypes,
}: {
  metrics: Metric[];
  groupingDimensionDataTypes: Array<Dimension['__typename']>;
}): MetricRawRow[] => {
  // group all needed data by the dimension values
  const dataGroupedByDimensionValues = groupDataByDimensionValues({
    metrics,
    groupingDimensionDataTypes,
  });

  // construct the rows:
  // date (if applicable), dimensions (if applicable), metricA, metricB, ...
  const rows: MetricRawRow[] = [];
  Object.keys(dataGroupedByDimensionValues).forEach((key) => {
    const data = dataGroupedByDimensionValues[key];

    // create the row
    const row: MetricRawDataCell[] = [];
    // put all dimension values in
    row.push(...data.groupedDimensionValues);
    // put date up front
    row.sort((r) => (r.dataType === 'TimeDimension' ? -1 : 1));
    // add metric data
    const metricData = Object.values(data.metricValuesMap);
    row.push(...metricData);

    rows.push(row);
  });

  // if there are no rows that means there is no data OR there are no groupings
  // in either case, display METRIC_MISSING_DATA_SYMBOL for grouping values and the aggregate value for metrics
  if (rows.length === 0) {
    const emptyDataRow = generateEmptyRawMetricsRow({ groupingDimensionDataTypes, metrics });
    // use the row iff any groupings or metrics were added, otherwise it would be an empty array
    if (emptyDataRow.length > 0) {
      rows.push(emptyDataRow);
    }
  }
  return rows;
};

export const formatRawMetricData = ({
  rawData: { dataType, value },
  locale,
  currencyCode,
  enableRounding = true,
}: {
  rawData: MetricRawDataCell;
  locale: Locale;
  currencyCode?: CurrencyCode;
  enableRounding?: boolean;
}): string | number => {
  const valueString = `${value}`;
  if (valueString === METRIC_MISSING_DATA_SYMBOL || !value) {
    return valueString;
  }

  switch (dataType) {
    case 'StringDimension':
    case 'TimeDimension': {
      return formatMetricDimensionValue({ value: valueString, dataType });
    }
    default: {
      return formatMetricValue({
        locale,
        currencyCode,
        dataType: dataType as MetricDataType,
        value,
        enableRounding,
      });
    }
  }
};

export const sortRawMetricDataRows = ({
  rows,
  sortColumn,
  isAscending,
}: {
  rows: MetricRawRow[];
  sortColumn: number;
  isAscending: boolean;
}): MetricRawRow[] => {
  return [...rows].sort((rowA, rowB) => {
    const cellA = rowA[sortColumn];
    const cellB = rowB[sortColumn];
    if (!cellA || !cellB) {
      const attentiveError = new LibError(
        `Unexpected error in sortRawMetricDataRows: MetricRawRow cell is undefined or null. sortColumn: ${sortColumn} metrics rows: ${rows}`,
        'reporting-platform'
      );
      logError(attentiveError);
    }
    const cellAValue = cellA?.value ?? METRIC_MISSING_DATA_SYMBOL;
    const cellBValue = cellB?.value ?? METRIC_MISSING_DATA_SYMBOL;
    const cellAString = `${cellAValue}`;
    const cellBString = `${cellBValue}`;

    // display missing data symbols at top for asc and bottom for desc
    if (cellAString === METRIC_MISSING_DATA_SYMBOL) {
      return isAscending ? -1 : 1;
    }
    if (cellBString === METRIC_MISSING_DATA_SYMBOL) {
      return isAscending ? 1 : -1;
    }

    const dataType =
      cellA.dataType === cellB.dataType ? cellA.dataType : MetricDataType.MetricDataTypeUnknown;

    switch (dataType) {
      case MetricDataType.MetricDataTypeCurrency:
      case MetricDataType.MetricDataTypePercent:
      case MetricDataType.MetricDataTypeNumber: {
        // sort by number
        // remove all characters except for numbers, periods, and minus signs
        const strippedCellANumber = cellAString.replace(/[^0-9.-]/g, '');
        const strippedCellBNumber = cellBString.replace(/[^0-9.-]/g, '');
        if (isAscending) {
          return parseFloat(strippedCellANumber) - parseFloat(strippedCellBNumber);
        }
        return parseFloat(strippedCellBNumber) - parseFloat(strippedCellANumber);
      }
      case 'TimeDimension': {
        const dateA = new Date(cellAValue);
        const dateB = new Date(cellBValue);
        if (isAscending) {
          return dateA.getTime() - dateB.getTime();
        }
        return dateB.getTime() - dateA.getTime();
      }
      case 'StringDimension':
      case MetricDataType.MetricDataTypeUnknown:
      default: {
        // sort by string
        if (isAscending) {
          return cellAString.localeCompare(cellBString);
        }
        return cellBString.localeCompare(cellAString);
      }
    }
  });
};

const MESSAGE_ID_HEADER = 'Message Id';
export const filterMessageIdFromTableData = (
  headers: string[],
  metricRows: MetricRawRow[]
): { filteredHeaders: string[]; filteredRawMetricRows: MetricRawRow[] } => {
  const filteredHeaders = headers.filter((header) => header !== MESSAGE_ID_HEADER);

  const messageIdIndex = headers.findIndex((header) => header === MESSAGE_ID_HEADER);
  const filteredRawMetricRows = [...metricRows];
  if (messageIdIndex >= 0) {
    filteredRawMetricRows.forEach((row) => row.splice(messageIdIndex, 1));
  }
  return { filteredHeaders, filteredRawMetricRows };
};
