/**
 * @generated SignedSource<<a3015b9e99f1b9e42ee581f9ef43e9c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useGenerateRawMetricsRows_ReportingPlatform_metrics$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"useReportMetricsData_AnalyticsUI_metrics" | "useVisibleMetrics_ReportingPlatform_metrics">;
  readonly " $fragmentType": "useGenerateRawMetricsRows_ReportingPlatform_metrics";
}>;
export type useGenerateRawMetricsRows_ReportingPlatform_metrics$key = ReadonlyArray<{
  readonly " $data"?: useGenerateRawMetricsRows_ReportingPlatform_metrics$data;
  readonly " $fragmentSpreads": FragmentRefs<"useGenerateRawMetricsRows_ReportingPlatform_metrics">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "useGenerateRawMetricsRows_ReportingPlatform_metrics",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useVisibleMetrics_ReportingPlatform_metrics"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useReportMetricsData_AnalyticsUI_metrics"
    }
  ],
  "type": "Metric",
  "abstractKey": null
};

(node as any).hash = "734e3090fed0cd2cc834d771050c6aee";

export default node;
