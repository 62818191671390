import { format } from 'date-fns';

import { CurrencyCode, Locale } from '@attentive/locale-utils';
import { kebabCase } from '@attentive/nodash';

import { METRIC_MISSING_DATA_SYMBOL, MetricRawDataCell } from '../../hooks';
import { formatRawMetricData } from '../../utils/metricTableFragmentUtils';

export const buildFileName = ({
  companyName,
  reportName,
}: {
  companyName: string;
  reportName: string;
}) => {
  return `${kebabCase(companyName)}_${kebabCase(reportName)}_${format(
    new Date(),
    'yyyy-MM-dd-hh-mm-ss'
  )}`;
};

export const escapeStringForCsv = (text: string) => {
  if (text.includes(',') || text.includes('\n') || text.includes('\r') || text.includes('"')) {
    // double up any double quotes and wrap the text in double quotes
    return `"${text.replace(/"/g, '""')}"`;
  }

  return text;
};

export const formatMetricRawDataForCsv = ({
  rawData,
  locale,
  currencyCode,
}: {
  rawData: MetricRawDataCell;
  locale: Locale;
  currencyCode?: CurrencyCode;
}): string => {
  if (`${rawData.value}` === METRIC_MISSING_DATA_SYMBOL) {
    return '';
  }

  const formattedValue = formatRawMetricData({
    rawData,
    locale,
    currencyCode,
    enableRounding: false,
  });

  // wrap in quotes, escape double quotes
  const formattedValueString = `${formattedValue}`;
  return escapeStringForCsv(formattedValueString);
};
