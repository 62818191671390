/**
 * @generated SignedSource<<cf7809de9b8c665c7f2de362ce6c2799>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MetricConnotation = "METRIC_CONNOTATION_NEGATIVE" | "METRIC_CONNOTATION_NEUTRAL" | "METRIC_CONNOTATION_POSITIVE" | "METRIC_CONNOTATION_UNKNOWN" | "%future added value";
export type MetricDataType = "METRIC_DATA_TYPE_CURRENCY" | "METRIC_DATA_TYPE_NUMBER" | "METRIC_DATA_TYPE_PERCENT" | "METRIC_DATA_TYPE_UNKNOWN" | "%future added value";
export type TimeDimensionGranularity = "TIME_DIMENSION_GRANULARITY_15MIN" | "TIME_DIMENSION_GRANULARITY_DAILY" | "TIME_DIMENSION_GRANULARITY_HOURLY" | "TIME_DIMENSION_GRANULARITY_MONTHLY" | "TIME_DIMENSION_GRANULARITY_UNKNOWN" | "TIME_DIMENSION_GRANULARITY_WEEKLY" | "TIME_DIMENSION_GRANULARITY_YEARLY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useReportMetricsData_AnalyticsUI_metrics$data = ReadonlyArray<{
  readonly aggregateValue: number | null;
  readonly definition: {
    readonly connotation: MetricConnotation;
    readonly dataType: MetricDataType;
    readonly description: string;
    readonly dimensionWrappers: ReadonlyArray<{
      readonly dimension: {
        readonly __typename: "StringDimension";
        readonly description: string | null;
        readonly dimensionId: string;
        readonly name: string;
      } | {
        readonly __typename: "TimeDimension";
        readonly description: string | null;
        readonly dimensionId: string;
        readonly granularities: ReadonlyArray<TimeDimensionGranularity>;
        readonly name: string;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      };
    }>;
    readonly metricId: string;
    readonly name: string;
  };
  readonly errors: ReadonlyArray<{
    readonly message: string;
  }>;
  readonly groupedValues: ReadonlyArray<{
    readonly groupingDimensions: ReadonlyArray<{
      readonly key: string;
      readonly value: string;
    }>;
    readonly value: number | null;
  }>;
  readonly " $fragmentType": "useReportMetricsData_AnalyticsUI_metrics";
}>;
export type useReportMetricsData_AnalyticsUI_metrics$key = ReadonlyArray<{
  readonly " $data"?: useReportMetricsData_AnalyticsUI_metrics$data;
  readonly " $fragmentSpreads": FragmentRefs<"useReportMetricsData_AnalyticsUI_metrics">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dimensionId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "useReportMetricsData_AnalyticsUI_metrics",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "aggregateValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MetricDefinition",
      "kind": "LinkedField",
      "name": "definition",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "metricId",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dataType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "connotation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DimensionWrapper",
          "kind": "LinkedField",
          "name": "dimensionWrappers",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "dimension",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v2/*: any*/),
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "granularities",
                      "storageKey": null
                    }
                  ],
                  "type": "TimeDimension",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v2/*: any*/),
                    (v1/*: any*/),
                    (v0/*: any*/)
                  ],
                  "type": "StringDimension",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GroupedMetricValue",
      "kind": "LinkedField",
      "name": "groupedValues",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DimensionValue",
          "kind": "LinkedField",
          "name": "groupingDimensions",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "key",
              "storageKey": null
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MetricDataError",
      "kind": "LinkedField",
      "name": "errors",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "message",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Metric",
  "abstractKey": null
};
})();

(node as any).hash = "e911b2b5336266ceb721760a627f5a69";

export default node;
