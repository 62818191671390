import React, { useEffect, useRef, useState } from 'react';

import {
  Box,
  Button,
  DateRangeChangeType,
  DateRangePicker as PicnicDateRangePicker,
  formatDateRange,
  PicnicCss,
} from '@attentive/picnic';

import { DateRangeSelect, DateRangeSelectChange } from '../DateRangeSelect';

import { TypableDateRange, TypableDateSchema } from './TypableDateRange';

const PU_TRIGGER_WIDTH = 265;

type PickedPopoverProps = Pick<
  React.ComponentProps<typeof PicnicDateRangePicker.Popover>,
  'side' | 'avoidCollisions'
>;

type PickedControlProps = Pick<
  React.ComponentProps<typeof PicnicDateRangePicker.Control>,
  | 'startDate'
  | 'endDate'
  | 'maxExpectedYear'
  | 'minExpectedYear'
  | 'isDayBlocked'
  | 'onChange'
  | 'minimumNights'
>;

type PickedDateRangeSelectProps = Pick<
  React.ComponentProps<typeof DateRangeSelect>,
  'dateRangeOptions' | 'companyTimezone' | 'dateFilterPeriod' | 'shouldUseInternalCalendar'
>;

type QuickDateRangePickerControlProps = PickedControlProps &
  PickedDateRangeSelectProps & {
    hideDateRangeSelect?: boolean;
    typableDateSchema?: TypableDateSchema;
    onChangeDatePeriod?: DateRangeSelectChange;
  };

const QuickDateRangePickerControl: React.FC<QuickDateRangePickerControlProps> = ({
  startDate,
  endDate,
  maxExpectedYear,
  minExpectedYear = 2017,
  isDayBlocked,
  onChange,
  onChangeDatePeriod,
  minimumNights,
  companyTimezone,
  dateRangeOptions,
  hideDateRangeSelect,
  typableDateSchema,
  dateFilterPeriod,
  shouldUseInternalCalendar,
}) => {
  return (
    <Box>
      <Box
        css={{
          mx: '$space7',
          mt: '$space7',
          mb: '$space0',
        }}
      >
        {!hideDateRangeSelect && (
          <DateRangeSelect
            startDate={startDate}
            endDate={endDate}
            onChange={onChangeDatePeriod ? onChangeDatePeriod : onChange}
            companyTimezone={companyTimezone}
            dateRangeOptions={dateRangeOptions}
            dateFilterPeriod={dateFilterPeriod}
            shouldUseInternalCalendar={shouldUseInternalCalendar}
          />
        )}
        <TypableDateRange
          startDate={startDate}
          endDate={endDate}
          onChange={onChange}
          dateSchema={typableDateSchema}
          css={{
            mt: '$space5',
          }}
        />
      </Box>
      <PicnicDateRangePicker.Control
        startDate={startDate}
        endDate={endDate}
        maxExpectedYear={maxExpectedYear}
        minExpectedYear={minExpectedYear}
        onChange={onChange}
        isDayBlocked={isDayBlocked}
        minimumNights={minimumNights || 0}
      />
    </Box>
  );
};

interface QuickDateRangePickerButtonsProps {
  handleCancelDateChange: () => void;
  handleApplyDateChange: () => void;
}

const QuickDateRangePickerButtons = ({
  handleCancelDateChange,
  handleApplyDateChange,
}: QuickDateRangePickerButtonsProps) => {
  return (
    <Box
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        mb: '$space7',
        mx: '$space7',
      }}
    >
      <Button onClick={handleCancelDateChange} variant="secondary">
        Cancel
      </Button>
      <Button onClick={handleApplyDateChange}>Apply</Button>
    </Box>
  );
};

type QuickDateRangePickerProps = PickedPopoverProps &
  QuickDateRangePickerControlProps & {
    css?: PicnicCss;
  };

const QuickDateRangePickerComponent: React.FC<QuickDateRangePickerProps> = ({
  startDate,
  endDate,
  maxExpectedYear,
  minExpectedYear,
  isDayBlocked,
  onChange,
  minimumNights,
  avoidCollisions,
  side,
  companyTimezone,
  dateRangeOptions,
  css,
  hideDateRangeSelect,
  typableDateSchema,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  // keep track of start and end dates locally because we only call onChange() if user clicks "Apply"
  const [localStartDate, setLocalStartDate] = useState<string | null>(startDate);
  const [localEndDate, setLocalEndDate] = useState<string | null>(endDate);

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    // when modal opens, reset the start and end dates based on props
    setLocalStartDate(startDate);
    setLocalEndDate(endDate);
  }, [isOpen, startDate, endDate]);

  useEffect(() => {
    setIsOpen(false);
  }, [startDate, endDate]);

  const displayStartDate = formatDateRange(startDate, endDate);

  const dialogToggled = (newIsOpen: boolean) => {
    setIsOpen(newIsOpen);
  };

  const handleLocalDateChange: DateRangeChangeType = (changedDates) => {
    setLocalStartDate(changedDates.startDate);
    setLocalEndDate(changedDates.endDate);
  };

  const handleApplyDateChange = () => {
    if (onChange) {
      onChange({
        startDate: localStartDate,
        endDate: localEndDate,
      });
    }
    setIsOpen(false);
  };

  const handleCancelDateChange = () => {
    setIsOpen(false);
  };

  return (
    <PicnicDateRangePicker.Root open={isOpen} onOpenChange={dialogToggled}>
      <PicnicDateRangePicker.Trigger>
        <PicnicDateRangePicker.Input
          size="normal"
          state="normal"
          value={displayStartDate}
          description="Open date range picker"
          inputGroupRef={inputRef}
          css={{ width: PU_TRIGGER_WIDTH, ...css }}
        />
      </PicnicDateRangePicker.Trigger>
      <PicnicDateRangePicker.Popover align="center" side={side} avoidCollisions={avoidCollisions}>
        <QuickDateRangePickerControl
          startDate={localStartDate}
          endDate={localEndDate}
          onChange={handleLocalDateChange}
          maxExpectedYear={maxExpectedYear}
          minExpectedYear={minExpectedYear}
          isDayBlocked={isDayBlocked}
          minimumNights={minimumNights || 0}
          companyTimezone={companyTimezone}
          dateRangeOptions={dateRangeOptions}
          hideDateRangeSelect={hideDateRangeSelect}
          typableDateSchema={typableDateSchema}
        />
        <QuickDateRangePickerButtons
          handleCancelDateChange={handleCancelDateChange}
          handleApplyDateChange={handleApplyDateChange}
        />
      </PicnicDateRangePicker.Popover>
    </PicnicDateRangePicker.Root>
  );
};

type ComponentType = typeof QuickDateRangePickerComponent;

interface CompositeComponent extends ComponentType {
  Root: typeof PicnicDateRangePicker.Root;
  Trigger: typeof PicnicDateRangePicker.Trigger;
  Popover: typeof PicnicDateRangePicker.Popover;
  Control: typeof QuickDateRangePickerControl;
  Buttons: typeof QuickDateRangePickerButtons;
}

const QuickDateRangePicker = QuickDateRangePickerComponent as CompositeComponent;
QuickDateRangePicker.Root = PicnicDateRangePicker.Root;
QuickDateRangePicker.Trigger = PicnicDateRangePicker.Trigger;
QuickDateRangePicker.Popover = PicnicDateRangePicker.Popover;
QuickDateRangePicker.Control = QuickDateRangePickerControl;
QuickDateRangePicker.Buttons = QuickDateRangePickerButtons;

export { QuickDateRangePicker };
