import { BarDatum } from '@attentive/charts';

import { MultiStackBarDatum } from './types';

export function transformMultiStackSeries(series: MultiStackBarDatum[], indexedBy: string) {
  const colorsSet = new Set();
  const labelsSet = new Set();
  let mergedResult: BarDatum[] = [];
  let transformed: BarDatum[] = [];

  transformed = series.map((item) => {
    const result: BarDatum = {
      [indexedBy]: item.index,
      dataType: item.dataType,
      value: item.value,
      ...(item.rangeStart ? { rangeStart: item.rangeStart } : {}),
      ...(item.rangeEnd ? { rangeEnd: item.rangeEnd } : {}),
    };

    const dynamicLabel = item.label;
    result[dynamicLabel] = item.value;

    // Add other dynamic properties (excluding known keys)
    Object.keys(item).forEach((key) => {
      if (!['value', 'color', 'label', 'index'].includes(key)) {
        result[key] = item[key];
      }
    });

    colorsSet.add(item.color);
    labelsSet.add(item.label);
    return result;
  });

  mergedResult = transformed.reduce((acc: BarDatum[], current) => {
    const foundIndex = acc.findIndex((item) => item[indexedBy] === current[indexedBy]);
    if (foundIndex !== -1) {
      acc[foundIndex] = { ...acc[foundIndex], ...current };
    } else {
      acc.push(current);
    }
    return acc;
  }, []);

  const colors = Array.from(colorsSet) as string[];
  const labels = Array.from(labelsSet) as string[];
  return { seriesdata: mergedResult, colors, labels };
}

export function findHighestSum(data: BarDatum[], labels: string[]) {
  let highestSum = 0;

  data.forEach((item) => {
    let currentSum = 0;
    labels.forEach((label) => {
      if (item[label] !== undefined) {
        currentSum += item[label] as number;
      }
    });
    if (currentSum > highestSum) {
      highestSum = currentSum;
    }
  });

  return highestSum;
}

export function formatTooltipValue(
  value: number | string,
  dataType: string,
  currencyCode: string,
  locale: string
) {
  if (dataType === 'number') return value.toLocaleString();

  return value.toLocaleString(locale, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
}
