import React, { Fragment, useEffect, useState } from 'react';

import { BarAxisProps, BarChart } from '@attentive/charts';
import { PicnicCss, Stack, keyframes } from '@attentive/picnic';

import { BarChartLegendItem } from '../BarChartLegendItem/BarChartLegendItem';
import { VerticalLegend } from '../VerticalLegend/VerticalLegend';

import { BarChartTooltip } from './BarChartTooltip';
import { SingleStackedHorizontalBarDatum } from './types';
import {
  buildTickFormatter,
  buildValueFormatter,
  calculateMaxValue,
  calculateTickValues,
  calculateTotal,
  transformSeries,
} from './utils';

const FADE_IN_ANIMATION = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

export interface SingleStackedHorizontalBarChartProps {
  series: SingleStackedHorizontalBarDatum[];
  percentChange?: number;
  legendDirection?: 'vertical' | 'horizontal';
  animate?: boolean;
  fadeIn?: boolean;
  css?: PicnicCss;
}

const HorizontalLegend = ({ colors, labels }: { colors: string[]; labels: string[] }) => {
  return (
    <Stack direction="horizontal" spacing="$space10" css={{ justifyContent: 'center' }}>
      {labels.map((_, index) => (
        <BarChartLegendItem key={labels[index]} label={labels[index]} color={colors[index]} />
      ))}
    </Stack>
  );
};

export const SingleStackedHorizontalBarChart = ({
  series,
  percentChange,
  legendDirection = 'horizontal',
  animate = true,
  fadeIn = false,
  css,
}: SingleStackedHorizontalBarChartProps) => {
  const [chartData, setChartData] = useState<SingleStackedHorizontalBarDatum[]>(series);
  const [animationKey, setAnimationKey] = useState<string>('');

  // See https://github.com/plouc/nivo/issues/732#issuecomment-858869327.
  // This prevents the default Nivo animation when the chart is first mounted.
  useEffect(() => {
    const animation = setTimeout(() => setChartData(series), 1);
    if (fadeIn) {
      setAnimationKey(`${Date.now()}`);
    }

    return () => {
      clearTimeout(animation);
    };
  }, [series, fadeIn]);

  const { data, values, colors, labels, dataType, currencyCode, locale } =
    transformSeries(chartData);

  const formatTick = buildTickFormatter(dataType, currencyCode, locale);
  const formatValue = buildValueFormatter(dataType, currencyCode, locale);
  const formattedValues = values.map((value) => formatValue(value));

  const total = calculateTotal(data);
  const maxValue = calculateMaxValue(total);
  const tickValues = calculateTickValues(maxValue);

  const axisBottom: BarAxisProps = {
    legendOffset: 40,
    legendPosition: 'middle',
    tickValues,
    format: formatTick,
  };

  return (
    <>
      <BarChart
        key={animationKey}
        css={{
          height: '100%',
          width: '100%',
          mb: '$space10',
          marginLeft: '-10px',
          ...(fadeIn && { animation: `${FADE_IN_ANIMATION} ease-in-out 0.375s` }),
          ...css,
        }}
        motionConfig={{ tension: 140, friction: 44, mass: 1 }}
        animate={animate}
        data={data}
        keys={labels}
        colors={colors}
        layout="horizontal"
        indexBy="" // no need for bar index if only one bar exists
        maxValue={maxValue}
        valueFormat={formatValue}
        axisBottom={axisBottom}
        axisLeft={null}
        gridXValues={tickValues}
        enableGridX={true}
        enableLabel={true}
        labelSkipWidth={1}
        labelTextColor={{ from: 'color', modifiers: [['darker', 10]] }}
        borderColor="#FFFFFF" // $borderInverted
        borderWidth={1} // $borderWidth1
        borderRadius={2} // $radius2
        tooltip={({ formattedValue, color, id }) => (
          <BarChartTooltip value={formattedValue} color={color} label={id.toString()} />
        )}
        theme={{
          textColor: '#1B1F23', // $textDefault
          fontFamily: 'Ginto Normal', // $body
          fontSize: 12, // $fontSize1
          axis: { ticks: { text: { fill: '#656567' } } }, // $textSubdued
          labels: { text: { fontWeight: 500 } }, // $bold
        }}
        defs={[
          {
            id: 'magicGradient',
            type: 'linearGradient',
            colors: [
              { offset: 0, color: '#EDC6ED' }, // $bgDecorative4Default
              { offset: 100, color: '#CEE5FD' }, // $bgDecorative3Accent
            ],
            x1: '0%',
            x2: '100%',
            y1: '0%',
            y2: '0%',
          },
        ]}
        fill={[{ match: (d) => d.color === 'magic', id: 'magicGradient' }]}
      />
      {legendDirection === 'horizontal' ? (
        <HorizontalLegend colors={colors} labels={labels} />
      ) : (
        <VerticalLegend
          values={formattedValues}
          colors={colors}
          labels={labels}
          percentChange={percentChange}
        />
      )}
    </>
  );
};
