import { format, sub } from 'date-fns';
import merge from 'lodash/merge';

import { MSWRequest, MSWRestHandler, mswRest } from '@attentive/mock-data';
import { DATE_FNS_ISO_8601_DATE_FORMAT } from '@attentive/picnic';

import { GetMetricsQueryResponse, LegacyMetricId, TimeGranularity } from '../../services';
import { LegacyMetricFactory } from '../factories/LegacyMetricFactory';

export const createGetMetricsMock = (
  args: {
    body?: GetMetricsQueryResponse;
  } = {}
): MSWRestHandler<MSWRequest<GetMetricsQueryResponse>> => {
  const { body } = args;
  const numDomains = 3;
  const defaultStartDate = format(sub(new Date(), { days: 30 }), DATE_FNS_ISO_8601_DATE_FORMAT);
  const defaultEndDate = format(new Date(), DATE_FNS_ISO_8601_DATE_FORMAT);
  const defaultResponse: GetMetricsQueryResponse = {
    domains: [...Array(numDomains)].map(() =>
      LegacyMetricFactory.createDomain({ startDate: defaultStartDate, endDate: defaultEndDate })
    ),
  };

  return mswRest.get<GetMetricsQueryResponse>(
    /https:\/\/ui-api-devel.attentivemobile.com\/report-platform\/api\/metrics\/query$/,
    (req, res, ctx) => {
      const metricsList = req.url.searchParams.get('metrics')?.split(',') as LegacyMetricId[];

      const requestStartDate = req.url.searchParams.get('startDate');
      const requestEndDate = req.url.searchParams.get('endDate');
      const requestGroupByMonth = req.url.searchParams.get('groupByMonth') === 'true';

      const response = metricsList?.length
        ? {
            domains: [
              LegacyMetricFactory.createDomain({
                startDate: requestStartDate || defaultStartDate,
                endDate: requestEndDate || defaultEndDate,
                metricIds: metricsList,
                timeGranularity: requestGroupByMonth ? TimeGranularity.MONTH : TimeGranularity.DAY,
              }),
            ],
          }
        : defaultResponse;

      return res(ctx.json(merge(response, body)));
    }
  );
};
