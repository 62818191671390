import React, { useState } from 'react';

import { DateFilterPeriod } from '@attentive/data/types';

import { getDateRangeOption } from '../../components';
import { ComparisonPeriod } from '../../types';

interface DateRangeContext {
  startDate: string;
  endDate: string;
  dateFilterPeriod: DateFilterPeriod | null;
  comparisonPeriod?: ComparisonPeriod | null;
  setDateRange: (
    start: string,
    end: string,
    dateFilter: DateFilterPeriod | null,
    comparison?: ComparisonPeriod | null
  ) => void;
}

const DateRangeContext = React.createContext<DateRangeContext | undefined>(undefined);

const useDateRangeContext = (): DateRangeContext => {
  const context = React.useContext(DateRangeContext);
  if (context === undefined) {
    throw new Error('useDateRangeContext must be used within a DateRangeProvider');
  }
  return context as DateRangeContext;
};

interface DateRangeProviderProps {
  timezone?: string;
}

const DateRangeProvider: React.FC<DateRangeProviderProps> = ({ timezone, children }) => {
  // TODO: Eventually we want to dynamically set the default depending on the type of report
  // For now, defaulting to Last 30 days
  // The same applies to dateFilterPeriod below
  const lastThirtyDaysDateRange = getDateRangeOption('Last 30 days', timezone);

  const [startDate, setStartDate] = useState<string>(lastThirtyDaysDateRange.getStartDate());
  const [endDate, setEndDate] = useState<string>(lastThirtyDaysDateRange.getEndDate());
  const [dateFilterPeriod, setDateFilterPeriod] = useState<DateFilterPeriod | null>(
    lastThirtyDaysDateRange.dateFilterPeriod
  );
  const [comparisonPeriod, setComparisonPeriod] = useState<ComparisonPeriod | null>(null);

  const setDateRange = (
    start: string,
    end: string,
    dateFilter: DateFilterPeriod | null,
    comparison?: ComparisonPeriod | null
  ) => {
    setStartDate(start);
    setEndDate(end);
    setDateFilterPeriod(dateFilter);
    setComparisonPeriod(comparison);
  };

  return (
    <DateRangeContext.Provider
      value={{
        startDate,
        endDate,
        dateFilterPeriod,
        comparisonPeriod,
        setDateRange,
      }}
    >
      {children}
    </DateRangeContext.Provider>
  );
};

export { DateRangeProvider, useDateRangeContext };
