import { Table, styled } from '@attentive/picnic';

import { SORT_ICON_WIDTH } from './utils';

export const StyledBodyCell = styled(Table.BodyCell, {
  wordBreak: 'break-word',
  variants: {
    type: {
      emphasized: { fontWeight: '$bold', backgroundColor: '$bgAccentSubtle' },
      plain: { backgroundColor: 'white' },
    },
    alignment: {
      left: {
        textAlign: 'left',
        pl: SORT_ICON_WIDTH,
        pr: 0,
      },
      right: {
        textAlign: 'right',
        pl: 0,
        pr: SORT_ICON_WIDTH,
      },
    },
    stickyLeft: {
      true: {
        position: 'sticky',
        left: '0',
        zIndex: 1,
        borderRight: '1px $grayscale200 solid',
      },
    },
  },
});
