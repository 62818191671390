import { DecoratorFn, Args } from '@storybook/react';
import { sub } from 'date-fns';
import { graphql, ResponseResolver, GraphQLRequest, GraphQLContext } from 'msw';
import React from 'react';

import { castStringAsSerializedDateTime } from '@attentive/data';
import { MetricsOrderByDirection } from '@attentive/data/types';
import { DateFormat, formatDateWithLocalTime, Locale } from '@attentive/locale-utils';
import { createNode, parseMSWHeaders } from '@attentive/mock-data';

import { Metric, TimeDimensionGranularity, DimensionFilter } from '../../services';
import { MetricFactory } from '../../services/metrics/MetricFactory';

import { DEFAULT_PAGE_SIZE, PaginatedMetricsTablePresenter } from './MetricsTableContext';

import {
  MetricsTableContextReportingPlatformCompanyRefetchFragment$data,
  MetricsTableContextReportingPlatformCompanyRefetchFragment$variables,
} from './__generated__/MetricsTableContextReportingPlatformCompanyRefetchFragment.graphql';
import { MetricsTableContextReportingPlatform_query$data } from './__generated__/MetricsTableContextReportingPlatform_query.graphql';

// Compatibility conversion type as all types pulled from .graphql files are read-only
type Writeable<T> = { -readonly [P in keyof T]: Writeable<T[P]> };

const metricsTableGqlResponseResolver: ResponseResolver<
  GraphQLRequest<MetricsTableContextReportingPlatformCompanyRefetchFragment$variables>,
  GraphQLContext<Writeable<MetricsTableContextReportingPlatform_query$data>>
> = async (
  { variables: { first, filters, after, metricIds: reqMetricIds }, headers },
  res,
  ctx
) => {
  const mswHeaders = parseMSWHeaders<MetricsTableContextMockMswHeaders>(headers);
  const metricIds = mswHeaders.metricIds !== undefined ? mswHeaders.metricIds : [...reqMetricIds];
  const numericAfter = Number(after) || 0;

  const metricsTable = mswHeaders.metrics
    ? MetricFactory.createMetricsTable(mswHeaders.metrics)
    : MetricFactory.createMetricsTable(
        MetricFactory.createMetricList({
          numMetrics: metricIds.length,
          metricIds,
          filters: filters as DimensionFilter[] | undefined,
          dimensionGroupings: filters?.map((f) => ({
            dimensionId: f.dimensionId,
            granularity:
              f.dimensionId === 'date'
                ? TimeDimensionGranularity.TimeDimensionGranularityDaily
                : null,
          })),
        })
      );
  metricsTable.pageInfo = createNode('PageInfo', {
    id: 'testInfo',
    hasNextPage: numericAfter + first < metricsTable.edges.length,
    hasPreviousPage: numericAfter > 0,
    startCursor: after ?? '0',
    endCursor: `${numericAfter + first}`,
  });
  metricsTable.edges = metricsTable.edges.slice(numericAfter || 0, (numericAfter || 0) + first);

  if (mswHeaders.metrics !== undefined) {
    return res(
      ctx.data({
        company: {
          __typename: 'Company',
          id: 'testCompanyId',
          metricsTable,
        },
      } as unknown as Writeable<MetricsTableContextReportingPlatform_query$data>)
    );
  } else if (metricIds.length) {
    return res(
      ctx.data({
        company: {
          __typename: 'Company',
          id: 'testCompanyId',
          metricsTable,
        },
      } as unknown as Writeable<MetricsTableContextReportingPlatform_query$data>)
    );
  }
};

export interface MetricsTableContextMockMswHeaders {
  status?: number;
  metricIds?: string[];
  metrics?: Metric[];
}

export const MetricsTableContextMock = graphql.query<
  MetricsTableContextReportingPlatform_query$data,
  MetricsTableContextReportingPlatformCompanyRefetchFragment$variables
>('MetricsTableContextReportingPlatformQuery', metricsTableGqlResponseResolver);

export const MetricsTableContextReportingPlatformCompanyRefetchFragmentMock = graphql.query<
  MetricsTableContextReportingPlatformCompanyRefetchFragment$data,
  MetricsTableContextReportingPlatformCompanyRefetchFragment$variables
>(
  'MetricsTableContextReportingPlatformCompanyRefetchFragment',
  metricsTableGqlResponseResolver as unknown as ResponseResolver<
    GraphQLRequest<MetricsTableContextReportingPlatformCompanyRefetchFragment$variables>,
    GraphQLContext<MetricsTableContextReportingPlatformCompanyRefetchFragment$data>
  >
);

export interface MetricsTableContextStoryDecoratorArgs extends Args {
  metricIds: string[];
  filters?: DimensionFilter[];
}
export const MetricsTableContextStoryDecorator: DecoratorFn = (Story, context) => {
  const { metricIds, filters } = context.args as MetricsTableContextStoryDecoratorArgs;
  const defaultStartDate = formatDateWithLocalTime(
    sub(new Date(), { months: 1 }),
    DateFormat.ISO_8601,
    Locale.enUS
  );
  const defaultEndDate = formatDateWithLocalTime(new Date(), DateFormat.ISO_8601, Locale.enUS);
  const defaultMetricIds = ['sms_unsubscribes'];
  const defaultGroupings = [
    {
      dimensionId: 'date',
      granularity: TimeDimensionGranularity.TimeDimensionGranularityDaily,
    },
  ];
  const defaultFilters: DimensionFilter[] = [
    {
      dimensionId: 'date',
      startDate: castStringAsSerializedDateTime(defaultStartDate),
      endDate: castStringAsSerializedDateTime(defaultEndDate),
      operator: null,
      value: null,
      list: null,
      nodeId: null,
      nodeIds: null,
    },
  ];

  return (
    <PaginatedMetricsTablePresenter
      companyId="testCompanyId"
      first={DEFAULT_PAGE_SIZE}
      metricIds={metricIds || defaultMetricIds}
      groupings={defaultGroupings}
      filters={filters !== undefined ? filters : defaultFilters}
      orderBy={[
        {
          metricId: 'message_target_total_clicks',
          direction: MetricsOrderByDirection.MetricsOrderByDirectionDesc,
        },
        {
          metricId: 'message_target_unique_clicks',
          direction: MetricsOrderByDirection.MetricsOrderByDirectionDesc,
        },
      ]}
    >
      <Story />
    </PaginatedMetricsTablePresenter>
  );
};
