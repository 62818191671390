import { useCompanyFeatureFlag } from '@attentive/acore-utils';

export const useAIProReportingFeatureFlags = () => {
  const isAiProEnabled = useCompanyFeatureFlag('ENABLE_AI_PRO_CLIENT_FACING_EXPERIENCE');
  const isAiProReportingDisabled = useCompanyFeatureFlag('DISABLE_REPORTING_AI_PRO_BUNDLE');
  const isAiProReportingEnabled = useCompanyFeatureFlag('ENABLE_REPORTING_AI_PRO_BUNDLE');
  const isIdentityDisabled = useCompanyFeatureFlag('DISABLE_DEVICE_BASED_EVENT_MATCHING');
  const isAiProAggregateReportingEnabled = useCompanyFeatureFlag(
    'ENABLE_AI_PRO_AGGREGATE_REPORTING'
  );

  const showAiProReportingFeatures =
    !isAiProReportingDisabled && (isAiProEnabled || isAiProReportingEnabled);
  const showAiProDashboard = showAiProReportingFeatures && isAiProAggregateReportingEnabled;
  // AI Pro churn state: When clients no longer have AI Pro enabled but still have access to AI Pro reporting features.
  const showAiProChurnState = showAiProReportingFeatures && !isAiProEnabled;

  return {
    isIdentityDisabled,
    showAiProReportingFeatures,
    showAiProDashboard,
    showAiProChurnState,
  };
};

export const AI_METRICS_TO_HIDE = [
  'campaign_suppressed_sends',
  'identity_ai_total_sends',
  'identity_ai_total_clicks',
  'identity_ai_click_rate',
  'identity_ai_total_unsubscribes',
  'identity_ai_unsubscribe_rate',
  'identity_ai_conversions',
  'identity_ai_revenue',
  'identity_ai_conversion_rate',
  'identity_ai_revenue_per_send',
  'identity_ai_average_order_value',
];

export const AI_DIMENSIONS_TO_HIDE = [
  'audience_ai_segment_group',
  'has_audience_ai',
  'identity_group',
];

export const AUDIENCES_AI_OPTIMIZED_DIMENSION_ID = 'has_audience_ai';
export const SEGMENT_DIMENSION_ID = 'foo';
