import React, { ReactElement, useEffect, useState } from 'react';

import { BarAxisProps, BarChart } from '@attentive/charts';
import { PicnicCss, Stack, keyframes } from '@attentive/picnic';

import { BarChartLegendItem } from '../BarChartLegendItem';
import {
  buildTickFormatter,
  buildValueFormatter,
  calculateMaxValue,
  calculateTickValues,
  transformSeries,
} from '../SingleStackedHorizontalBarChart/utils';

import { MultiStackVerticalBarChartTooltip } from './MultiStackedVerticalBarChartTooltip';
import { MultiStackBarDatum, ComputedDatum, BarDatum } from './types';
import { transformMultiStackSeries, findHighestSum } from './utils';

const fadeInAnimation = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

export interface MultiStackedBarChartProps {
  series: MultiStackBarDatum[];
  indexedBy: string;
  labeledBy?: string;
  css?: PicnicCss;
  hideLegend?: boolean;
  useRange?: boolean;
  yAxisLabel?: string;
  tickMultiplesToShow?: number;
  barChartCss?: PicnicCss;
  barChartMargin?: { top: number; right: number; bottom: number; left: number };
  hideBottomAxisBreakpoint?: number;
  animate?: boolean;
  fadeIn?: boolean;
  getTooltipAuxiliaryData?: (data: BarDatum, indexedBy: string) => ReactElement;
  onMouseEnter?: (datum: ComputedDatum<BarDatum>, event: React.MouseEvent<Element>) => void;
}

export const MultiStackedVerticalBarChart: React.FunctionComponent<MultiStackedBarChartProps> = ({
  series,
  css,
  indexedBy,
  labeledBy = '',
  hideLegend,
  useRange,
  yAxisLabel,
  tickMultiplesToShow = 1,
  barChartCss,
  barChartMargin,
  hideBottomAxisBreakpoint,
  animate = true,
  fadeIn = false,
  getTooltipAuxiliaryData,
  onMouseEnter,
}) => {
  const [chartData, setChartData] = useState<MultiStackBarDatum[]>(series);
  const [animationKey, setAnimationKey] = useState<string>('');

  // See https://github.com/plouc/nivo/issues/732#issuecomment-858869327.
  // This prevents the default Nivo animation when the chart is first mounted.
  useEffect(() => {
    const animation = setTimeout(() => setChartData(series), 1);
    if (fadeIn) {
      setAnimationKey(`${Date.now()}`);
    }

    return () => {
      clearTimeout(animation);
    };
  }, [series, fadeIn]);

  const { seriesdata, colors, labels } = transformMultiStackSeries(chartData, indexedBy);
  const { dataType, currencyCode, locale } = transformSeries(chartData);

  const tickFormat = buildTickFormatter(dataType, currencyCode, locale);
  const valueFormat = buildValueFormatter(dataType, currencyCode, locale);

  const highestTotal = findHighestSum(seriesdata, labels);
  const maxValue = calculateMaxValue(highestTotal);
  const tickValues = calculateTickValues(maxValue);

  const capitalizedLabel = yAxisLabel
    ? yAxisLabel.charAt(0).toUpperCase() + yAxisLabel?.slice(1)
    : '';

  const axisLeft: BarAxisProps = {
    legendOffset: -80,
    legendPosition: 'middle',
    tickValues,
    format: tickFormat,
    legend: capitalizedLabel,
    tickPadding: 8,
  };

  return (
    <Stack
      direction="vertical"
      css={{ ...css, ...(fadeIn && { animation: `${fadeInAnimation} ease-in-out 0.75s` }) }}
      key={animationKey}
    >
      <Stack direction="horizontal" spacing="$space8" css={{ marginRight: '$space8' }}>
        {!hideLegend &&
          labels.map((_, index) => (
            <BarChartLegendItem
              verticalCircleGradient
              key={labels[index]}
              label={labels[index]}
              color={colors[index]}
              size="small"
            />
          ))}
      </Stack>
      <BarChart
        tickMultiplesToShow={tickMultiplesToShow}
        css={{ height: '100%', width: '100%', ...barChartCss }}
        {...(barChartMargin && { margin: barChartMargin })}
        padding={0.3}
        data={seriesdata}
        colors={colors}
        motionConfig={{ tension: 500, friction: 60, mass: 1 }}
        animate={animate}
        keys={labels}
        layout="vertical"
        indexBy={indexedBy}
        labelBy={labeledBy}
        maxValue={maxValue}
        valueFormat={valueFormat}
        axisLeft={axisLeft}
        {...(hideBottomAxisBreakpoint &&
          seriesdata.length > hideBottomAxisBreakpoint && { axisBottom: null })}
        gridYValues={tickValues}
        enableGridY={true}
        borderColor="#FFFFFF"
        borderWidth={1}
        borderRadius={2}
        onMouseEnter={onMouseEnter}
        tooltip={({ data }) => (
          <MultiStackVerticalBarChartTooltip
            useRange={!!useRange}
            data={data}
            labels={labels}
            colors={colors}
            indexedBy={indexedBy}
          >
            {getTooltipAuxiliaryData && getTooltipAuxiliaryData(data, indexedBy)}
          </MultiStackVerticalBarChartTooltip>
        )}
        theme={{
          textColor: '#1B1F23',
          fontFamily: 'Ginto Normal',
          fontSize: 12,
          axis: { ticks: { text: { fill: '#656567' } } },
          labels: { text: { fontWeight: 500 } },
        }}
        defs={[
          {
            id: 'magicGradient',
            type: 'linearGradient',
            colors: [
              { offset: 0, color: '#EDC6ED' },
              { offset: 100, color: '#CEE5FD' },
            ],
            x1: '0%',
            x2: '0%',
            y1: '100%',
            y2: '0%',
          },
        ]}
        fill={[{ match: (d) => d.color === 'magic', id: 'magicGradient' }]}
      />
    </Stack>
  );
};
