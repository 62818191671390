import { DecoratorFn, Args } from '@storybook/react';
import { sub } from 'date-fns';
import { graphql, ResponseResolver, GraphQLRequest, GraphQLContext } from 'msw';
import React from 'react';

import { castStringAsSerializedDateTime } from '@attentive/data';
import { DateFormat, formatDateWithLocalTime, Locale } from '@attentive/locale-utils';
import { parseMSWHeaders } from '@attentive/mock-data';

import { DimensionFilter, Metric, MetricFactory, TimeDimensionGranularity } from '../../services';

import { MetricsProvider } from './MetricsContext';

import {
  MetricsContextReportingPlatformQuery$rawResponse,
  MetricsContextReportingPlatformQuery$variables,
} from './__generated__/MetricsContextReportingPlatformQuery.graphql';

const defaultMetrics = MetricFactory.createMetricList({ numMetrics: 5 });
const defaultResponse: MetricsContextReportingPlatformQuery$rawResponse = {
  company: {
    __typename: 'Company',
    id: 'company-1',
    metrics: defaultMetrics,
    metricsForCharts: defaultMetrics,
  },
} as MetricsContextReportingPlatformQuery$rawResponse;

const metricsGqlResponseResolver: ResponseResolver<
  GraphQLRequest<MetricsContextReportingPlatformQuery$variables>,
  GraphQLContext<MetricsContextReportingPlatformQuery$rawResponse>
> = async (req, res, ctx) => {
  const mswHeaders = parseMSWHeaders<MetricsContextMockMswHeaders>(req.headers);
  const metricIds =
    mswHeaders.metricIds !== undefined ? mswHeaders.metricIds : req.variables.metricIds;

  // @ts-ignore - make sure each grouping has a dimensionId
  const dimensionGroupings: DimensionGrouping[] | undefined = req.variables.groupings
    ?.map((g) => {
      return {
        ...g,
        dimensionId: g?.dimensionId || '',
        granularity: g?.granularity || null,
      };
    })
    .filter((g) => g.dimensionId !== '');

  let responseData = defaultResponse;

  if (mswHeaders.metrics !== undefined) {
    responseData = {
      company: {
        __typename: 'Company',
        id: 'company-1',
        metrics: mswHeaders.metrics,
        metricsForCharts: mswHeaders.metrics,
      },
    } as MetricsContextReportingPlatformQuery$rawResponse;
  } else if (metricIds.length !== undefined) {
    const metrics = MetricFactory.createMetricList({
      numMetrics: metricIds.length,
      // @ts-ignore
      metricIds,
      dimensionGroupings,
      filters: req.variables.filters as DimensionFilter[] | undefined,
    });
    responseData = {
      company: {
        __typename: 'Company',
        id: 'company-1',
        metrics,
        metricsForCharts: metrics,
      },
    } as MetricsContextReportingPlatformQuery$rawResponse;
  }

  return res(ctx.data(responseData));
};

export interface MetricsContextMockMswHeaders {
  status?: number;
  metricIds?: string[];
  metrics?: Metric[];
}

export const MetricsContextMock = graphql.query<
  MetricsContextReportingPlatformQuery$rawResponse,
  MetricsContextReportingPlatformQuery$variables
>('MetricsContextReportingPlatformQuery', metricsGqlResponseResolver);

export const MetricsContextReportingPlatformCompanyRefetchFragmentMock = graphql.query<
  MetricsContextReportingPlatformQuery$rawResponse,
  MetricsContextReportingPlatformQuery$variables
>('MetricsContextReportingPlatformCompanyRefetchFragment', metricsGqlResponseResolver);

export interface MetricsContextStoryDecoratorArgs extends Args {
  metricIds: string[];
  filters?: DimensionFilter[];
}
export const MetricsContextStoryDecorator: DecoratorFn = (Story, context) => {
  const { metricIds, filters } = context.args as MetricsContextStoryDecoratorArgs;
  const defaultStartDate = formatDateWithLocalTime(
    sub(new Date(), { months: 1 }),
    DateFormat.ISO_8601,
    Locale.enUS
  );
  const defaultEndDate = formatDateWithLocalTime(new Date(), DateFormat.ISO_8601, Locale.enUS);
  const defaultMetricIds = ['sms_unsubscribes'];
  const defaultGroupings = [
    {
      dimensionId: 'date',
      granularity: TimeDimensionGranularity.TimeDimensionGranularityDaily,
    },
  ];
  const defaultFilters: DimensionFilter[] = [
    {
      dimensionId: 'date',
      startDate: castStringAsSerializedDateTime(defaultStartDate),
      endDate: castStringAsSerializedDateTime(defaultEndDate),
      operator: null,
      value: null,
      list: null,
      nodeId: null,
      nodeIds: null,
    },
  ];

  return (
    <MetricsProvider
      metricIds={metricIds || defaultMetricIds}
      groupings={defaultGroupings}
      filters={filters !== undefined ? filters : defaultFilters}
    >
      <Story />
    </MetricsProvider>
  );
};
