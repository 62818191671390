/**
 * @generated SignedSource<<a77490f106bb5687c2a844e766a14665>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useCMGReportUrlDeepLinkFragment_composeCampaign$data = {
  readonly company: {
    readonly externalId: number;
    readonly timezone: string;
  } | null;
  readonly name: string;
  readonly " $fragmentType": "useCMGReportUrlDeepLinkFragment_composeCampaign";
};
export type useCMGReportUrlDeepLinkFragment_composeCampaign$key = {
  readonly " $data"?: useCMGReportUrlDeepLinkFragment_composeCampaign$data;
  readonly " $fragmentSpreads": FragmentRefs<"useCMGReportUrlDeepLinkFragment_composeCampaign">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useCMGReportUrlDeepLinkFragment_composeCampaign",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "externalId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timezone",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ComposeCampaign",
  "abstractKey": null
};

(node as any).hash = "2ad3d5878770e597fd0c5854708334f5";

export default node;
