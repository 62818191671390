/**
 * @generated SignedSource<<053104fc4a11735f39fab34cac739789>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MetricDataType = "METRIC_DATA_TYPE_CURRENCY" | "METRIC_DATA_TYPE_NUMBER" | "METRIC_DATA_TYPE_PERCENT" | "METRIC_DATA_TYPE_UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useVisibleMetrics_ReportingPlatform_metrics$data = ReadonlyArray<{
  readonly definition: {
    readonly dataType: MetricDataType;
  };
  readonly " $fragmentSpreads": FragmentRefs<"useReportMetricsData_AnalyticsUI_metrics">;
  readonly " $fragmentType": "useVisibleMetrics_ReportingPlatform_metrics";
}>;
export type useVisibleMetrics_ReportingPlatform_metrics$key = ReadonlyArray<{
  readonly " $data"?: useVisibleMetrics_ReportingPlatform_metrics$data;
  readonly " $fragmentSpreads": FragmentRefs<"useVisibleMetrics_ReportingPlatform_metrics">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "useVisibleMetrics_ReportingPlatform_metrics",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MetricDefinition",
      "kind": "LinkedField",
      "name": "definition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dataType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useReportMetricsData_AnalyticsUI_metrics"
    }
  ],
  "type": "Metric",
  "abstractKey": null
};

(node as any).hash = "b3f00820d6f452f4adb39bca22650e59";

export default node;
