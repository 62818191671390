import React, { useEffect, useState } from 'react';

import { useLocaleConfig } from '@attentive/acore-utils';
import { DateFormat, formatDateWithLocalTime } from '@attentive/locale-utils';
import {
  DateRangeChangeType,
  DateRangePicker as PicnicDateRangePicker,
  Heading,
  Icon,
  PicnicCss,
  Separator,
} from '@attentive/picnic';

import { QuickDateRangePicker } from '../QuickDateRangePicker';

type PickedPopoverProps = Pick<
  React.ComponentProps<typeof PicnicDateRangePicker.Popover>,
  'side' | 'avoidCollisions'
>;

type PickedControlProps = Pick<
  React.ComponentProps<typeof PicnicDateRangePicker.Control>,
  | 'startDate'
  | 'endDate'
  | 'maxExpectedYear'
  | 'minExpectedYear'
  | 'isDayBlocked'
  | 'onChange'
  | 'minimumNights'
  | 'numberOfMonths'
>;

type DateRangeSelectCalendarProps = PickedPopoverProps &
  PickedControlProps & {
    isOpen: boolean;
    setIsOpen?: (newIsOpen: boolean) => void;
    css?: PicnicCss;
  };

export const DateRangeSelectCalendar: React.FC<DateRangeSelectCalendarProps> = ({
  startDate,
  endDate,
  maxExpectedYear,
  minExpectedYear,
  isDayBlocked,
  onChange,
  minimumNights,
  avoidCollisions,
  isOpen,
  setIsOpen,
  numberOfMonths,
}) => {
  const { locale } = useLocaleConfig();

  // keep track of start and end dates locally because we only call onChange() if user clicks "Apply"
  const [localStartDate, setLocalStartDate] = useState<string | null>(startDate);
  const [localEndDate, setLocalEndDate] = useState<string | null>(endDate);

  useEffect(() => {
    // when modal opens, reset the start and end dates based on props
    setLocalStartDate(startDate);
    setLocalEndDate(endDate);
  }, [isOpen, startDate, endDate]);

  const handleLocalDateChange: DateRangeChangeType = (changedDates) => {
    setLocalStartDate(changedDates.startDate);
    setLocalEndDate(changedDates.endDate);
  };

  const handleApplyDateChange = () => {
    if (onChange) {
      onChange({
        startDate: localStartDate,
        endDate: localEndDate,
      });
    }
    setIsOpen && setIsOpen(false);
  };

  const handleCancelDateChange = () => {
    setIsOpen && setIsOpen(false);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <PicnicDateRangePicker.Root open={isOpen} onOpenChange={setIsOpen}>
      <PicnicDateRangePicker.Anchor>
        <div></div>
      </PicnicDateRangePicker.Anchor>
      <PicnicDateRangePicker.Popover align="start" side="bottom" avoidCollisions={avoidCollisions}>
        {localStartDate && localEndDate && (
          <Heading
            variant="sm"
            css={{ ml: '$space7', mt: '$space6', display: 'flex', alignItems: 'center' }}
          >
            {formatDateWithLocalTime(localStartDate, DateFormat.SHORT_MONTH, locale)}
            <Icon name="ArrowRight" size="small" css={{ mx: '$space4' }} />
            {formatDateWithLocalTime(localEndDate, DateFormat.SHORT_MONTH, locale)}
          </Heading>
        )}
        <Separator css={{ mt: '$space5', mx: '$space7', width: 'auto' }} />

        <PicnicDateRangePicker.Control
          startDate={localStartDate}
          endDate={localEndDate}
          onChange={handleLocalDateChange}
          maxExpectedYear={maxExpectedYear}
          minExpectedYear={minExpectedYear}
          isDayBlocked={isDayBlocked}
          minimumNights={minimumNights || 0}
          numberOfMonths={numberOfMonths}
        />

        <Separator css={{ mb: '$space5', mx: '$space7', width: 'auto' }} />
        <QuickDateRangePicker.Buttons
          handleCancelDateChange={handleCancelDateChange}
          handleApplyDateChange={handleApplyDateChange}
        />
      </PicnicDateRangePicker.Popover>
    </PicnicDateRangePicker.Root>
  );
};
