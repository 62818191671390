import React from 'react';

import { Table } from '@attentive/picnic';

import { MetricRawRow } from '../../hooks';

import { StyledBodyCell } from './StyledBodyCell';

interface BodyRowProps {
  row: MetricRawRow;
  rowIndex: number;
}
export const BodyRow = ({ row, rowIndex }: BodyRowProps) => {
  return (
    <Table.BodyRow>
      {row.map((cell, columnIndex) => {
        // left align grouping values, left align metric values
        const isLeftAligned = cell.isGroupingDimensionValue;
        const sticky = columnIndex === 0;
        return (
          <StyledBodyCell
            // eslint-disable-next-line react/no-array-index-key
            key={`cell: ${rowIndex}, ${columnIndex}, ${cell.value}`}
            align={isLeftAligned ? 'left' : 'right'}
            alignment={isLeftAligned ? 'left' : 'right'}
            data-analytics-id={`report-table-row-${rowIndex}-cell`}
            stickyLeft={sticky}
            type="plain"
          >
            {cell.value}
          </StyledBodyCell>
        );
      })}
    </Table.BodyRow>
  );
};
