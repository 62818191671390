import { format, isBefore, isValid as isValidDate } from 'date-fns';
import React, { useMemo } from 'react';

import { Tracker, TrackerEvents, useCurrentUser } from '@attentive/acore-utils';
import {
  AxisDataType,
  LegendProps,
  LineChart,
  Point,
  PointDataType,
  TimePrecision,
} from '@attentive/charts';
import {
  DateFormat,
  formatDateWithLocalTime,
  Locale,
  CountryCode,
  getLocaleConfig,
} from '@attentive/locale-utils';

import {
  LegacyDateDimension,
  LegacyMetric,
  LegacySourceDimension,
  TimeDimensionGranularity,
} from '../../services';
import {
  DEFAULT_LOCALE_CONFIG,
  formatLegacyMetricValue,
  useIsMultiSourceMetric,
} from '../../utils';
import { ChartDataUnavailableNotification } from '../DataUnavailableNotification';

import { MetricLineChartCommonProps } from './types';

const PU_CHART_DEFAULT_HEIGHT = 240;

export interface MetricLineChartProps extends MetricLineChartCommonProps {
  metric: LegacyMetric;
  startDate?: never;
  endDate?: never;
  metricId?: never;
  region?: CountryCode;
  legend?: LegendProps;
  isBillableReport?: boolean;
}

export const getXAxisPrecisionFromTimeGranularity = (
  granularity: TimeDimensionGranularity
): TimePrecision => {
  switch (granularity) {
    case TimeDimensionGranularity.TimeDimensionGranularityMonthly: {
      return 'month';
    }
    case TimeDimensionGranularity.TimeDimensionGranularityYearly: {
      return 'year';
    }
    default: {
      return 'day';
    }
  }
};

export const getDateFormatFromXAxisPrecision = (precision: TimePrecision): string => {
  switch (precision) {
    case 'year': {
      return 'y';
    }
    case 'month': {
      return 'MMMM y';
    }
    default: {
      return 'E, MMM d, y';
    }
  }
};

const dateSorter = (a: Point, b: Point) =>
  isBefore(new Date(a.x ?? ''), new Date(b.x ?? '')) ? -1 : 1;

const dateDimensionMapperFactory =
  (locale: Locale, metric: LegacyMetric) =>
  ({ date, value }: LegacyDateDimension) => {
    return {
      x:
        // ensure date is in supported format across browsers before parsing with new Date()
        date !== null && isValidDate(new Date(date.replace(/-/g, '/')))
          ? formatDateWithLocalTime(date, DateFormat.ISO_8601, locale)
          : '',
      y: metric.type === 'percent' ? value * 100 : value,
    };
  };

type SourcesWithFormattedPoints = LegacySourceDimension & {
  points: Array<{ x: string; y: number }>;
};

const valueFormatter = (
  metric: LegacyMetric,
  isMultiSource: boolean,
  locale: Locale
): SourcesWithFormattedPoints[] => {
  const groupByDateMapper = dateDimensionMapperFactory(locale, metric);
  if (isMultiSource && metric.sources) {
    return (
      metric.sources.map((source) => ({
        ...source,
        points: source.groupByDate?.map(groupByDateMapper).sort(dateSorter) ?? [],
      })) ?? []
    );
  }

  return [
    {
      ...metric,
      percent: metric.total,
      points: metric.groupByDate.map(groupByDateMapper).sort(dateSorter),
    },
  ];
};

export const MetricLineChart: React.FC<MetricLineChartProps> = ({
  metric,
  dateGranularity = TimeDimensionGranularity.TimeDimensionGranularityDaily,
  isYAxisStartAtZero,
  css,
  region,
  legend,
  isBillableReport,
}) => {
  const { company } = useCurrentUser();
  const countryCode = region || company.country;
  const {
    locale,
    currencyConfig: { currencyCode },
  } = isBillableReport ? DEFAULT_LOCALE_CONFIG : getLocaleConfig(countryCode as CountryCode);
  const isMultiSource = useIsMultiSourceMetric(metric);
  const formattedValues = useMemo(
    () => valueFormatter(metric, isMultiSource, locale),
    [metric, isMultiSource, locale]
  );
  const numDataPoints = useMemo(
    () => formattedValues.reduce((total, currentValue) => total + currentValue.points.length, 0),
    [formattedValues]
  );
  const hasData = numDataPoints > 0;
  const hasOnlyOnePoint = numDataPoints === 1;

  const xAxisPrecision = useMemo(
    () => getXAxisPrecisionFromTimeGranularity(dateGranularity),
    [dateGranularity]
  );

  const handleTooltipFormatValue = (val: PointDataType) => {
    if (typeof val === 'number') {
      return formatLegacyMetricValue({
        currencyConfig: {
          currencyCode,
        },
        locale,
        metric: {
          ...metric,
          total: metric.type === 'percent' ? val / 100 : val || 0,
        },
      });
    }
    return val;
  };

  const handleMouseEnter = () => {
    Tracker.track({
      eventName: TrackerEvents.ANALYTICS_METRIC_LINE_CHART_HOVERED,
      properties: {
        title: metric.name,
        total: metric.total,
      },
    });
  };

  return hasData ? (
    <LineChart
      css={{ height: PU_CHART_DEFAULT_HEIGHT, ...css }}
      enablePoints={hasOnlyOnePoint}
      tooltipFormatter={({ x, y }: Point) => ({
        x: x ? format(new Date(x), getDateFormatFromXAxisPrecision(xAxisPrecision)) : '',
        y: handleTooltipFormatValue(y) as string,
      })}
      onMouseEnter={handleMouseEnter}
      xScale={{
        type: AxisDataType.DATE,
        precision: xAxisPrecision,
        numTicks: 3,
      }}
      isYAxisStartAtZero={isYAxisStartAtZero}
      yScale={{ type: AxisDataType.NUMBER, numTicks: 5 }}
      enableSlices={isMultiSource && 'x'}
      legend={legend}
    >
      {formattedValues.map(({ name, points }) => (
        <LineChart.Line key={name} id={name} points={points} />
      ))}
    </LineChart>
  ) : (
    <ChartDataUnavailableNotification />
  );
};
