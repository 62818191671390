import { faker } from '@faker-js/faker';
import merge from 'lodash/merge';

import { AuthUserCompany } from '@attentive/data';
import { createNode, GraphQLNode } from '@attentive/mock-data';

export const CompanyFactory = {
  create: (companyArg?: Partial<AuthUserCompany>): GraphQLNode & AuthUserCompany => {
    const companyName = faker.company.name();

    const company = merge(
      {
        id: `company-${faker.datatype.number()}`,
        internalId: faker.datatype.number(),
        name: companyName,
        displayName: companyName,
        externalId: faker.datatype.number(),
        domain: faker.internet.url(),
        attentiveDomain: `${faker.datatype.uuid()}.attn.tv`,
        status: 'ACTIVE',
        timezone: 'America/New_York',
        businessHours: {
          open: '09:00',
          close: '22:00',
          timezone: 'America/New_York',
        },
        country: 'US',
      },
      companyArg
    );
    return createNode('Company', company);
  },
};
