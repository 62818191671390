/**
 * @generated SignedSource<<81d0f68e4fa802e231bf59e4bc35c62f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type MetricConnotation = "METRIC_CONNOTATION_NEGATIVE" | "METRIC_CONNOTATION_NEUTRAL" | "METRIC_CONNOTATION_POSITIVE" | "METRIC_CONNOTATION_UNKNOWN" | "%future added value";
export type MetricDataType = "METRIC_DATA_TYPE_CURRENCY" | "METRIC_DATA_TYPE_NUMBER" | "METRIC_DATA_TYPE_PERCENT" | "METRIC_DATA_TYPE_UNKNOWN" | "%future added value";
export type TimeDimensionGranularity = "TIME_DIMENSION_GRANULARITY_15MIN" | "TIME_DIMENSION_GRANULARITY_DAILY" | "TIME_DIMENSION_GRANULARITY_HOURLY" | "TIME_DIMENSION_GRANULARITY_MONTHLY" | "TIME_DIMENSION_GRANULARITY_UNKNOWN" | "TIME_DIMENSION_GRANULARITY_WEEKLY" | "TIME_DIMENSION_GRANULARITY_YEARLY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type transformMetricsTable_ReportingPlatform_metricsTable$data = {
  readonly aggregateValues: ReadonlyArray<{
    readonly value: number | null;
  }>;
  readonly definitions: ReadonlyArray<{
    readonly connotation: MetricConnotation;
    readonly dataType: MetricDataType;
    readonly description: string;
    readonly dimensionWrappers: ReadonlyArray<{
      readonly dimension: {
        readonly __typename: "StringDimension";
        readonly description: string | null;
        readonly dimensionId: string;
        readonly name: string;
      } | {
        readonly __typename: "TimeDimension";
        readonly description: string | null;
        readonly dimensionId: string;
        readonly granularities: ReadonlyArray<TimeDimensionGranularity>;
        readonly name: string;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      };
    }>;
    readonly metricId: string;
    readonly name: string;
  }>;
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly groupingDimensions: ReadonlyArray<{
        readonly key: string;
        readonly value: string;
      }>;
      readonly values: ReadonlyArray<{
        readonly errors: ReadonlyArray<{
          readonly message: string;
        }>;
        readonly value: number | null;
      }>;
    };
  }>;
  readonly " $fragmentType": "transformMetricsTable_ReportingPlatform_metricsTable";
};
export type transformMetricsTable_ReportingPlatform_metricsTable$key = {
  readonly " $data"?: transformMetricsTable_ReportingPlatform_metricsTable$data;
  readonly " $fragmentSpreads": FragmentRefs<"transformMetricsTable_ReportingPlatform_metricsTable">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "transformMetricsTable_ReportingPlatform_metricsTable"
};

(node as any).hash = "133a5590c64f1113ff710ff7f79dd563";

export default node;
