import { BarDatum } from '@attentive/charts';
import { CurrencyCode, Locale } from '@attentive/locale-utils';

export type BarDatumType = 'number' | 'currency';

export interface SingleStackedHorizontalBarDatum extends BarDatum {
  value: number;
  color: string;
  label: string;
  dataType: BarDatumType;
  currencyCode: CurrencyCode;
  locale: Locale;
}
