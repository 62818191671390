import { addDays, differenceInCalendarDays, subDays, subYears } from 'date-fns';

import { deserializeGQLDateTime } from '@attentive/data';

import { ComparisonDateFilterPeriodType, ComparisonPeriod } from '../types';

export const formatComparisonDate = (date: SerializedDateTime | Date) => {
  const deserializedDate = date instanceof Date ? date : deserializeGQLDateTime(date);
  const year = deserializedDate.getUTCFullYear();

  // padding function to ensure month and day are two digits
  const pad = (num: number): string => (num < 10 ? `0${num}` : num.toString());

  // getUTCMonth returns a 0-indexed month (0-11), so add 1 to get the correct month
  const month = pad(deserializedDate.getUTCMonth() + 1);
  const day = pad(deserializedDate.getUTCDate());

  return `${year}-${month}-${day}`;
};
export const formatComparisonDateRange = (
  startDate: SerializedDateTime,
  endDate: SerializedDateTime
) => {
  const start = formatComparisonDate(startDate);
  const end = formatComparisonDate(endDate);
  return `${start} to ${end}`;
};

const computePreviousPeriodDates = (startDate: Date, periodLength: number) => {
  const start = subDays(startDate, periodLength);
  const end = subDays(startDate, 1);
  return { start, end };
};

const computeCustomDatesFromStartDate = (
  startDate: SerializedDateTime,
  endDate: SerializedDateTime | null,
  periodLength: number
) => {
  const period: ComparisonDateFilterPeriodType = 'COMPARISON_DATE_FILTER_CUSTOM_PERIOD';
  const start = deserializeGQLDateTime(startDate);
  // if the end date is null, derive it based off the start date and period length (with offset)
  const end = endDate ? deserializeGQLDateTime(endDate) : addDays(start, periodLength - 1);
  return { start, end, period };
};

const computeCustomDatesFromPeriod = (
  startDate: Date,
  endDate: Date,
  comparisonPeriod: NonNullable<ComparisonPeriod>,
  periodLength: number
) => {
  const period = comparisonPeriod.comparisonPeriodType;
  let start: Date | null = null;
  let end: Date | null = null;

  switch (period) {
    case 'COMPARISON_DATE_FILTER_PREVIOUS_PERIOD':
      ({ start, end } = computePreviousPeriodDates(startDate, periodLength));
      break;

    case 'COMPARISON_DATE_FILTER_PREVIOUS_YEAR':
      start = subYears(startDate, 1);
      end = subYears(endDate, 1);
      break;

    case 'COMPARISON_DATE_FILTER_CUSTOM_PERIOD':
      ({ start, end } = comparisonPeriod.startDate
        ? // if the start date changed, derive the dates based off it
          computeCustomDatesFromStartDate(
            comparisonPeriod.startDate,
            comparisonPeriod.endDate,
            periodLength
          )
        : // otherwise derive the dates based off the period
          computePreviousPeriodDates(startDate, periodLength));
      break;

    default:
      break;
  }

  return { start, end, period };
};

// on load, either the comparison period or start/end dates are set
// but on change, both the comparison period and start dates can be set
export const computeComparisonDates = (
  startDate: Date,
  endDate: Date,
  comparisonPeriod: ComparisonPeriod
) => {
  const periodLength = differenceInCalendarDays(endDate, startDate) + 1;

  // in this case, we are either on init with only the comparison period set
  // or on change with both the comparison period and start dates set
  if (comparisonPeriod?.comparisonPeriodType) {
    return computeCustomDatesFromPeriod(startDate, endDate, comparisonPeriod, periodLength);
  }

  // in this case, we are on init with only the start/end dates set
  if (comparisonPeriod?.startDate) {
    return computeCustomDatesFromStartDate(
      comparisonPeriod.startDate,
      comparisonPeriod.endDate,
      periodLength
    );
  }
};
