import { parse, isAfter, isValid } from 'date-fns';

import { DATE_FNS_ISO_8601_DATE_FORMAT } from '@attentive/picnic';

export const removeInvalidDateCharacters = (date: string): string => {
  return date.replace('/', '-').replace(/[^\d-]/g, '');
};

export const isValidDate = (date: string | undefined | null): boolean => {
  if (!date) {
    return false;
  }

  const parsedStartDate = parse(date, DATE_FNS_ISO_8601_DATE_FORMAT, new Date());
  return isValid(parsedStartDate);
};

export const isValidDateRange = ({
  newStart,
  newEnd,
}: {
  newStart: string | undefined;
  newEnd: string | undefined;
}) => {
  const parsedStartDate = parse(newStart || '', DATE_FNS_ISO_8601_DATE_FORMAT, new Date());
  const parsedEndDate = parse(newEnd || '', DATE_FNS_ISO_8601_DATE_FORMAT, new Date());
  return !isAfter(parsedStartDate, parsedEndDate);
};

export const isDateAvailable = (date: string | undefined): boolean => {
  const parsedDate = parse(date || '', DATE_FNS_ISO_8601_DATE_FORMAT, new Date());
  return !isAfter(parsedDate, new Date());
};
