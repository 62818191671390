import React, { FC } from 'react';

import { Stack, Text } from '@attentive/picnic';

type MetricInformationalDisplayWrapperProps = {
  header?: string;
  caption?: string;
};

export const MetricInformationalDisplayWrapper: FC<MetricInformationalDisplayWrapperProps> = ({
  header,
  caption,
  children,
}) => (
  <Stack spacing="$space2">
    {header && (
      <Text variant="body" css={{ fontWeight: '$bold' }}>
        {header}
      </Text>
    )}
    {children}
    {caption && (
      <Text variant="caption" color="subdued">
        {caption}
      </Text>
    )}
  </Stack>
);
