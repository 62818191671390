import { LibError } from '@attentive/acore-utils';
import { CurrencyCode, Locale } from '@attentive/locale-utils';

import {
  ColumnHeader,
  METRIC_MISSING_DATA_SYMBOL,
  MetricCellDataType,
  MetricRawDataCell,
  MetricRawRow,
} from '../hooks';
import { MetricDataType } from '../services';

import { logError } from './logger';
import { formatMetricValue } from './metricFragmentUtils';
import { formatMetricDimensionValue } from './metricUtils';

export const formatRawMetricData = ({
  rawData: { dataType, value },
  locale,
  currencyCode,
  enableRounding = true,
}: {
  rawData: MetricRawDataCell;
  locale: Locale;
  currencyCode?: CurrencyCode;
  enableRounding?: boolean;
}): string | number => {
  const valueString = `${value}`;

  if (valueString === METRIC_MISSING_DATA_SYMBOL || !value) {
    return valueString;
  }

  switch (dataType) {
    case 'StringDimension':
    case 'TimeDimension': {
      return formatMetricDimensionValue({ value: valueString, dataType });
    }
    default: {
      return formatMetricValue({
        locale,
        currencyCode,
        dataType,
        value,
        enableRounding,
      });
    }
  }
};

export const sortRows = ({
  rows,
  sortColumn,
  isAscending,
}: {
  rows: MetricRawRow[];
  sortColumn: number;
  isAscending: boolean;
}) => {
  return [...rows].sort((rowA, rowB) => {
    const cellA = rowA[sortColumn];
    const cellB = rowB[sortColumn];

    if (!cellA || !cellB) {
      const attentiveError = new LibError(
        `Unexpected error in sortRawMetricDataRows: MetricRawRow cell is undefined or null. sortColumn: ${sortColumn} metrics rows: ${rows}`,
        'reporting-platform'
      );

      logError(attentiveError);
    }

    const cellAValue = cellA?.value ?? METRIC_MISSING_DATA_SYMBOL;
    const cellBValue = cellB?.value ?? METRIC_MISSING_DATA_SYMBOL;
    const cellAString = `${cellAValue}`;
    const cellBString = `${cellBValue}`;

    // display missing data symbols at top for asc and bottom for desc
    if (cellAString === METRIC_MISSING_DATA_SYMBOL) {
      return isAscending ? -1 : 1;
    }

    if (cellBString === METRIC_MISSING_DATA_SYMBOL) {
      return isAscending ? 1 : -1;
    }

    const dataType: MetricCellDataType =
      cellA.dataType === cellB.dataType ? cellA.dataType : MetricDataType.MetricDataTypeUnknown;

    switch (dataType) {
      case MetricDataType.MetricDataTypeCurrency:
      case MetricDataType.MetricDataTypePercent:
      case MetricDataType.MetricDataTypeNumber: {
        // remove all characters except for numbers, periods, and minus signs
        const strippedCellANumber = cellAString.replace(/[^0-9.-]/g, '');
        const strippedCellBNumber = cellBString.replace(/[^0-9.-]/g, '');

        // sort by number
        if (isAscending) {
          return parseFloat(strippedCellANumber) - parseFloat(strippedCellBNumber);
        }

        return parseFloat(strippedCellBNumber) - parseFloat(strippedCellANumber);
      }
      case 'TimeDimension': {
        const dateA = new Date(cellAValue);
        const dateB = new Date(cellBValue);

        // sort by time
        if (isAscending) {
          return dateA.getTime() - dateB.getTime();
        }

        return dateB.getTime() - dateA.getTime();
      }
      case 'StringDimension':
      case MetricDataType.MetricDataTypeUnknown:
      default: {
        // sort by string
        if (isAscending) {
          return cellAString.localeCompare(cellBString);
        }

        return cellBString.localeCompare(cellAString);
      }
    }
  });
};

const MESSAGE_ID_HEADER = 'Message Id';

export const filterMessageIdFromTableData = ({
  headers,
  metricRows,
}: {
  headers: ColumnHeader[];
  metricRows: MetricRawRow[];
}): { filteredHeaders: ColumnHeader[]; filteredRows: MetricRawRow[] } => {
  const filteredHeaders = headers.filter((header) => header.name !== MESSAGE_ID_HEADER);
  const messageIdIndex = headers.findIndex((header) => header.name === MESSAGE_ID_HEADER);
  const filteredRows = [...metricRows];

  if (messageIdIndex >= 0) {
    filteredRows.forEach((row) => row.splice(messageIdIndex, 1));
  }

  return { filteredHeaders, filteredRows };
};
