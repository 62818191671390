import { useCurrentUser } from '@attentive/acore-utils';

import { getDateRangeOptions, getDateRangeTitle } from '../components';
import { useDateRangeContext } from '../contexts/DateRangeContext';
import { ReportFilter, isReportTimeFilter } from '../services';

export const useShortenReportFilters = (currentReportFilters: ReportFilter[]) => {
  const {
    company: { timezone },
  } = useCurrentUser();
  const { startDate, endDate, dateFilterPeriod } = useDateRangeContext();

  // Include all possible options
  const dateRangeOptions = getDateRangeOptions({
    companyTimezone: timezone,
    includeLast4Quarters: true,
    includeCustom: true,
  });
  const dateRangeTitle = getDateRangeTitle(dateFilterPeriod, dateRangeOptions);

  const shortFilters = [`startDate=${startDate},endDate=${endDate},period=${dateRangeTitle}`];

  currentReportFilters.forEach((filter) => {
    if (isReportTimeFilter(filter)) return;

    const filterOperator = filter.filterOperator === 'FILTER_OPERATOR_IN' ? '=' : '!=';
    const shortFilter = `${filter.dimensionId}${filterOperator}[${filter.values}]`;
    shortFilters.push(shortFilter);
  });

  return shortFilters.toString();
};
