import _useGenerateRawMetricsRows_ReportingPlatform_metricsTable from "./__generated__/useGenerateRawMetricsRows_ReportingPlatform_metricsTable.graphql";
import _useGenerateRawMetricsRows_ReportingPlatform_metrics from "./__generated__/useGenerateRawMetricsRows_ReportingPlatform_metrics.graphql";
import _useGenerateRawMetricsRows_ReportingPlatform_report from "./__generated__/useGenerateRawMetricsRows_ReportingPlatform_report.graphql";
import _useGenerateRawMetricsRows_ReportingPlatform_company from "./__generated__/useGenerateRawMetricsRows_ReportingPlatform_company.graphql";
import { useFragment } from 'react-relay';
import { graphql } from '@attentive/data';
import { formatPercentChange, getPercentChangeSymbol } from '../components/ReportTable/utils';
import { formatRawMetricData } from '../utils/metricTableUtils';
import { useVisibleMetrics } from './useVisibleMetrics';
import CompanyFragment from './__generated__/useGenerateRawMetricsRows_ReportingPlatform_company.graphql';
import MetricsFragment from './__generated__/useGenerateRawMetricsRows_ReportingPlatform_metrics.graphql';
import MetricsTableFragment from './__generated__/useGenerateRawMetricsRows_ReportingPlatform_metricsTable.graphql';
import ReportFragment from './__generated__/useGenerateRawMetricsRows_ReportingPlatform_report.graphql';
_useGenerateRawMetricsRows_ReportingPlatform_company;
_useGenerateRawMetricsRows_ReportingPlatform_report;
_useGenerateRawMetricsRows_ReportingPlatform_metrics;
_useGenerateRawMetricsRows_ReportingPlatform_metricsTable;
export const METRIC_MISSING_DATA_SYMBOL = '-';

const groupDataByDimensionValues = _ref => {
  let {
    metrics,
    groupingDimensionDataTypes
  } = _ref;
  // initialize all metric values to dashes
  const initialMetricValuesMap = (metrics === null || metrics === void 0 ? void 0 : metrics.reduce((result, metric) => {
    result[metric.definition.metricId] = {
      value: METRIC_MISSING_DATA_SYMBOL,
      dataType: metric.definition.dataType,
      isGroupingDimensionValue: false,
      rawValue: 0
    };
    return result;
  }, {})) || {};
  const dataGroupedByDimension = (metrics === null || metrics === void 0 ? void 0 : metrics.reduce((groupedData, metric) => {
    metric.groupedValues.forEach(groupedValue => {
      if (!groupedValue) {
        return; // continue looping
      }

      const stringifiedDimensionsArray = JSON.stringify(groupedValue.groupingDimensions.map(_ref2 => {
        let {
          key
        } = _ref2;
        return key;
      })); // if the grouping doesn't exist yet, initialize it

      if (!groupedData[stringifiedDimensionsArray]) {
        const groupedDimensionValues = groupedValue.groupingDimensions.map((dimension, index) => ({
          value: dimension.value,
          rawValue: dimension.value,
          dataType: groupingDimensionDataTypes[index] || 'StringDimension',
          isGroupingDimensionValue: true
        }));
        const metricValuesMap = { ...initialMetricValuesMap
        };
        groupedData[stringifiedDimensionsArray] = {
          groupedDimensionValues,
          metricValuesMap
        };
      } // add the metric to the group


      if (groupedValue.value !== null) {
        groupedData[stringifiedDimensionsArray].metricValuesMap[metric.definition.metricId] = { // update the value since everything else is set
          ...groupedData[stringifiedDimensionsArray].metricValuesMap[metric.definition.metricId],
          value: groupedValue.value
        };
      }
    });
    return groupedData;
  }, {})) || {};
  return dataGroupedByDimension;
};

const generateEmptyRawMetricsRow = _ref3 => {
  let {
    metrics,
    groupingDimensionDataTypes
  } = _ref3;
  // display dashes for grouping dimension values
  const row = [];
  groupingDimensionDataTypes.forEach(groupingDimensionDataType => {
    const cell = {
      value: METRIC_MISSING_DATA_SYMBOL,
      rawValue: 0,
      dataType: groupingDimensionDataType || 'StringDimension',
      isGroupingDimensionValue: true
    };
    row.push(cell);
  }); // display dashes for aggregate metric values

  metrics === null || metrics === void 0 ? void 0 : metrics.forEach(metric => {
    var _metric$aggregateValu, _metric$aggregateValu2;

    const cell = {
      value: (_metric$aggregateValu = metric.aggregateValue) !== null && _metric$aggregateValu !== void 0 ? _metric$aggregateValu : METRIC_MISSING_DATA_SYMBOL,
      dataType: metric.definition.dataType,
      isGroupingDimensionValue: false,
      rawValue: (_metric$aggregateValu2 = metric.aggregateValue) !== null && _metric$aggregateValu2 !== void 0 ? _metric$aggregateValu2 : 0
    };
    row.push(cell);
  });
  return row;
};

export const useGenerateTotalsRow = _ref4 => {
  let {
    headers,
    metrics,
    locale,
    currencyCode
  } = _ref4;
  const metricTotals = {};
  metrics.forEach(metric => {
    metricTotals[metric.definition.name] = metric.aggregateValue || METRIC_MISSING_DATA_SYMBOL;
  });
  return headers.map(header => {
    const formattedValue = metricTotals[header.name] ? formatRawMetricData({
      rawData: {
        dataType: header.dataType,
        value: metricTotals[header.name],
        isGroupingDimensionValue: header.isGroupingDimensionValue
      },
      locale,
      currencyCode
    }) : METRIC_MISSING_DATA_SYMBOL;
    return {
      value: formattedValue,
      rawValue: metricTotals[header.name],
      isGroupingDimensionValue: header.isGroupingDimensionValue,
      dataType: header.dataType
    };
  });
};
export const calculateRowDifference = _ref5 => {
  let {
    currentPeriodTotalsRow,
    comparisonPeriodTotalsRow,
    locale
  } = _ref5;
  const changeRow = currentPeriodTotalsRow.map((column, i) => {
    if (column.isGroupingDimensionValue) {
      return {
        isGroupingDimensionValue: column.isGroupingDimensionValue,
        dataType: column.dataType,
        value: i === 0 ? 'Change' : '',
        rawValue: 0
      };
    }

    const currentValue = column.rawValue;
    const comparisonValue = comparisonPeriodTotalsRow[i].rawValue;
    const numericCurrentValue = typeof currentValue === 'number' ? currentValue : 0;
    const numericComparisonValue = typeof comparisonValue === 'number' ? comparisonValue : 0;

    if (numericComparisonValue === 0) {
      // we can't divide by 0
      return {
        isGroupingDimensionValue: column.isGroupingDimensionValue,
        dataType: column.dataType,
        value: '-',
        rawValue: 0
      };
    }

    const percentChange = (numericCurrentValue - numericComparisonValue) / numericComparisonValue * 100;
    const formattedValue = "".concat(getPercentChangeSymbol(percentChange), " ").concat(formatPercentChange(locale, percentChange));
    return {
      isGroupingDimensionValue: column.isGroupingDimensionValue,
      dataType: column.dataType,
      value: formattedValue,
      rawValue: percentChange
    };
  });
  return changeRow;
};
export const useGenerateRawMetricsRows = _ref6 => {
  let {
    companyRef,
    reportRef,
    metricsRef,
    metricsTableRef,
    filterDimensions,
    groupingDimensions
  } = _ref6;
  const companyData = useFragment(CompanyFragment, companyRef);
  const reportData = useFragment(ReportFragment, reportRef);
  const metricsData = useFragment(MetricsFragment, metricsRef);
  const metricsTableData = useFragment(MetricsTableFragment, metricsTableRef);
  const visibleMetrics = useVisibleMetrics({
    companyRef: companyData,
    reportRef: reportData,
    metricsRef: metricsData,
    metricsTableRef: metricsTableData,
    reportFilters: filterDimensions,
    reportGroupings: groupingDimensions
  });
  const groupingDimensionDataTypes = groupingDimensions.map(grouping => grouping.__typename === 'ReportTimeGrouping' ? 'TimeDimension' : 'StringDimension'); // group all needed data by the dimension values

  const dataGroupedByDimensionValues = groupDataByDimensionValues({
    metrics: visibleMetrics,
    groupingDimensionDataTypes
  }); // construct the rows by date, dimensions, and metrics

  const rows = [];
  Object.keys(dataGroupedByDimensionValues).forEach(key => {
    const data = dataGroupedByDimensionValues[key]; // create the row

    const row = []; // put all the dimension values in

    row.push(...data.groupedDimensionValues); // put the date up front

    row.sort(r => r.dataType === 'TimeDimension' ? -1 : 1); // add the metric data

    const metricData = Object.values(data.metricValuesMap);
    row.push(...metricData);
    rows.push(row);
  }); // if there are no rows, then there is no data OR there are no groupings
  // either way, display dashes for grouping values and metrics aggregate values

  if (!rows.length) {
    const emptyDataRow = generateEmptyRawMetricsRow({
      groupingDimensionDataTypes,
      metrics: visibleMetrics
    }); // display a row of dashes in case any groupings or metrics were added

    if (emptyDataRow.length) {
      rows.push(emptyDataRow);
    }
  }

  return rows;
};