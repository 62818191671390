import _useReportDateRangesFragmentReportingPlatform_report from "./__generated__/useReportDateRangesFragmentReportingPlatform_report.graphql";
import { format, parseISO } from 'date-fns';
import { useFragment } from 'react-relay';
import { deserializeGQLDateTime, graphql } from '@attentive/data';
import { computeComparisonDates } from './comparisonDates';
import { getCalendarDay } from './dates';
import useReportDateRangesFragmentReportingPlatform_report from './__generated__/useReportDateRangesFragmentReportingPlatform_report.graphql';

const isReportTimeFilter = reportFilter => reportFilter.__typename === 'ReportTimeFilter';

const clipDateOnly = date => {
  if (!date) {
    return null;
  }

  return format(date, 'yyyy-MM-dd');
};

_useReportDateRangesFragmentReportingPlatform_report;
export const useReportDateRanges = (reportRef, timezone) => {
  var _reportData$filterDim;

  const reportData = useFragment(useReportDateRangesFragmentReportingPlatform_report, reportRef);
  const reportTimeFilter = (_reportData$filterDim = reportData.filterDimensions.find(dimension => isReportTimeFilter(dimension.filter))) === null || _reportData$filterDim === void 0 ? void 0 : _reportData$filterDim.filter;

  if (!reportTimeFilter || !isReportTimeFilter(reportTimeFilter)) {
    return null;
  }

  const {
    startDate: reportStartDate,
    endDate: reportEndDate,
    dateFilterPeriod,
    comparisonPeriod
  } = reportTimeFilter;
  const {
    startDate: comparisonStartDate,
    endDate: comparisonEndDate,
    comparisonPeriodType
  } = comparisonPeriod || {};
  const formattedReportStartDate = reportStartDate ? getCalendarDay(deserializeGQLDateTime(reportStartDate), timezone) : null;
  const formattedReportEndDate = reportEndDate ? getCalendarDay(deserializeGQLDateTime(reportEndDate), timezone) : null;
  const formattedComparisonStartDate = comparisonStartDate ? getCalendarDay(deserializeGQLDateTime(comparisonStartDate), timezone) : null;
  const formattedComparisonEndDate = comparisonEndDate ? getCalendarDay(deserializeGQLDateTime(comparisonEndDate), timezone) : null;
  const computedComparisonPeriod = formattedReportStartDate && formattedReportEndDate ? computeComparisonDates(parseISO(formattedReportStartDate), parseISO(formattedReportEndDate), comparisonPeriod) : null;
  return {
    reportStartDate: formattedReportStartDate,
    reportEndDate: formattedReportEndDate,
    dateFilterPeriod: dateFilterPeriod || null,
    comparisonStartDate: formattedComparisonStartDate || clipDateOnly(computedComparisonPeriod === null || computedComparisonPeriod === void 0 ? void 0 : computedComparisonPeriod.start),
    comparisonEndDate: formattedComparisonEndDate || clipDateOnly(computedComparisonPeriod === null || computedComparisonPeriod === void 0 ? void 0 : computedComparisonPeriod.end),
    comparisonPeriodType: comparisonPeriodType || null
  };
};