/**
 * @generated SignedSource<<538f30c25f9c565a3237f3fa57e95351>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ComparisonDateFilterPeriodType = "COMPARISON_DATE_FILTER_CUSTOM_PERIOD" | "COMPARISON_DATE_FILTER_PREVIOUS_PERIOD" | "COMPARISON_DATE_FILTER_PREVIOUS_YEAR" | "COMPARISON_DATE_FILTER_UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type fragmentTypes_ReportingPlatformLib_report$data = {
  readonly filterDimensions: ReadonlyArray<{
    readonly filter: {
      readonly comparisonPeriod?: {
        readonly comparisonPeriodType: ComparisonDateFilterPeriodType | null;
        readonly endDate: SerializedDateTime | null;
        readonly startDate: SerializedDateTime | null;
      } | null;
    };
  }>;
  readonly " $fragmentType": "fragmentTypes_ReportingPlatformLib_report";
};
export type fragmentTypes_ReportingPlatformLib_report$key = {
  readonly " $data"?: fragmentTypes_ReportingPlatformLib_report$data;
  readonly " $fragmentSpreads": FragmentRefs<"fragmentTypes_ReportingPlatformLib_report">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "fragmentTypes_ReportingPlatformLib_report",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ReportFilterWrapper",
      "kind": "LinkedField",
      "name": "filterDimensions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "filter",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ComparisonPeriod",
                  "kind": "LinkedField",
                  "name": "comparisonPeriod",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "comparisonPeriodType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "startDate",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endDate",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "ReportTimeFilter",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Report",
  "abstractKey": null
};

(node as any).hash = "3498954b3691b77d482d9c6ba1cd7821";

export default node;
