import round from 'lodash/round';

import { CurrencyCode, Locale } from '@attentive/locale-utils';

import { METRIC_MISSING_DATA_SYMBOL, MetricCellDataType } from '../hooks';

import { MINIMUM_ROUNDED_VALUE, formatCurrency, formatPercent } from './formatUtils';

export const formatMetricValue = ({
  value,
  dataType,
  currencyCode = CurrencyCode.US,
  locale,
  enableRounding = true,
}: {
  value: number | string | null;
  dataType: MetricCellDataType;
  locale: Locale;
  enableRounding?: boolean;
  currencyCode?: CurrencyCode;
}): string => {
  if (value === null) {
    return METRIC_MISSING_DATA_SYMBOL;
  }

  switch (dataType) {
    case 'METRIC_DATA_TYPE_CURRENCY': {
      return formatCurrency({
        value,
        locale,
        currencyCode,
        hasDecimals: true,
      });
    }
    case 'METRIC_DATA_TYPE_PERCENT': {
      return formatPercent(value, enableRounding);
    }
    case 'METRIC_DATA_TYPE_NUMBER': {
      if (!enableRounding) {
        return value.toLocaleString(locale, {
          maximumFractionDigits: 20,
          minimumFractionDigits: 0,
        });
      }
      const roundedValue = round(parseFloat(`${value}`), 2);
      if (roundedValue === 0 && value !== 0) {
        return `< ${MINIMUM_ROUNDED_VALUE}`;
      }
      return roundedValue.toLocaleString();
    }
    default: {
      return value.toLocaleString(locale, {
        maximumFractionDigits: 20,
        minimumFractionDigits: 0,
      });
    }
  }
};
