/**
 * @generated SignedSource<<c3c48731656fc3fd45d4c22d16c9351e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ComparisonDateFilterPeriodType = "COMPARISON_DATE_FILTER_CUSTOM_PERIOD" | "COMPARISON_DATE_FILTER_PREVIOUS_PERIOD" | "COMPARISON_DATE_FILTER_PREVIOUS_YEAR" | "COMPARISON_DATE_FILTER_UNKNOWN" | "%future added value";
export type DateFilterPeriod = "DATE_FILTER_PERIOD_LAST_30_DAYS" | "DATE_FILTER_PERIOD_LAST_7_DAYS" | "DATE_FILTER_PERIOD_LAST_90_DAYS" | "DATE_FILTER_PERIOD_LAST_MONTH" | "DATE_FILTER_PERIOD_LAST_QUARTER" | "DATE_FILTER_PERIOD_LAST_YEAR" | "DATE_FILTER_PERIOD_MONTH_TO_DATE" | "DATE_FILTER_PERIOD_QUARTER_TO_DATE" | "DATE_FILTER_PERIOD_TODAY" | "DATE_FILTER_PERIOD_UNKNOWN" | "DATE_FILTER_PERIOD_WEEK_TO_DATE" | "DATE_FILTER_PERIOD_YEAR_TO_DATE" | "DATE_FILTER_PERIOD_YESTERDAY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useReportDateRangesFragmentReportingPlatform_report$data = {
  readonly filterDimensions: ReadonlyArray<{
    readonly filter: {
      readonly __typename: "ReportTimeFilter";
      readonly comparisonPeriod: {
        readonly comparisonPeriodType: ComparisonDateFilterPeriodType | null;
        readonly endDate: SerializedDateTime | null;
        readonly startDate: SerializedDateTime | null;
      } | null;
      readonly dateFilterPeriod: DateFilterPeriod | null;
      readonly endDate: SerializedDateTime | null;
      readonly startDate: SerializedDateTime | null;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    };
  }>;
  readonly " $fragmentType": "useReportDateRangesFragmentReportingPlatform_report";
};
export type useReportDateRangesFragmentReportingPlatform_report$key = {
  readonly " $data"?: useReportDateRangesFragmentReportingPlatform_report$data;
  readonly " $fragmentSpreads": FragmentRefs<"useReportDateRangesFragmentReportingPlatform_report">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useReportDateRangesFragmentReportingPlatform_report",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ReportFilterWrapper",
      "kind": "LinkedField",
      "name": "filterDimensions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "filter",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "dateFilterPeriod",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ComparisonPeriod",
                  "kind": "LinkedField",
                  "name": "comparisonPeriod",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "comparisonPeriodType",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "ReportTimeFilter",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Report",
  "abstractKey": null
};
})();

(node as any).hash = "a5a7742ae256fe1907fddede084214f2";

export default node;
